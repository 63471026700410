import React, { useEffect, useState, useCallback, FormEvent } from 'react';
import { RouteProps, Prompt } from 'react-router-dom';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';
import classNames from 'classnames';

import {
  Button,
  ConfirmationModal,
  DateInput,
  FormDropdown,
  FormField,
  Header,
  Loader,
  OverlapPageControls,
  Paragraph,
  Toggle,
  UIText,
} from '@air/components';
import { LogoUploader } from 'components';
import { ATSSettingsSection } from './ATSSettingsSection/ATSSettingsSection';
import { MMSettingsSection } from './MMSettingsSection/MMSettingsSection';
import { MatchScoutSettingsSection } from './MatchScoutSettingsSection/MatchScoutSettingsSection';
import { BillingReportSection } from './BillingReportSection/BillingReportSection';
import { AdminSettingsSection } from './AdminSettingsSection/AdminSettingsSection';

import * as phrases from 'constants/phrases';
import { PAGE_START, REQUEST_PAGING_SIZE } from 'constants/api';
import {
  enrichmentRolesOptions,
  EXTERNAL_ATS_LIST,
  LicenseTypeOptions,
} from 'constants/customerCompanies';
import { CRITERIA_COLUMNS } from 'constants/criteria';

import { emailRegex } from '@air/utils/strings';
import { LicenseType, RoleName } from '@air/api';
import {
  CustomerCompanyExtendedT,
  CustomerCompanyFormT,
  defaultFieldValidationRules,
  getCompanyData,
  getFormLabel,
  LogoT,
  MAX_FIELD_LENGTH,
  prepareExistingCompanyFormForBE,
  prepareNewCompanyFormForBE,
} from 'domain/customerCompanies';
import { useOutsideClick } from '@air/utils/hooks';
import { NEW_ITEM } from 'constants/dictionaries';

import styles from './CustomerCompanyForm.css';
import {
  useCustomerCompaniesContext,
  useCustomerCompaniesMethods,
} from 'providers/CustomerCompaniesProvider';
import { dictionarySelectors } from 'selectors';

const LICENSE_DEFAULT_MONTH_FROM = new Date();
const LICENSE_EXPIRED_DEFAULT_MONTH_TO = new Date();

type Props = RouteProps & {
  closeForm: () => void;
  keyword: string;
  licenseType?: string[];
  companyId: string;
  companyName?: string;
};

export const CustomerCompanyForm: React.FC<Props> = (props: Props) => {
  const { closeForm, keyword, licenseType, companyId } = props;

  const sortField = useCustomerCompaniesContext(dictionarySelectors.sortField);
  const sortOrder = useCustomerCompaniesContext(dictionarySelectors.sortOrder);

  const {
    fetchDictionaryItems,
    createNewItem,
    updateItem,
    uploadLogoForCustomerCompany,
    removeLogoForCustomerCompany,
    dropIndexationResults,
    removeItem,
    getItemById,
  } = useCustomerCompaniesMethods();

  const isNewCompany = companyId === NEW_ITEM;
  // form is being updated
  const [isLoading, setLoading] = useState(isNewCompany ? false : undefined);
  // first time form loading
  const [isLoaded, setIsLoaded] = useState(isNewCompany ? true : false);
  const [isRemoveConfirmVisible, setRemoveConfirmVisibility] = useState(false);
  const [removeConfirmInputValue, setRemoveConfirmInputValue] = useState(null);
  const [selectedCompany, setSelectedCompany] =
    useState<CustomerCompanyExtendedT>(getCompanyData());
  const [logo, setLogo] = useState<LogoT>({ existing: {}, new: {} });
  const [isCompanyNameEditMode, setCompanyNameEditMode] = useState(false);

  const methods = useForm<Record<string, any>>({
    defaultValues: selectedCompany.form,
    shouldUnregister: true,
  });

  const { handleSubmit, control, setValue, reset, formState, trigger } =
    methods;

  const formValues = useWatch({ control });

  const getSelectedCompany = useCallback(async () => {
    const result = await getItemById(companyId);
    const computedResult = getCompanyData(result);
    setLogo({
      ...logo,
      existing: {
        name: computedResult.logoFilename,
        id: computedResult.id,
      },
    });
    await setSelectedCompany(computedResult);
    reset(computedResult.form);
  }, [companyId, getItemById, logo, reset]);

  useEffect(() => {
    if (companyId && !isLoading && !selectedCompany?.id && !isNewCompany) {
      const getCompany = async () => {
        setLoading(true);
        try {
          await getSelectedCompany();
          setLoading(false);
          setIsLoaded(true);
        } catch (e) {
          toast.error(phrases.CUSTOMER_COMPANY_NOT_FOUND);
          closeForm();
        }
      };
      getCompany();
    }
  }, [
    getSelectedCompany,
    getItemById,
    companyId,
    isLoading,
    selectedCompany,
    isNewCompany,
    formValues,
    closeForm,
    reset,
    logo,
  ]);

  useEffect(() => {
    // validate trialExpired field when licenseType is changed
    if (formValues.trialExpired) {
      trigger();
    }
  }, [trigger, formValues.licenseType, formValues.trialExpired]);

  const isEditExistingCompanyMode = !!selectedCompany?.id;
  const [companyNameRef] = useOutsideClick(
    () => {
      // if the company name was removed during editing preserve the old saved name
      if (!formValues.name) setValue('name', selectedCompany.name);
      setCompanyNameEditMode(false);
    },
    {
      useCapture: true,
    }
  );

  useEffect(() => {
    if (isLoaded && !formValues.piplEnabled) {
      setValue('enrichAllowRole', {
        value: RoleName.RECRUITER,
        label: RoleName.RECRUITER,
      });
    }
  }, [formValues.piplEnabled, setValue, isLoaded]);

  useEffect(() => {
    if (
      isCompanyNameEditMode &&
      formValues.name &&
      formValues.name !== selectedCompany.name
    ) {
      setSelectedCompany({ ...selectedCompany, name: formValues.name });
    }
  }, [isCompanyNameEditMode, formValues.name, selectedCompany]);

  const saveLogoLocally = (params: LogoT) => {
    setLogo({
      ...logo,
      ...(params.new ? { new: params.new } : {}),
      ...(params.existing ? { existing: params.existing } : {}),
    });
  };

  const handleUpdateLogo = async (companyId: string) => {
    const shouldRemoveLogo =
      selectedCompany.logoFilename &&
      !logo.existing.name &&
      R.isNullOrEmpty(logo.new);
    const logoWasUpdated =
      selectedCompany.logoFilename &&
      logo.existing.name !== selectedCompany.logoFilename;
    const logoWasAdded =
      !selectedCompany.logoFilename && !R.isNullOrEmpty(logo.new);
    // logo existed and needs to be removed
    if (shouldRemoveLogo) {
      await removeLogoForCustomerCompany(companyId);
    } else if (logoWasUpdated || logoWasAdded) {
      // logo existed but was updated or logo did not exist yet
      // upload request replaces the existing logo or adds a new one, no need to remove first
      if (!R.isNullOrEmpty(logo.new)) {
        const body = new FormData();
        body.append('file', logo.new as Blob);
        await uploadLogoForCustomerCompany({
          companyId: companyId || selectedCompany.id,
          body: body,
        });
      }
    }
  };

  const saveCustomerCompany = async (formData: CustomerCompanyFormT) => {
    if (formData) {
      setLoading(true);
      let result;
      try {
        if (isEditExistingCompanyMode) {
          const extraParams = {
            specificNotificationSender:
              selectedCompany.specificNotificationSender,
            dataSourceSettings: selectedCompany.dataSourceSettings,
          };
          const updatedCompany = prepareExistingCompanyFormForBE(
            formData,
            extraParams
          );
          result = await updateItem({
            newItem: updatedCompany,
            companyId: selectedCompany.id,
          });
        } else {
          const newCustomerCompany = prepareNewCompanyFormForBE(formData);
          result = await createNewItem(newCustomerCompany);
        }
        const companyId = String(result?.id);

        if (companyId) {
          await handleUpdateLogo(companyId);
          toast.success(
            phrases.getCustomerCompanySaveSuccessMessage(
              formData.name,
              !isEditExistingCompanyMode
            )
          );
          fetchDictionaryItems({
            page: PAGE_START,
            size: REQUEST_PAGING_SIZE,
            sortField,
            sortOrder,
            keyword,
            licenseType,
          });
          closeForm();
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleCloseForm = () => {
    if (!formState.isDirty || window.confirm(phrases.CONFIRM_LEAVE_PAGE)) {
      closeForm();
    }
  };

  useBeforeUnload(formState.isDirty, phrases.CONFIRM_LEAVE_PAGE);

  const handleRemoveItem = async () => {
    if (removeConfirmInputValue === phrases.CUSTOMER_COMPANY_DELETE_KEYWORD) {
      setLoading(true);
      try {
        await removeItem(selectedCompany.id);
        fetchDictionaryItems({
          page: PAGE_START,
          size: REQUEST_PAGING_SIZE,
          sortField,
          sortOrder,
          keyword,
          licenseType,
        });
        setRemoveConfirmVisibility(false);
        setRemoveConfirmInputValue(null);
        toast.success(phrases.CUSTOMER_COMPANY_REMOVE_SUCCESS);
        closeForm();
      } catch (e) {
        toast.error(phrases.CUSTOMER_COMPANY_REMOVE_ACTION_ERROR);
        setLoading(false);
      }
    }
  };

  const isExpiredLicenseSelected = [
    LicenseType.TRIALEXPIRED,
    LicenseType.TRIALEXPIREDFULLREQUESTED,
  ].includes(formValues.licenseType?.value);

  const isTrialExpirationDateValid = (
    selectedDate: Date,
    isRequired: boolean
  ) => {
    if (selectedDate) {
      // trialExpired date cannot be in the past for active license
      const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
      return (
        isExpiredLicenseSelected ||
        startOfToday.getTime() <= Date.parse(selectedDate.toDateString())
      );
    }
    return isRequired;
  };

  const isDisabled = isLoading;
  const showAtsSettings =
    !isNewCompany && EXTERNAL_ATS_LIST.includes(formValues.atsType);
  const showTrialExpiration =
    !isNewCompany &&
    formValues.licenseType?.value &&
    formValues.licenseType.value !== LicenseType.FULL;

  const logoWasEdited =
    (selectedCompany.logoFilename && !logo.existing.name) ||
    (!selectedCompany.logoFilename && !R.isNullOrEmpty(logo.new));

  const isSaveDisabled = isEditExistingCompanyMode
    ? !formState.isDirty && !logoWasEdited
    : !formState.isDirty;

  // @ts-ignore
  return (
    <FormProvider {...methods}>
      <form
        className={styles.customerCompanyForm}
        onSubmit={handleSubmit(saveCustomerCompany)}
      >
        <Prompt when={formState.isDirty} message={phrases.CONFIRM_LEAVE_PAGE} />
        <OverlapPageControls
          onClose={handleCloseForm}
          buttonWrapperClassName={styles.closeButtonWrapper}
        />
        {isLoaded ? (
          <>
            <div className={styles.formHeader}>
              <div className={styles.companyNameWrapper}>
                {selectedCompany.id && !isLoading && (
                  <div
                    ref={companyNameRef}
                    className={classNames(styles.input, {
                      [styles.visible]: isCompanyNameEditMode,
                    })}
                  >
                    <Controller
                      name="name"
                      control={control}
                      rules={defaultFieldValidationRules}
                      render={({ field, fieldState }) => (
                        <FormField
                          type="text"
                          isDisabled={isDisabled}
                          hasError={fieldState.invalid}
                          maxLength={MAX_FIELD_LENGTH}
                          label={getFormLabel(
                            phrases.CUSTOMER_COMPANY_LABEL_NAME,
                            fieldState
                          )}
                          {...field}
                        />
                      )}
                    />
                  </div>
                )}
                <Header
                  level={2}
                  className={classNames(styles.companyName, {
                    [styles.visible]: !isCompanyNameEditMode,
                  })}
                  onClick={
                    selectedCompany.name
                      ? () => setCompanyNameEditMode(true)
                      : () => {}
                  }
                >
                  {isNewCompany
                    ? phrases.CUSTOMER_COMPANY_FORM_TITLE
                    : selectedCompany.name}
                </Header>
              </div>

              {selectedCompany?.id && (
                <div className={styles.dateInfo}>
                  {selectedCompany.created && (
                    <Paragraph>
                      {phrases.CUSTOMER_COMPANY_CREATED}
                      {selectedCompany.created}
                    </Paragraph>
                  )}
                  {selectedCompany.updated && (
                    <Paragraph>
                      {phrases.CUSTOMER_COMPANY_UPDATED}
                      {selectedCompany.updated}
                    </Paragraph>
                  )}
                </div>
              )}
            </div>

            <div className={styles.form}>
              <div className={styles.formGroup}>
                <UIText small className={styles.formGroupTitle}>
                  {phrases.CUSTOMER_COMPANY_FORM_GROUP_ORGANIZATION}
                </UIText>
                <div className={styles.inputsWrapper}>
                  {!isEditExistingCompanyMode && !isLoading && (
                    <Controller
                      name="name"
                      control={control}
                      rules={defaultFieldValidationRules}
                      render={({ field, fieldState }) => (
                        <FormField
                          type="text"
                          isDisabled={isDisabled}
                          hasError={fieldState.invalid}
                          maxLength={MAX_FIELD_LENGTH}
                          label={getFormLabel(
                            phrases.CUSTOMER_COMPANY_LABEL_NAME,
                            fieldState
                          )}
                          {...field}
                        />
                      )}
                    />
                  )}

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: phrases.FORM_ERROR_MISSING,
                      },
                      pattern: {
                        value: emailRegex,
                        message: phrases.FORM_ERROR_INVALID,
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <FormField
                        type="email"
                        isDisabled={isDisabled}
                        hasError={fieldState.invalid}
                        maxLength={MAX_FIELD_LENGTH}
                        label={getFormLabel(
                          phrases.CUSTOMER_COMPANY_LABEL_EMAIL,
                          fieldState
                        )}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="accountManager"
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormField
                        type="text"
                        isDisabled={isDisabled}
                        hasError={fieldState.invalid}
                        maxLength={MAX_FIELD_LENGTH}
                        label={phrases.CUSTOMER_COMPANY_LABEL_ACCOUNT_MANAGER}
                        {...field}
                      />
                    )}
                  />

                  {isEditExistingCompanyMode && (
                    <Controller
                      name="atsType"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          type="text"
                          isDisabled
                          label={phrases.CUSTOMER_COMPANY_LABEL_ATS_TYPE}
                          {...field}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <LogoUploader
                handleUpload={saveLogoLocally}
                logo={logo}
                isDisabled={isDisabled}
              />

              {!isEditExistingCompanyMode && (
                <AdminSettingsSection
                  control={control}
                  isDisabled={isDisabled}
                />
              )}

              <div className={styles.formGroup}>
                <UIText small className={styles.formGroupTitle}>
                  {phrases.CUSTOMER_COMPANY_FORM_GROUP_APPLICANT}
                </UIText>
                <div className={styles.inputsWrapper}>
                  <label className={styles.toggleLabel}>
                    <Controller
                      name="applicantEmailNotificationsEnabled"
                      control={control}
                      render={({ field }) => (
                        <Toggle
                          name="applicantEmailNotificationsEnabled"
                          disabled={isDisabled}
                          onChange={field.onChange}
                          checked={field.value}
                          className={styles.toggleButton}
                        />
                      )}
                    />
                    <Paragraph small>
                      {phrases.CUSTOMER_COMPANY_LABEL_EMAILS_ENABLED}
                    </Paragraph>
                  </label>

                  <label className={styles.toggleLabel}>
                    <Controller
                      name="applicantStatsEnabled"
                      control={control}
                      render={({ field }) => (
                        <Toggle
                          name="applicantStatsEnabled"
                          disabled={isDisabled}
                          onChange={field.onChange}
                          checked={field.value}
                          className={styles.toggleButton}
                        />
                      )}
                    />
                    <Paragraph small>
                      {phrases.CUSTOMER_COMPANY_LABEL_STATS_ENABLED}
                    </Paragraph>
                  </label>
                </div>
                <div className={styles.inputsWrapper}>
                  <Controller
                    name={CRITERIA_COLUMNS.CONTACT_EMAIL}
                    control={control}
                    rules={defaultFieldValidationRules}
                    render={({ field, fieldState }) => (
                      <FormField
                        type="email"
                        isDisabled={isDisabled}
                        hasError={fieldState.invalid}
                        maxLength={MAX_FIELD_LENGTH}
                        label={getFormLabel(
                          phrases.CRITERIA_COLUMN_TITLE_FROM_EMAIL,
                          fieldState
                        )}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <UIText small className={styles.formGroupTitle}>
                  {phrases.CUSTOMER_COMPANY_FORM_GROUP_LICENSE}
                </UIText>
                <div className={styles.inputsWrapper}>
                  <Controller
                    name="licenseType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormDropdown
                        hasWarning={false}
                        isDisabled={isDisabled}
                        id="licenseType"
                        options={LicenseTypeOptions}
                        label={
                          phrases.CRITERIA_COLUMN_DROPDOWN_TITLE_LICENSE_STATUS
                        }
                        {...field}
                      />
                    )}
                  />
                  {isNewCompany &&
                    formValues.licenseType?.value !== LicenseType.FULL && (
                      <Controller
                        name="trialPeriodInDays"
                        control={control}
                        rules={defaultFieldValidationRules}
                        render={({ field, fieldState }) => (
                          <FormField
                            type="number"
                            isDisabled={isDisabled || isEditExistingCompanyMode}
                            hasError={fieldState.invalid}
                            label={getFormLabel(
                              phrases.CUSTOMER_COMPANY_LABEL_TRIAL_DAYS,
                              fieldState
                            )}
                            {...field}
                          />
                        )}
                      />
                    )}
                  {showTrialExpiration && (
                    <>
                      <div className={styles.datePickerWrapper}>
                        <DateInput
                          selectedDate={
                            selectedCompany.form.trialExpired as Date
                          }
                          label={
                            phrases.CUSTOMER_COMPANY_LABEL_TRIAL_EXPIRATION_DATE
                          }
                          fieldName="trialExpired"
                          fromMonth={
                            isExpiredLicenseSelected
                              ? null
                              : LICENSE_DEFAULT_MONTH_FROM
                          }
                          toMonth={
                            isExpiredLicenseSelected
                              ? LICENSE_EXPIRED_DEFAULT_MONTH_TO
                              : null
                          }
                          isDateValid={isTrialExpirationDateValid}
                          isDisabled={isDisabled}
                        />
                      </div>

                      {selectedCompany.fullVersionRequested && (
                        <Paragraph className={styles.formGroupExplanation}>
                          {phrases.CUSTOMER_COMPANY_LICENSE_REQUESTED}
                          <br />
                          {selectedCompany.fullVersionRequested}
                        </Paragraph>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={styles.formGroup}>
                <UIText small className={styles.formGroupTitle}>
                  {phrases.CUSTOMER_COMPANY_FORM_GROUP_PIPL}
                </UIText>
                <label className={styles.toggleLabel}>
                  <Controller
                    name="piplEnabled"
                    control={control}
                    render={({ field }) => (
                      <Toggle
                        name="piplEnabled"
                        disabled={isDisabled}
                        onChange={field.onChange}
                        checked={field.value}
                        className={styles.toggleButton}
                      />
                    )}
                  />
                  <Paragraph small>
                    {phrases.CUSTOMER_COMPANY_LABEL_PIPL_ENABLED}
                  </Paragraph>
                </label>
                <div
                  className={styles.inputsWrapper}
                  title={phrases.CUSTOMER_ENRICHMENT_ROLES_HINT}
                >
                  <Controller
                    name="enrichAllowRole"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormDropdown
                        menuPlacement="top"
                        hasWarning={false}
                        isDisabled={isDisabled || !formValues.piplEnabled}
                        id="enrichAllowRole"
                        options={enrichmentRolesOptions}
                        label={phrases.CUSTOMER_COMPANY_LABEL_ENRICH_ROLES}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              {!isNewCompany && (
                <>
                  <MMSettingsSection
                    isDisabled={isDisabled || !formValues.atsType}
                    setLoading={setLoading}
                    dropIndexationResults={dropIndexationResults}
                    selectedCompany={selectedCompany}
                    getSelectedCompany={getSelectedCompany}
                  />
                  <MatchScoutSettingsSection
                    isDisabled={isDisabled || !formValues.atsType}
                  />
                </>
              )}

              {showAtsSettings && (
                <ATSSettingsSection
                  isLoaded={isLoaded}
                  control={control}
                  isDisabled={isDisabled}
                  formValues={formValues}
                  setValue={setValue}
                />
              )}

              {!isNewCompany && <BillingReportSection companyId={companyId} />}

              <div className={styles.formControls}>
                <Button
                  variant={Button.variants.POSITIVE_CONFIRM}
                  onClick={handleSubmit(saveCustomerCompany)}
                  isLoading={isLoading}
                  disabled={isSaveDisabled}
                >
                  {isEditExistingCompanyMode
                    ? phrases.CUSTOMER_COMPANY_FORM_ACTION_SAVE
                    : phrases.CUSTOMER_COMPANY_FORM_ACTION_CREATE}
                </Button>
                <Button
                  variant={Button.variants.NEGATIVE_MAIN}
                  onClick={handleCloseForm}
                  disabled={isLoading}
                >
                  {isEditExistingCompanyMode
                    ? phrases.CUSTOMER_COMPANY_FORM_ACTION_CLOSE
                    : phrases.CUSTOMER_COMPANY_FORM_ACTION_CANCEL}
                </Button>
                {!isNewCompany && (
                  <>
                    <Button
                      variant={Button.variants.INLINE}
                      className={styles.removeButton}
                      onClick={() => setRemoveConfirmVisibility(true)}
                      disabled={isLoading}
                    >
                      {phrases.CUSTOMER_COMPANY_REMOVE_ACTION}
                    </Button>
                    <ConfirmationModal
                      onConfirm={handleRemoveItem}
                      onCancel={() => setRemoveConfirmVisibility(false)}
                      confirmLabel={phrases.CUSTOMER_COMPANY_CONFIRM_ACTION}
                      isOpen={isRemoveConfirmVisible}
                      actionDisabled={
                        removeConfirmInputValue !==
                        phrases.CUSTOMER_COMPANY_DELETE_KEYWORD
                      }
                      isLoading={isLoading}
                    >
                      <Paragraph className={styles.confirmation}>
                        {phrases.getCustomerCompanyRemoveConfirmation(
                          selectedCompany.name
                        )}
                      </Paragraph>
                      <br />
                      <FormField
                        onChange={(event: FormEvent<HTMLInputElement>) =>
                          // @ts-ignore
                          setRemoveConfirmInputValue(event.target?.value)
                        }
                        value={removeConfirmInputValue}
                        type="text"
                      />
                    </ConfirmationModal>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <Loader className={styles.loader} />
        )}
      </form>
    </FormProvider>
  );
};

CustomerCompanyForm.displayName = 'CustomerCompanyForm';
