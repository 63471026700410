import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormDropdown, UIText } from '@air/components';
import * as phrases from 'constants/phrases';
import { matchScoutStatusOptions } from 'constants/customerCompanies';

import sharedStyles from 'features/CustomerCompaniesView/CustomerCompanуForm/CustomerCompanyForm.css';

type Props = {
  isDisabled: boolean;
};

export const MatchScoutSettingsSection: React.FC<Props> = ({ isDisabled }) => {
  const { control } = useFormContext();

  return (
    <div className={sharedStyles.formGroup}>
      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_GROUP_MATCH_SCOUT}
      </UIText>
      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="msStatus"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormDropdown
              hasWarning={false}
              isDisabled={isDisabled}
              id="msStatus"
              options={matchScoutStatusOptions}
              label={phrases.CUSTOMER_COMPANY_LABEL_MS_STATUS}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};
