import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import querystring from 'query-string';

import { DictionaryView, TabControlsWrapper } from 'components';

import {
  companiesTableColumns,
  companySizesTableColumns,
  NEW_COMPANY_SIZES_ITEM,
  COMPANY_TABS_OPTIONS,
} from 'constants/companies';
import { CompanyTabs } from '@air/constants/tabs';
import * as phrases from 'constants/phrases';

import commonStyles from 'styles/commonDictionaries.css';
import {
  useCompaniesContext,
  useCompaniesMethods,
} from 'providers/CompaniesProvider';
import {
  useCompanySizesContext,
  useCompanySizesMethods,
} from 'providers/CompanySizesProvider';

type Props = RouteProps;

export const CompaniesView: React.FC<Props> = () => {
  const parsedUrlParams = querystring.parse(location.search);
  const [tab, openTab] = useState<CompanyTabs>(
    (parsedUrlParams.tab as CompanyTabs) || CompanyTabs.Companies
  );

  return (
    <div className={commonStyles.dictionaryTabsView}>
      <TabControlsWrapper<CompanyTabs>
        items={COMPANY_TABS_OPTIONS}
        selectedTab={tab}
        onChange={openTab}
        className={commonStyles.tabs}
      />
      {tab === CompanyTabs.Companies && (
        <DictionaryView
          tableTitle={phrases.COMPANY_TAB_COMPANIES}
          tableColumns={companiesTableColumns}
          dictionaryContext={useCompaniesContext}
          dictionaryContextMethods={useCompaniesMethods}
          tab={tab}
          isReadOnly
        />
      )}
      {/*tab === CompanyTabs.CompanyGroups && <div>Company groups</div> */}
      {tab === CompanyTabs.CompanySizes && (
        <DictionaryView
          tableTitle={phrases.COMPANY_TAB_COMPANY_SIZES}
          tableColumns={companySizesTableColumns}
          defaultEmptyItem={NEW_COMPANY_SIZES_ITEM}
          dictionaryContext={useCompanySizesContext}
          dictionaryContextMethods={useCompanySizesMethods}
          hideDeprecatedControl
          tab={tab}
        />
      )}
    </div>
  );
};
