import React, { useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import { Column, Row } from 'react-table';

import * as phrases from '@air/constants/phrases';
import {
  UpdateFieldParamsT,
  UpdateFieldValueT,
} from 'domain/dictionaries/criteria';
import { SelectOptionT } from '@air/domain/Forms/types';
import { AsyncProps } from 'react-select/src/Async';

type Props = {
  row: Row;
  value: unknown;
  column: Column;
  loadOptions: any;
  updateData: ({ value, id, fieldName }: UpdateFieldParamsT) => void;
  disabled?: boolean;
  options?: SelectOptionT[];
  defaultOptions?: AsyncProps<unknown>['defaultOptions'];
};

export const MultiselectCell = React.memo((props: Props) => {
  const {
    row: { original },
    value,
    column,
    updateData,
    disabled,
    loadOptions,
    defaultOptions,
  } = props;

  const onChange = useCallback(
    (value: UpdateFieldValueT) => {
      updateData({ value, id: original.id, fieldName: column.id });
    },
    [updateData, original.id, column.id]
  );

  const getNoOptionMessage = useCallback(
    ({ inputValue }: { inputValue: string }) =>
      inputValue ? phrases.NO_OPTIONS_DEFAULT_MESSAGE : null,
    []
  );

  return (
    <div>
      <AsyncSelect
        isMulti
        value={value}
        onChange={onChange}
        loadOptions={loadOptions}
        isDisabled={disabled}
        noOptionsMessage={getNoOptionMessage}
        defaultOptions={defaultOptions}
      />
    </div>
  );
});

MultiselectCell.displayName = 'MultiselectCell';
