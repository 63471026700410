import { Task } from '@air/utils/fp';
import {
  CustomerCompanyCreateRequestV3,
  CustomerCompanyResponseV3,
} from '@air/api';
import * as Http from '@air/utils/http';
import {
  parseErrorJson,
  parseResponseBlob,
  parseResponseJson,
} from '@air/utils/api';
import * as urls from '@air/constants/apiEndpoints';
import * as sharedUrls from '@air/constants/apiEndpoints';

export const fetchCustomerCompanyAts = <T>(params: {
  companyId: string;
}): Task<T> => {
  const { companyId } = params;
  return Http.get(sharedUrls.API_ATS_CUSTOMER_COMPANY_V1)
    .bind(Http.withQueryParams({ companyId }))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const fetchCustomerCompanyById = (
  companyId: string
): Task<CustomerCompanyResponseV3> => {
  return Http.get(sharedUrls.getCustomerCompanyByIdEndpointV3(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const createNewItem = <BodyT>(
  body: BodyT
): Task<CustomerCompanyCreateRequestV3> => {
  return Http.post(sharedUrls.API_CUSTOMER_COMPANY_V3)
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const updateItem = <BodyT>(
  body: BodyT,
  companyId: string
): Task<CustomerCompanyResponseV3> => {
  return Http.put(sharedUrls.getCustomerCompanyByIdEndpointV3(companyId))
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export type UploadCompanyLogoParamsT = {
  companyId: string;
  body?: FormData;
};

export const uploadCompanyLogo = ({
  companyId,
  body,
}: UploadCompanyLogoParamsT) => {
  return Http.post(urls.API_ATS_CUSTOMER_COMPANY_LOGO(companyId))
    .bind(Http.withFormMultipartBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const removeItem = (companyId: string): Task => {
  return Http.remove(urls.getCustomerCompanyByIdEndpointV1(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const dropIndexationResults = (companyId: string): Task => {
  return Http.remove(urls.getCustomerCompanyIndexationEndpoint(companyId))
    .toTask()
    .chainError(parseErrorJson);
};

export const removeCompanyLogo = ({ companyId }: UploadCompanyLogoParamsT) => {
  return Http.remove(urls.API_ATS_CUSTOMER_COMPANY_LOGO(companyId))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export type BillingReportPayload = {
  companyId: string;
  from: number;
  to: number;
};

export const getBillingReport = ({
  companyId,
  from,
  to,
}: BillingReportPayload) => {
  return Http.get(urls.API_BILLING_REPORT(companyId, from, to))
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseErrorJson);
};

export const getCompanyManagers = () => {
  return Http.get(urls.API_COMPANY_MANAGERS)
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};
