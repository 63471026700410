import { SortOrderT } from 'constants/api';

export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_FORMAT_AMERICAN = 'MM/DD/YYYY';
export type TableColumnsT = {
  accessor: string;
  Header: string;
  required?: boolean;
}[];

export const getNewSortOrder = (sortOrder: string, isSameField?: boolean) => {
  if (!isSameField) {
    return SortOrderT.asc;
  }
  return sortOrder === SortOrderT.desc ? SortOrderT.asc : SortOrderT.desc;
};
