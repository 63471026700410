import { CRITERIA_COLUMNS } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { MajorsTabs } from '@air/constants/tabs';
import { EducationMajorGroup } from '@air/api';

export const MAJORS_TABS_OPTIONS = [
  {
    value: MajorsTabs.Majors,
    label: phrases.MAJORS_TAB_MAJORS,
  },
  {
    value: MajorsTabs.MajorGroups,
    label: phrases.MAJORS_TAB_MAJOR_GROUPS,
  },
];

export const majorsTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

export const majorGroupsTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MAJOR_LIST_AMOUNT,
    accessor: CRITERIA_COLUMNS.ITEMS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

type MajorGroupItem = EducationMajorGroup & {
  __isNew__: boolean;
};

export const NEW_MAJORS_GROUP_ITEM: MajorGroupItem = {
  __isNew__: true,
  fullName: '',
  items: [],
  created: null,
  updated: null,
  deprecated: false,
};
