import React, { useMemo, useState, useCallback } from 'react';
import R from '@air/third-party/ramda';
import { toast } from '@air/third-party/toast';

import { IndustriesContext, DictionaryContextT } from 'context';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';

import {
  ModifiedCriteriaListResponse,
  UpdateFieldParamsT,
  FetchCriteriaParamsT,
  computeUpdatedItemsInUpdatedList,
  computeUpdatedDictionaryItems,
  notifyAfterPublishingChanges,
  DmsDictionaryResponse,
  prepareDictionaryObjectForUI,
  ModifiedIndustryResponse,
} from 'domain/dictionaries/criteria';
import { prepareIndustriesForBackend } from 'domain/dictionaries/industries';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import {
  DictionaryUpsertResponse,
  IndustryUpsertRequest,
  Industry,
} from '@air/api';
import {
  downloadFile,
  resetToDefaultDictionaryState,
} from 'domain/dictionaries/dictionaries';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { ExtraResponseParamsT } from '@air/domain/Common/apiTypes';
import { nullifyEmptyStrings } from 'domain/listHelpers';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';

const DEFAULT_FILE_NAME = 'industry_dictionary';

export const IndustriesProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] =
    useState<ModifiedCriteriaListResponse>(DEFAULT_DICTIONARY_ITEM);

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      const { sortField, sortOrder } = params;
      setDictionaryItems((state) => ({
        ...state,
        sortField: sortField || state?.sortField,
        sortOrder: sortOrder || state?.sortOrder,
        isLoading: true,
      }));

      DictionariesApi.fetchDictionary<DmsDictionaryResponse<Industry>>(
        params,
        sharedUrls.DICTIONARY_API_INDUSTRY
      ).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: DmsDictionaryResponse<Industry>) => {
          setDictionaryItems((state) => {
            return prepareDictionaryObjectForUI(state, response, params);
          });
        }
      );
    },
    []
  );

  // updating dictionaryItems locally
  const updateDictionaryItem = useCallback(
    (updatedField: UpdateFieldParamsT) => {
      setDictionaryItems((state) => {
        return computeUpdatedItemsInUpdatedList({
          localState: state,
          updatedField,
        });
      });
    },
    []
  );

  const discardDictionaryItemsChanges = useCallback(() => {
    setDictionaryItems((state) => resetToDefaultDictionaryState(state));
  }, []);

  // updating/publishing dictionaryItems on back-end
  const publishDictionaryItemsChanges = useCallback(async () => {
    const updatedItemsLength = dictionaryItems?.updatedItems?.length;
    if (updatedItemsLength > 0) {
      const body = prepareIndustriesForBackend(dictionaryItems.updatedItems);
      DictionariesApi.publishChanges<IndustryUpsertRequest[]>(
        body.map(nullifyEmptyStrings),
        sharedUrls.DICTIONARY_API_INDUSTRY
      ).fork(
        () => toast.error(phrases.DICTIONARY_PUBLISHING_ERROR),
        async (response: DictionaryUpsertResponse) => {
          const allItemsValid = response.invalidItems.length === 0;
          notifyAfterPublishingChanges(response, allItemsValid);
          setDictionaryItems((state) => {
            return computeUpdatedDictionaryItems<DictionaryUpsertResponse>(
              state,
              response
            );
          });
        }
      );
    }
  }, [dictionaryItems]);

  const exportDictionaryItems = useCallback(() => {
    const exportUrl = sharedUrls.makeDictionaryExportUrl(
      sharedUrls.CATEGORY_INDUSTRY
    );
    DictionariesApi.exportDictionary(exportUrl).fork(
      () => toast.error(phrases.DICTIONARY_EXPORT_ERROR),
      (response: Blob, extraParams: ExtraResponseParamsT) => {
        return downloadFile(response, extraParams?.headers, DEFAULT_FILE_NAME);
      }
    );
  }, []);

  const removeAddedDictionaryItem = useCallback((id?: string) => {
    if (!id) return;
    setDictionaryItems((state) => ({
      ...state,
      updatedItems: R.reject<any, any>(
        (item) => item.id === id,
        state?.updatedItems
      ),
    }));
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      methods: {
        fetchDictionaryItems,
        updateDictionaryItem,
        exportDictionaryItems,
        publishDictionaryItemsChanges,
        discardDictionaryItemsChanges,
        removeAddedDictionaryItem,
      },
    };
  }, [
    dictionaryItems,
    fetchDictionaryItems,
    updateDictionaryItem,
    publishDictionaryItemsChanges,
    exportDictionaryItems,
    discardDictionaryItemsChanges,
    removeAddedDictionaryItem,
  ]);

  return (
    <IndustriesContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </IndustriesContext.Provider>
  );
};

export const useIndustriesContext = <Selected,>(
  selector: (state: DictionaryContextT<ModifiedIndustryResponse>) => Selected
) => {
  return useContextSelector(IndustriesContext, selector);
};

export const useIndustriesMethods = () => {
  return useEqualContextSelector(
    IndustriesContext,
    (state: DictionaryContextT<ModifiedIndustryResponse>) => state.methods,
    R.shallowEqualObjects
  );
};

IndustriesProvider.displayName = 'IndustriesProvider';
