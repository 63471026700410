import * as phrases from 'constants/phrases';
import { CRITERIA_COLUMNS } from 'constants/criteria';
import { TitleAliasResponse, TitleCategoryUpsertRequest } from '@air/api';
import { RolesTabs } from '@air/constants/tabs';

export const ROLES_TABS_OPTIONS = [
  {
    value: RolesTabs.Roles,
    label: phrases.ROLES_TAB_ROLES,
  },
  {
    value: RolesTabs.RoleCategories,
    label: phrases.ROLES_TAB_ROLE_CATEGORIES,
  },
];

export const rolesTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ALIASES,
    accessor: CRITERIA_COLUMNS.ALIASES,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SPECIALIZATIONS,
    accessor: CRITERIA_COLUMNS.SPECIALIZATIONS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_INDEX,
    accessor: CRITERIA_COLUMNS.PRIORITY,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SIMILAR_GROUPS,
    accessor: CRITERIA_COLUMNS.SIMILAR_GROUPS,
  },

  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MANAGERIAL,
    accessor: CRITERIA_COLUMNS.MANAGERIAL,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

export const roleCategoriesTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SPECIALIZATIONS,
    accessor: CRITERIA_COLUMNS.SPECIALIZATIONS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_INCLUDED_ACTIVE_ROLES,
    accessor: CRITERIA_COLUMNS.ITEMS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

type RoleItem = {
  __isNew__: boolean;
  fullName: string;
  aliases: string;
  priority: number;
  similarGroups: TitleAliasResponse[];
  specializations: string[];
  managerial: boolean;
  deprecated: boolean;
  created: string;
  updated: string;
};

type RoleCategoryItem = TitleCategoryUpsertRequest & {
  __isNew__: boolean;
  created: string;
  updated: string;
};

export const NEW_ROLE_ITEM: RoleItem = {
  __isNew__: true,
  fullName: '',
  aliases: '',
  priority: 0,
  similarGroups: [],
  specializations: [],
  managerial: false,
  created: null,
  updated: null,
  deprecated: false,
};

export const NEW_ROLE_CATEGORY_ITEM: RoleCategoryItem = {
  __isNew__: true,
  fullName: '',
  items: [],
  specializations: [],
  created: null,
  updated: null,
  deprecated: false,
};
