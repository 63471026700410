import * as phrases from 'constants/phrases';
import { CRITERIA_COLUMNS } from 'constants/criteria';

export const skillsTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SHORT_NAME,
    accessor: CRITERIA_COLUMNS.SHORTNAME,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ALIASES,
    accessor: CRITERIA_COLUMNS.ALIASES,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SPECIALIZATIONS,
    accessor: CRITERIA_COLUMNS.SPECIALIZATIONS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_INDEX,
    accessor: CRITERIA_COLUMNS.INDEX,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_LANGUAGE,
    accessor: CRITERIA_COLUMNS.LANGUAGE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

export const NEW_SKILL_ITEM = {
  __isNew__: true,
  fullName: '',
  shortName: '',
  aliases: '',
  index: 0,
  created: '',
  updated: '',
  deprecated: false,
  specializations: [] as string[],
};
