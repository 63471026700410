import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import querystring from 'query-string';

import { DictionaryView, TabControlsWrapper } from 'components';

import * as phrases from 'constants/phrases';
import { RolesTabs } from '@air/constants/tabs';

import commonStyles from 'styles/commonDictionaries.css';
import {
  NEW_ROLE_ITEM,
  NEW_ROLE_CATEGORY_ITEM,
  roleCategoriesTableColumns,
  ROLES_TABS_OPTIONS,
  rolesTableColumns,
} from 'constants/roles';
import { CRITERIA_COLUMNS } from 'constants/criteria';
import { loadTitleAsyncOptionsWithExcluded } from '@air/api-tasks/dictionariesApi';
import {
  useRoleCategoriesContext,
  useRoleCategoriesMethods,
} from 'providers/RoleCategoriesProvider';
import { useRolesContext, useRolesMethods } from 'providers/RolesProvider';

type Props = RouteProps;

export const RolesView: React.FC<Props> = () => {
  const parsedUrlParams = querystring.parse(location.search.substr(1));
  const [tab, openTab] = useState<RolesTabs>(
    (parsedUrlParams.tab as RolesTabs) || RolesTabs.Roles
  );

  return (
    <div className={commonStyles.dictionaryTabsView}>
      <TabControlsWrapper<RolesTabs>
        items={ROLES_TABS_OPTIONS}
        selectedTab={tab}
        onChange={openTab}
        className={commonStyles.tabs}
      />
      {tab === RolesTabs.Roles && (
        <DictionaryView
          tableTitle={phrases.ROLES_TAB_ROLES}
          tableColumns={rolesTableColumns}
          dictionaryContext={useRolesContext}
          dictionaryContextMethods={useRolesMethods}
          defaultEmptyItem={NEW_ROLE_ITEM}
          asyncOptionsCell={CRITERIA_COLUMNS.SIMILAR_GROUPS}
          loadAsyncCellOptions={loadTitleAsyncOptionsWithExcluded}
          showManagerialControl
          tab={tab}
          isReadOnly
        />
      )}
      {tab === RolesTabs.RoleCategories && (
        <DictionaryView
          tableTitle={phrases.ROLES_TAB_ROLE_CATEGORIES}
          tableColumns={roleCategoriesTableColumns}
          dictionaryContext={useRoleCategoriesContext}
          dictionaryContextMethods={useRoleCategoriesMethods}
          defaultEmptyItem={NEW_ROLE_CATEGORY_ITEM}
          loadAsyncListOptions={loadTitleAsyncOptionsWithExcluded}
          overlayListEditorPlaceholder={
            phrases.ROLES_LIST_TYPEAHEAD_PLACEHOLDER
          }
          withOverlayEditor
          tab={tab}
        />
      )}
    </div>
  );
};
