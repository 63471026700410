import { InstitutionListUpsertRequest } from '@air/api';
import { ModifiedInstitutionResponse } from './criteria';
import { prepareAliasesForBackend } from 'domain/dictionaries/dictionaries';

export const prepareInstitutionsForBackend = (
  institutions: ModifiedInstitutionResponse[]
): InstitutionListUpsertRequest[] => {
  return institutions.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      shortName: item.shortName,
      aliases: prepareAliasesForBackend(item.aliases),
      country: item.country,
      state: item.state,
      city: item.city,
      accreditation: item.accreditation,
      type: item.type,
      deprecated: item.deprecated,
    } as InstitutionListUpsertRequest;
  });
};

export const prepareInstitutionsListsForBackend = (
  institutions: ModifiedInstitutionResponse[]
): InstitutionListUpsertRequest[] => {
  return institutions.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      items: item.items,
      deprecated: item.deprecated,
    } as InstitutionListUpsertRequest;
  });
};
