import React, { Dispatch, useCallback, useMemo } from 'react';
import { RouteProps } from 'react-router-dom';
import R from '@air/third-party/ramda';

import {
  ModifiedDictionaryGroupItem,
  UpdateFieldParamsT,
} from 'domain/dictionaries/criteria';
import { SelectOptionT } from '@air/domain/Forms/types';
import { CRITERIA_COLUMNS } from 'constants/criteria';
import {
  FormField,
  Header,
  OverlayPanel,
  Paragraph,
  SelectRenderer,
  SvgIcon,
} from '@air/components';
import { DictionaryIdentifiableItem } from '@air/api';
import * as phrases from 'constants/phrases';
import { LoadAsyncListOptionsT } from '@air/components/Select/Select';

// imports from styles
import styles from './OverlayListEditor.css';

type Props = {
  updateList?: ({ id, value, fieldName, item }: UpdateFieldParamsT) => void;
  setOverlayItem: Dispatch<any>;
  overlayItem: ModifiedDictionaryGroupItem<DictionaryIdentifiableItem>;
  loadAsyncListOptions?: LoadAsyncListOptionsT;
  placeholder?: string;
  isReadOnly?: boolean;
} & RouteProps;

export const OverlayListEditor: React.FC<Props> = ({
  updateList = R.noop,
  overlayItem,
  setOverlayItem,
  loadAsyncListOptions,
  placeholder,
  isReadOnly = false,
}) => {
  const onUpdateField = useCallback(
    (event: any, id: string, fieldName: string) => {
      const value =
        fieldName === CRITERIA_COLUMNS.DEPRECATED
          ? event.target.checked
          : event.target.value;
      updateList({
        id,
        value,
        item: overlayItem,
        fieldName,
      });
    },
    [overlayItem, updateList]
  );

  const onUpdateFieldList = useCallback(
    (item: SelectOptionT) => {
      updateList({
        id: overlayItem.id as string,
        value: item
          ? [
              ...overlayItem?.items,
              { id: Number(item.value), fullName: item.label },
            ]
          : overlayItem?.items,
        item: overlayItem,
        fieldName: CRITERIA_COLUMNS.ITEMS,
      });
    },
    [overlayItem, updateList]
  );

  const removeItemFromList = useCallback(
    (itemId: number) => {
      const updatedList = R.filter(
        (listItem) => listItem.id !== itemId,
        // @ts-ignore otherwise need to parametrise ramda here
        overlayItem?.items
      );
      updateList({
        id: overlayItem.id as string,
        value: updatedList,
        item: overlayItem,
        fieldName: CRITERIA_COLUMNS.ITEMS,
      });
    },
    [overlayItem, updateList]
  );
  const setOverlayItemCb = useCallback(
    () => setOverlayItem(null),
    [setOverlayItem]
  );

  const idsInUse = useMemo(
    () => overlayItem?.items.map((item) => item.id),
    [overlayItem?.items]
  );

  return (
    <OverlayPanel
      position={OverlayPanel.positions.right}
      visible={!R.isNil(overlayItem)}
      onClose={setOverlayItemCb}
    >
      {!R.isNil(overlayItem) && (
        <div className={styles.overlayListEditor}>
          <div className={styles.header}>
            {overlayItem.__isNew__ ? (
              <FormField
                id={CRITERIA_COLUMNS.FULLNAME}
                type="text"
                name={CRITERIA_COLUMNS.FULLNAME}
                placeholder={phrases.LIST_TITLE_INPUT_PLACEHOLDER}
                defaultValue={overlayItem.fullName}
                onKeyDown={(event: React.KeyboardEvent) =>
                  onUpdateField(
                    event,
                    String(overlayItem.id),
                    CRITERIA_COLUMNS.FULLNAME
                  )
                }
              />
            ) : (
              <Header level={3}>{overlayItem.fullName}</Header>
            )}
            <label className={styles.deprecated}>
              <input
                disabled={
                  !overlayItem.id || overlayItem.__isNew__ || isReadOnly
                }
                name={CRITERIA_COLUMNS.DEPRECATED}
                type="checkbox"
                className={styles.checkbox}
                checked={overlayItem.deprecated}
                onChange={(e) =>
                  onUpdateField(
                    e,
                    String(overlayItem.id),
                    CRITERIA_COLUMNS.DEPRECATED
                  )
                }
              />
              {CRITERIA_COLUMNS.DEPRECATED}
            </label>
            {!isReadOnly && !overlayItem.deprecated && (
              <SelectRenderer
                hideSelectedOptions
                isClearable
                creatable={false}
                asyncOptions={loadAsyncListOptions(idsInUse)}
                placeholder={placeholder}
                onChange={(value: SelectOptionT) => onUpdateFieldList(value)}
                className={styles.listsTypeahead}
              />
            )}
          </div>

          <div className={styles.main}>
            <div className={styles.addedItemsList}>
              {overlayItem?.items?.map((group: DictionaryIdentifiableItem) => {
                return (
                  <Paragraph key={group.id} className={styles.addedItem}>
                    {group.fullName}
                    {!isReadOnly && (
                      <SvgIcon
                        icon="cross-icon"
                        className={styles.remove}
                        onClick={() => removeItemFromList(group.id)}
                      />
                    )}
                  </Paragraph>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </OverlayPanel>
  );
};

OverlayListEditor.displayName = 'OverlayListEditor';
