import React, { useEffect } from 'react';
import { BrowserRouter as Router, NavLink, RouteProps } from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';

import styles from './LandingView.css';
import { Button, Logo } from '@air/components';
import { Routes } from 'routes';
import * as sharedPhrases from '@air/constants/phrases';
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import * as commonUrls from '@air/constants/commonUrls';
import {
  useAdminProfileContext,
  useAdminProfileMethods,
} from 'providers/AdminProfileProvider';
import { adminProfileSelectors } from 'selectors';

type Props = RouteProps;

const shouldRedirectFromRoute = (pathname: string) => {
  return (
    pathname === urls.ROOT_ROUTE ||
    pathname === urls.ROUTE_DICTIONARIES ||
    pathname === `${urls.ROUTE_DICTIONARIES}/`
  );
};

export const LandingView: React.FC<Props> = () => {
  const isUserLoaded = useAdminProfileContext(
    adminProfileSelectors.isUserLoaded
  );
  const authenticated = useAdminProfileContext(
    adminProfileSelectors.authenticated
  );
  const invalidToken = useAdminProfileContext(
    adminProfileSelectors.invalidToken
  );
  const { logout } = useAdminProfileMethods();
  const [showLeftPanel, toggleLeftPanel] = useToggle(true);

  useEffect(() => {
    if (
      isUserLoaded &&
      authenticated &&
      shouldRedirectFromRoute(window.location.pathname)
    ) {
      // This triggers extra reload
      window.location.href = urls.ROUTE_SKILLS;
      // TODO use this instead - now component inside route is not loaded
      // window.history.pushState({}, document.title, urls.ROUTE_SKILLS);
    }
  }, [isUserLoaded, authenticated]);

  if (isUserLoaded && (invalidToken || !authenticated)) {
    window.location.href = commonUrls.LOGOUT_REDIRECT_ROUTE;
  }

  return isUserLoaded ? (
    <Router>
      <div className={styles.landingView}>
        <div className={styles.header}>
          <Logo
            isWhiteLogoText
            onlyIconVisible
            className={styles.logoWrapper}
            onClick={toggleLeftPanel}
          />
          <div className={styles.topNavigation}>
            <NavLink
              className={styles.topNavigationLink}
              to={urls.ROUTE_CUSTOMER_COMPANIES}
              activeClassName={styles.selected}
            >
              {phrases.TOP_NAV_CUSTOMER_COMPANIES}
            </NavLink>
            <NavLink
              className={styles.topNavigationLink}
              to={urls.ROUTE_SKILLS}
              activeClassName={styles.selected}
            >
              {phrases.TOP_NAV_DICTIONARIES}
            </NavLink>
            <Button
              small
              className={styles.logoutButton}
              onClick={logout}
              variant={Button.variants.POSITIVE_CONFIRM}
            >
              {sharedPhrases.LOGOUT_BUTTON_TEXT}
            </Button>
          </div>
        </div>
        <Routes showLeftPanel={showLeftPanel} />
      </div>
    </Router>
  ) : null;
};
