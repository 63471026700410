import React from 'react';
import classNames from 'classnames';
import { RouteProps } from 'react-router-dom';
import { useTable, useSortBy, Row, Cell, HeaderGroup } from 'react-table';
import R from '@air/third-party/ramda';

import { Button, Loader, Paragraph, SvgIcon } from '@air/components';
import {
  CheckboxCell,
  InputCell,
  SelectCell,
  MultiselectCell,
  TextareaCell,
  TextCell,
  AmountCell,
} from 'components';
import { SortOrderT } from 'constants/api';
import { getNewSortOrder, TableColumnsT } from 'constants/tables';
import {
  GetCellComponentParamsT,
  getItemErrorMessage,
} from 'domain/dictionaries/dictionaries';
import {
  ModifiedCriteriaComputedResponse,
  ModifiedCriteriaListResponse,
  UpdateFieldParamsT,
} from 'domain/dictionaries/criteria';
import * as phrases from 'constants/phrases';

// imports from styles
import styles from './EditableTable.css';

type PropsT = {
  columns: TableColumnsT;
  data: Omit<ModifiedCriteriaListResponse, 'updatedItems'>;
  loadMoreItems: () => void;
  removeItem?: (id: string) => void;
  onCellClick?: (id: string) => void;
  expandItem?: (item: ModifiedCriteriaComputedResponse) => void;
  updateData?: ({ id, value, fieldName, item }: UpdateFieldParamsT) => void;
  getCellComponent: ({
    cell,
    row,
    removeItem,
    onCellClick,
  }: GetCellComponentParamsT) => any;
  fetchSortedItems: (sortField?: string, sortOrder?: string) => void;
  sortOrder?: SortOrderT;
  sortField?: string;
  sortableColumns?: string[];
  errorMapping?: { [key: string]: string };
  title?: string;
  isReadOnly?: boolean;
  pageNumber: number;
} & RouteProps;

const EditableTable: React.FC<PropsT> = ({
  columns,
  data = { items: [] },
  loadMoreItems,
  removeItem,
  onCellClick,
  updateData,
  getCellComponent,
  fetchSortedItems,
  sortOrder,
  sortField,
  sortableColumns,
  errorMapping,
  expandItem,
  title,
}) => {
  const defaultColumn = {
    Cell: TextCell,
    TextCell,
    CheckboxCell,
    InputCell,
    SelectCell,
    MultiselectCell,
    TextareaCell,
    AmountCell,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: data?.items,
        defaultColumn,
        updateData,
      },
      useSortBy
    );

  const computedTotalItems = R.max(data?.items?.length, data?.total);

  return (
    <div className={styles.editableTable}>
      <div className={styles.description}>
        {title ? `${title} | ` : ''}
        {phrases.getTableItemsAmountMessage(
          data?.items?.length,
          computedTotalItems
        )}
      </div>
      <form key={0} className={styles.tableWrapper}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup, index: number) => {
              return (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const columnName = column.id;
                    const canBeSorted = sortableColumns.includes(columnName);
                    const isSameField = sortField === columnName;
                    const isCurrentlySorted = canBeSorted && isSameField;
                    const newSortOrder = getNewSortOrder(
                      sortOrder,
                      isSameField
                    );
                    const handleThClick = canBeSorted
                      ? () => fetchSortedItems(column.id, newSortOrder)
                      : R.noop;

                    return (
                      <th
                        key={column.id}
                        className={classNames(
                          styles.tableTh,
                          styles[column.id],
                          {
                            [styles.isSortable]: canBeSorted,
                            [styles.isSorted]: isCurrentlySorted,
                          }
                        )}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        onClick={handleThClick}
                      >
                        {column.render('Header')}
                        {column.required ? '*' : ''}
                        {isCurrentlySorted ? (
                          sortOrder === SortOrderT.desc ? (
                            <span>&#8595;</span>
                          ) : (
                            <span>&#8593;</span>
                          )
                        ) : (
                          ''
                        )}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: Row) => {
              prepareRow(row);

              return (
                <>
                  {!R.isNullOrEmpty(row.original.errors) && (
                    <tr
                      key={`${row.id}-error`}
                      className={classNames(
                        styles.tableRow,
                        styles.errorMessage
                      )}
                    >
                      <td
                        key={`${row.id}-error-cell`}
                        colSpan={row.cells.length}
                      >
                        {getItemErrorMessage(row.original.errors, errorMapping)}
                      </td>
                    </tr>
                  )}
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    className={classNames(styles.tableRow, {
                      [styles.isRemoved]: row.original.deprecated,
                      [styles.isUpdated]:
                        row.original.__isUpdated__ && !row.original.__isNew__,
                      [styles.isNew]: row.original.__isNew__,
                      [styles.hasErrors]: !R.isNullOrEmpty(row.original.errors),
                    })}
                  >
                    {row.cells.map((cell: Cell) => {
                      const cellProps = cell.getCellProps();
                      const cellType = getCellComponent({
                        cell,
                        row,
                        removeItem,
                        onCellClick,
                      });

                      return (
                        <td
                          key={cellProps.key}
                          className={classNames(
                            styles.tableTd,
                            styles[cell.column.id]
                          )}
                          {...cellProps}
                        >
                          {cellType}
                        </td>
                      );
                    })}
                    {expandItem && (
                      <SvgIcon
                        title={phrases.REMOVE_ADDED_ITEM}
                        icon="shevron-down"
                        className={classNames(styles.expand, {
                          [styles.disabled]: row.original.disabled,
                        })}
                        onClick={
                          row.original.disabled
                            ? R.noop
                            : () => expandItem(row.original)
                        }
                      />
                    )}
                    {row.original.__isNew__ && (
                      <SvgIcon
                        title={phrases.REMOVE_ADDED_ITEM}
                        icon="cross-icon"
                        className={classNames(styles.remove, {
                          [styles.disabled]: row.original.disabled,
                        })}
                        onClick={
                          row.original.disabled
                            ? R.noop
                            : () => removeItem(row.original.id)
                        }
                      />
                    )}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
        {data?.total > data?.items?.length && (
          <Button
            className={styles.loadMoreButton}
            onClick={loadMoreItems}
            disabled={data.isLoading}
            variant={Button.variants.POSITIVE_SECONDARY}
            alignCenter
            small
          >
            {data.isLoading ? phrases.LOADING : phrases.LOAD_MORE}
          </Button>
        )}
      </form>
    </div>
  );
};

EditableTable.displayName = 'EditableTable';

export const EditableTableWrapper: React.FC<PropsT> = (props) => {
  const isFirstPageLoading = props.pageNumber === 0 && props.data.isLoading;
  const showResultsTable = props.data.items?.length > 0 && !isFirstPageLoading;

  if (isFirstPageLoading) {
    return (
      <div className={styles.editableTableWrapper}>
        <Loader />
      </div>
    );
  }

  return showResultsTable ? (
    <EditableTable {...props} />
  ) : (
    <div className={styles.editableTableWrapper}>
      <Paragraph>{phrases.NO_RESULTS}</Paragraph>
    </div>
  );
};

EditableTableWrapper.displayName = 'EditableTableWrapper';
