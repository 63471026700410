import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { Button, UIText, DateInput } from '@air/components';
import * as phrases from 'constants/phrases';

import styles from './BillingReportSection.css';
import sharedStyles from 'features/CustomerCompaniesView/CustomerCompanуForm/CustomerCompanyForm.css';
import { useCustomerCompaniesMethods } from 'providers/CustomerCompaniesProvider';

const DEFAULT_DATE_FROM = new Date(2022, 0, 1);
const DEFAULT_DATE_TO = new Date();
const FIELD_NAME_DATE_FROM = 'dateFrom';
const FIELD_NAME_DATE_TO = 'dateTo';

type Props = {
  companyId: string;
};

export const BillingReportSection: React.FC<Props> = ({ companyId }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      dateFrom: '' as Date | string,
      dateTo: '' as Date | string,
    },
  });
  const { handleSubmit, watch, trigger } = methods;

  const dateFrom = watch(FIELD_NAME_DATE_FROM);
  const dateTo = watch(FIELD_NAME_DATE_TO);

  useEffect(() => {
    trigger(['dateFrom', 'dateTo']);
  }, [dateFrom, dateTo, trigger]);

  const { getBillingReport } = useCustomerCompaniesMethods();
  const [isLoading, setIsLoading] = useState(false);

  const isDateFromValid = (date: Date | string) => {
    const selectedDate = typeof date === 'object' ? date.toDateString() : date;

    return dateTo && selectedDate
      ? Date.parse(dateTo as string) >= Date.parse(selectedDate)
      : true;
  };

  const isDateToValid = (date: Date | string) => {
    const selectedDate =
      date && (typeof date === 'object' ? date.toDateString() : new Date(date));

    return dateFrom && selectedDate
      ? Date.parse(dayjs(dateFrom).format('MM/D/YYYY')) <=
          Date.parse(dayjs(selectedDate).format('MM/D/YYYY'))
      : true;
  };

  const onSubmit = async ({
    dateFrom,
    dateTo,
  }: {
    dateFrom: Date;
    dateTo: Date;
  }) => {
    setIsLoading(true);
    await getBillingReport({
      companyId,
      from: dateFrom.getTime(),
      to: dateTo.getTime(),
    });
    setIsLoading(false);
  };

  const isButtonEnabled = () => {
    return dateFrom && dateTo
      ? Date.parse(dateFrom as string) <= Date.parse(dateTo as string)
      : false;
  };

  return (
    <div className={styles.billingReportSection}>
      <div className={sharedStyles.formGroup}>
        <UIText small className={sharedStyles.formGroupTitle}>
          {phrases.BILLING_REPORT_SECTION_LABEL}
        </UIText>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={sharedStyles.inputsWrapper}>
              <DateInput
                selectedDate={dateFrom as unknown as Date}
                className={styles.dateFromInput}
                label={phrases.BILLING_REPORT_DATE_FROM_LABEL}
                fieldName={FIELD_NAME_DATE_FROM}
                fromMonth={DEFAULT_DATE_FROM}
                toMonth={DEFAULT_DATE_TO}
                isDateValid={isDateFromValid}
                disabledDays={{
                  after: new Date(),
                }}
              />
              <DateInput
                selectedDate={dateTo as unknown as Date}
                className={styles.dateToInput}
                label={phrases.BILLING_REPORT_DATE_TO_LABEL}
                fieldName={FIELD_NAME_DATE_TO}
                fromMonth={DEFAULT_DATE_FROM}
                toMonth={DEFAULT_DATE_TO}
                isDateValid={isDateToValid}
                disabledDays={{
                  after: new Date(),
                }}
              />
              <Button
                className={styles.submitButton}
                variant={Button.variants.POSITIVE_MAIN}
                onClick={handleSubmit(onSubmit)}
                isLoading={isLoading}
                disabled={!isButtonEnabled()}
              >
                {phrases.BILLING_REPORT_SUBMIT_BUTTON_TEXT}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
