import React from 'react';
import { Column, Row } from 'react-table';

import * as phrases from 'constants/phrases';
import { UpdateFieldParamsT } from 'domain/dictionaries/criteria';
import { CRITERIA_COLUMNS } from 'constants/criteria';

// imports from styles
import styles from './CheckboxCell.css';

type Props = {
  row: Row;
  column: Column;
  updateData?: ({ value, id, fieldName }: UpdateFieldParamsT) => void;
  disabled?: boolean;
};

export const CheckboxCell: React.FC<Props> = (props) => {
  const {
    row: { original },
    column,
    updateData,
    disabled,
  } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    updateData({ value, id: original.id, fieldName: column.id });
  };
  const isCellDisabled =
    disabled ||
    (original.isNew && column.id === CRITERIA_COLUMNS.DEPRECATED) ||
    (original.deprecated && column.id === CRITERIA_COLUMNS.LANGUAGE) ||
    !updateData;

  const value = original[column.id];

  return (
    <div className={styles.CheckboxCell}>
      <label>
        <input
          disabled={isCellDisabled}
          name={original.id}
          type="checkbox"
          className={styles.checkbox}
          checked={value}
          value={value}
          onChange={onChange}
        />
        {original[column.id] ? phrases.YES : phrases.NO}
      </label>
    </div>
  );
};

CheckboxCell.displayName = 'CheckboxCell';
