import React, { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import querystring from 'query-string';

import { DictionaryView, TabControlsWrapper } from 'components';

import * as phrases from 'constants/phrases';
import {
  majorGroupsTableColumns,
  majorsTableColumns,
  NEW_MAJORS_GROUP_ITEM,
  MAJORS_TABS_OPTIONS,
} from 'constants/majors';
import { MajorsTabs } from '@air/constants/tabs';

import commonStyles from 'styles/commonDictionaries.css';
import {
  useMajorGroupsContext,
  useMajorGroupsMethods,
} from 'providers/MajorGroupsProvider';
import { useMajorsContext, useMajorsMethods } from 'providers/MajorsProvider';

type Props = RouteProps;

export const MajorsView: React.FC<Props> = () => {
  const parsedUrlParams = querystring.parse(location.search.substr(1));
  const [tab, openTab] = useState<MajorsTabs>(
    (parsedUrlParams.tab as MajorsTabs) || MajorsTabs.Majors
  );

  return (
    <div className={commonStyles.dictionaryTabsView}>
      <TabControlsWrapper<MajorsTabs>
        items={MAJORS_TABS_OPTIONS}
        selectedTab={tab}
        onChange={openTab}
        className={commonStyles.tabs}
      />
      {tab === MajorsTabs.Majors && (
        <DictionaryView
          tableTitle={phrases.MAJORS_TAB_MAJORS}
          tableColumns={majorsTableColumns}
          dictionaryContext={useMajorsContext}
          dictionaryContextMethods={useMajorsMethods}
          tab={tab}
          isReadOnly
        />
      )}
      {tab === MajorsTabs.MajorGroups && (
        <DictionaryView
          tableTitle={phrases.MAJORS_TAB_MAJOR_GROUPS}
          tableColumns={majorGroupsTableColumns}
          dictionaryContext={useMajorGroupsContext}
          dictionaryContextMethods={useMajorGroupsMethods}
          defaultEmptyItem={NEW_MAJORS_GROUP_ITEM}
          tab={tab}
          withOverlayEditor
          isReadOnly
        />
      )}
    </div>
  );
};
