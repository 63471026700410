import React, { useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import * as phrases from 'constants/phrases';
import { LOGO_DROPZONE_CONFIG } from 'constants/customerCompanies';
import { toast } from '@air/third-party/toast';
import { makeCustomerCompanyLogoUrl } from '@air/constants/apiEndpoints';
import { LogoT } from 'domain/customerCompanies';

import { Button, SvgIcon } from '@air/components';

// imports from styles
import styles from './LogoUploader.css';

type Props = {
  logo?: LogoT;
  handleUpload?: (params: LogoT) => void;
  isDisabled?: boolean;
};

export const LogoUploader = React.memo((props: Props) => {
  const { handleUpload, logo, isDisabled } = props;

  const { getRootProps, getInputProps, open } = useDropzone({
    ...LOGO_DROPZONE_CONFIG,
    noDrag: true,
    onDropRejected: () => toast.warning(phrases.NOT_SUPPORTED_FILE_ERROR),
    onDropAccepted: (logos: File[]) => {
      handleUpload({ new: logos[0] }); // only 1 file is allowed
    },
  });

  const imgRef = useRef(null);
  const logoImageSrc = logo.existing?.id
    ? makeCustomerCompanyLogoUrl(logo.existing.id)
    : null;

  const toggleImage = (show?: boolean) => {
    if (imgRef?.current) {
      imgRef.current.style.visibility = show ? 'visible' : `hidden`;
    }
  };

  const showExistingLogo = logo.existing.name && !logo.new.name;
  const showNewLogo = !showExistingLogo && logo.new.name;
  const showUploadButton = !showExistingLogo && !showNewLogo;

  return (
    <div className={styles.logoUploader}>
      {showUploadButton && (
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <Button
            variant={Button.variants.INLINE}
            className={styles.uploadLogoButton}
            onClick={open}
            disabled={isDisabled}
          >
            <div className={styles.icon}>
              <SvgIcon icon="plus" />
            </div>
            <span>{phrases.CUSTOMER_COMPANY_LOGO_UPLOAD}</span>
          </Button>
        </div>
      )}

      {showExistingLogo && (
        <div className={styles.logoName}>
          {logo.existing.name}
          <SvgIcon
            icon="cross-icon"
            onClick={() =>
              handleUpload({
                existing: {
                  name: null,
                  id: logo.existing.id,
                },
              })
            }
          />
          {logoImageSrc && (
            <img
              ref={imgRef}
              src={logoImageSrc}
              onError={() => toggleImage(false)}
              onLoad={() => toggleImage(true)}
              className={styles.companyLogo}
            />
          )}
        </div>
      )}

      {showNewLogo && (
        <div className={styles.logoName}>
          {logo.new.name}
          <SvgIcon
            icon="cross-icon"
            onClick={() => {
              handleUpload({ ...logo, new: {} });
            }}
          />
          <img
            src={URL.createObjectURL(logo.new as Blob)}
            className={styles.companyLogo}
          />
        </div>
      )}
    </div>
  );
});

LogoUploader.displayName = 'ImageUploader';
