import React, { useState } from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import DayPicker from 'react-day-picker';

import { useOutsideClick } from '@air/utils/hooks';
import { FormField } from '@air/components';

import styles from './DateInput.css';
import 'react-day-picker/lib/style.css';

export const buildDateStringForInput = (date: Date) =>
  date ? dayjs(date).format('D MMMM, YYYY') : '';

type Props = {
  selectedDate: Date;
  className?: string;
  label: string;
  fieldName: string;
  fromMonth?: Date;
  toMonth?: Date;
  isDisabled?: boolean;
  isRequired?: boolean;
  isDateValid: (date: Date, isRequired: boolean) => boolean;
  disabledDays?: {
    after: Date;
  };
};

export const DateInput: React.FC<Props> = ({
  selectedDate,
  className,
  label,
  fieldName,
  fromMonth,
  toMonth,
  isDisabled,
  isRequired = false,
  isDateValid,
  disabledDays,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const { control, setValue } = useFormContext();

  const handleSelectDate = (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }
    setValue(fieldName, date, { shouldValidate: true, shouldDirty: true });
    setShowCalendar(false);
  };

  const closeCalendar = () => {
    setShowCalendar(false);
  };

  const [outsideClickRef] = useOutsideClick(closeCalendar, {
    useCapture: true,
  });

  return (
    <div
      className={classNames(className, styles.dateInput)}
      ref={outsideClickRef}
    >
      <Controller
        name={fieldName}
        control={control}
        rules={{ validate: (date: Date) => isDateValid(date, isRequired) }}
        render={({ field, fieldState }) => {
          return (
            <FormField
              autoComplete="off"
              type="text"
              label={label}
              hasError={fieldState.invalid}
              isDisabled={isDisabled}
              onClick={() =>
                setShowCalendar((showCalendar: boolean) => !showCalendar)
              }
              {...field}
              onChange={() => field.value}
              value={buildDateStringForInput(field.value)}
            />
          );
        }}
      />
      {showCalendar && (
        <DayPicker
          canChangeMonth
          selectedDays={selectedDate}
          onDayClick={handleSelectDate}
          className={styles.datePicker}
          fromMonth={fromMonth}
          toMonth={toMonth}
          disabledDays={disabledDays}
        />
      )}
    </div>
  );
};
