import 'normalize.css/normalize.css';
import '@air/styles/index.css';
import 'styles/index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Direct import to avoid circular dependency.
import App from './features/App/App';

// Insert icons sprite as inline svg.
fetch(`/${SVG_SPRITE_FILE}`)
  .then((res) => res.text())
  .then((res) => document.body.insertAdjacentHTML('beforeend', res));

ReactDOM.render(<App />, document.getElementById('app'));
