import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { FormField, UIText } from '@air/components';
import * as phrases from 'constants/phrases';
import * as sharedPhrases from '@air/constants/phrases';
import {
  defaultFieldValidationRules,
  getFormLabel,
  MAX_FIELD_LENGTH,
} from 'domain/customerCompanies';
import { emailRegex, passwordRegex } from '@air/utils/strings';

import sharedStyles from 'features/CustomerCompaniesView/CustomerCompanуForm/CustomerCompanyForm.css';

type Props = {
  control?: Control<Record<string, any>, object>;
  isDisabled: boolean;
};

export const AdminSettingsSection: React.FC<Props> = ({
  control,
  isDisabled,
}) => {
  return (
    <div className={sharedStyles.formGroup}>
      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_GROUP_ADMIN}
      </UIText>
      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="adminFirstName"
          control={control}
          rules={defaultFieldValidationRules}
          render={({ field, fieldState }) => (
            <FormField
              type="text"
              isDisabled={isDisabled}
              hasError={fieldState.invalid}
              maxLength={MAX_FIELD_LENGTH}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_ADMIN_FIRSTNAME,
                fieldState
              )}
              {...field}
            />
          )}
        />
        <Controller
          name="adminLastName"
          control={control}
          rules={defaultFieldValidationRules}
          render={({ field, fieldState }) => (
            <FormField
              type="text"
              isDisabled={isDisabled}
              hasError={fieldState.invalid}
              maxLength={MAX_FIELD_LENGTH}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_ADMIN_LASTNAME,
                fieldState
              )}
              {...field}
            />
          )}
        />
      </div>
      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="adminEmail"
          control={control}
          rules={{
            required: {
              value: true,
              message: phrases.FORM_ERROR_MISSING,
            },
            pattern: {
              value: emailRegex,
              message: phrases.FORM_ERROR_INVALID,
            },
          }}
          render={({ field, fieldState }) => (
            <FormField
              type="email"
              isDisabled={isDisabled}
              hasError={fieldState.invalid}
              maxLength={MAX_FIELD_LENGTH}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_ADMIN_EMAIL,
                fieldState
              )}
              {...field}
            />
          )}
        />
        <Controller
          name="adminPassword"
          control={control}
          rules={{
            required: {
              value: true,
              message: phrases.CUSTOMER_COMPANY_MISSING_PASSWORD,
            },
            minLength: {
              value: 8,
              message: sharedPhrases.PASSWORD_ERROR_WEAK_VALUE,
            },
            maxLength: {
              value: 100,
              message: sharedPhrases.PASSWORD_ERROR_LONG_VALUE,
            },
            pattern: {
              value: passwordRegex,
              message: sharedPhrases.PASSWORD_REGEX_HINT,
            },
          }}
          render={({ field, fieldState }) => (
            <FormField
              type="password"
              isDisabled={isDisabled}
              hasError={fieldState.invalid}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_ADMIN_PASSWORD,
                fieldState,
                true
              )}
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};
