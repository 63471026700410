import React, { useState, useCallback } from 'react';
import { RouteProps } from 'react-router-dom';
import querystring from 'query-string';

import { DictionaryView, TabControlsWrapper } from 'components';
import { loadInstitutionOptions } from '@air/api-tasks/dictionariesApi';

import { InstitutionsTabs } from '@air/constants/tabs';
import * as phrases from 'constants/phrases';
import {
  institutionsListsTableColumns,
  institutionsTableColumns,
  NEW_INSTITUTION_ITEM,
  NEW_INSTITUTIONS_LIST_ITEM,
  UNIVERSITIES_TABS_OPTIONS,
} from 'constants/instututions';

import commonStyles from 'styles/commonDictionaries.css';
import {
  useInstitutionsListsContext,
  useInstitutionsListsMethods,
} from 'providers/InstitutionsListsProvider';
import {
  useInstitutionsContext,
  useInstitutionsMethods,
} from 'providers/InstitutionsProvider';

type Props = RouteProps;

export const InstitutionsView: React.FC<Props> = () => {
  const parsedUrlParams = querystring.parse(location.search.substr(1));
  const [tab, openTab] = useState<InstitutionsTabs>(
    (parsedUrlParams.tab as InstitutionsTabs) || InstitutionsTabs.Institutions
  );

  const loadAsyncListOptions = useCallback(
    (data) => loadInstitutionOptions(data, true),
    []
  );

  return (
    <div className={commonStyles.dictionaryTabsView}>
      <TabControlsWrapper<InstitutionsTabs>
        items={UNIVERSITIES_TABS_OPTIONS}
        selectedTab={tab}
        onChange={openTab}
        className={commonStyles.tabs}
      />
      {tab === InstitutionsTabs.Institutions && (
        <DictionaryView
          tableTitle={phrases.INSTITUTIONS_TAB_UNIVERSITIES}
          tableColumns={institutionsTableColumns}
          dictionaryContext={useInstitutionsContext}
          dictionaryContextMethods={useInstitutionsMethods}
          defaultEmptyItem={NEW_INSTITUTION_ITEM}
          tab={tab}
        />
      )}
      {tab === InstitutionsTabs.InstitutionsLists && (
        <DictionaryView
          tableTitle={phrases.INSTITUTIONS_TAB_UNIVERSITY_LISTS}
          tableColumns={institutionsListsTableColumns}
          dictionaryContext={useInstitutionsListsContext}
          dictionaryContextMethods={useInstitutionsListsMethods}
          defaultEmptyItem={NEW_INSTITUTIONS_LIST_ITEM}
          loadAsyncListOptions={loadAsyncListOptions}
          overlayListEditorPlaceholder={
            phrases.INSTITUTIONS_LIST_TYPEAHEAD_PLACEHOLDER
          }
          withOverlayEditor
          tab={tab}
        />
      )}
    </div>
  );
};
