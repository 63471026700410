import React, { useMemo, useState, useCallback } from 'react';
import { toast } from '@air/third-party/toast';

import { DegreesContext, DictionaryContextT } from 'context';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';

import {
  prepareDictionaryObjectForUI,
  FetchCriteriaParamsT,
  DmsDictionaryResponse,
  ModifiedCriteriaListResponse,
} from 'domain/dictionaries/criteria';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { DmsEducationDegree } from '@air/api';
import { downloadFile } from 'domain/dictionaries/dictionaries';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { ExtraResponseParamsT } from '@air/domain/Common/apiTypes';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

const DEFAULT_FILE_NAME = 'company_sizes_dictionary';

export const DegreesProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] =
    useState<ModifiedCriteriaListResponse>(DEFAULT_DICTIONARY_ITEM);

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      const { sortField, sortOrder } = params;
      setDictionaryItems((state) => ({
        ...state,
        sortField: sortField || state?.sortField,
        sortOrder: sortOrder || state?.sortOrder,
        isLoading: true,
      }));

      DictionariesApi.fetchDictionary<
        DmsDictionaryResponse<DmsEducationDegree>
      >(params, sharedUrls.DICTIONARY_API_DEGREE).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: DmsDictionaryResponse<DmsEducationDegree>) => {
          setDictionaryItems((state) => {
            return prepareDictionaryObjectForUI<
              DmsDictionaryResponse<DmsEducationDegree>
            >(state, response, params);
          });
        }
      );
    },
    []
  );

  const exportDictionaryItems = useCallback(() => {
    const exportUrl = sharedUrls.makeDictionaryExportUrl(
      sharedUrls.CATEGORY_DEGREE
    );
    DictionariesApi.exportDictionary(exportUrl).fork(
      () => toast.error(phrases.DICTIONARY_EXPORT_ERROR),
      (response: Blob, extraParams: ExtraResponseParamsT) => {
        return downloadFile(response, extraParams?.headers, DEFAULT_FILE_NAME);
      }
    );
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      items: dictionaryItems.items as DmsEducationDegree[],
      updatedItems: dictionaryItems.updatedItems as DmsEducationDegree[],
      methods: {
        fetchDictionaryItems,
        exportDictionaryItems,
      },
    };
  }, [dictionaryItems, fetchDictionaryItems, exportDictionaryItems]);

  return (
    <DegreesContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </DegreesContext.Provider>
  );
};

export const useDegreesContext = <Selected,>(
  selector: (state: DictionaryContextT<DmsEducationDegree>) => Selected
) => {
  return useContextSelector(DegreesContext, selector);
};

export const useDegreesMethods = () => {
  return useEqualContextSelector(
    DegreesContext,
    (state: DictionaryContextT<DmsEducationDegree>) => state.methods,
    R.shallowEqualObjects
  );
};

DegreesProvider.displayName = 'DegreesProvider';
