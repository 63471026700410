import { CRITERIA_COLUMNS } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { InstitutionsTabs } from '@air/constants/tabs';
import {
  AccreditationResponse,
  EducationInstitutionAccreditaionEnum,
  InstitutionList,
  InstitutionType,
} from '@air/api';

export const UNIVERSITIES_TABS_OPTIONS = [
  {
    value: InstitutionsTabs.Institutions,
    label: phrases.INSTITUTIONS_TAB_UNIVERSITIES,
  },
  {
    value: InstitutionsTabs.InstitutionsLists,
    label: phrases.INSTITUTIONS_TAB_UNIVERSITY_LISTS,
  },
];

export const institutionsTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SHORT_NAME,
    accessor: CRITERIA_COLUMNS.SHORTNAME,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ALIASES,
    accessor: CRITERIA_COLUMNS.ALIASES,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ACCREDITATION,
    accessor: CRITERIA_COLUMNS.ACCREDITATION,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_INSTITUTION_TYPE,
    accessor: CRITERIA_COLUMNS.INSTITUTION_TYPE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_COUNTRY,
    accessor: CRITERIA_COLUMNS.COUNTRY,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_STATE,
    accessor: CRITERIA_COLUMNS.STATE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CITY,
    accessor: CRITERIA_COLUMNS.CITY,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

export const NEW_INSTITUTION_ITEM = {
  __isNew__: true,
  fullName: '',
  shortName: '',
  aliases: '',
  accreditation: EducationInstitutionAccreditaionEnum.UNACCREDITED,
  type: InstitutionType.UNKNOWN,
  country: '',
  state: '',
  city: '',
  created: '',
  updated: '',
  deprecated: false,
};

// options need to have a certain order
export const ACCREDITATION_OPTIONS = [
  {
    value: AccreditationResponse.UNKNOWN,
    label: AccreditationResponse.UNKNOWN,
  },
  {
    value: AccreditationResponse.ACCREDITED,
    label: AccreditationResponse.ACCREDITED,
  },
  {
    value: AccreditationResponse.PREACCREDITED,
    label: AccreditationResponse.PREACCREDITED,
  },
  {
    value: AccreditationResponse.UNACCREDITED,
    label: AccreditationResponse.UNACCREDITED,
  },
];

export const institutionsListsTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UNI_LIST_AMOUNT,
    accessor: CRITERIA_COLUMNS.ITEMS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];

// options need to have a certain order
export const INSTITUTION_TYPE_OPTIONS = [
  { value: InstitutionType.UNKNOWN, label: InstitutionType.UNKNOWN },
  { value: InstitutionType.COLLEGE, label: InstitutionType.COLLEGE },
  { value: InstitutionType.COMMUNITY, label: InstitutionType.COMMUNITY },
  { value: InstitutionType.UNIVERSITY, label: InstitutionType.UNIVERSITY },
  { value: InstitutionType.PROFESSIONAL, label: InstitutionType.PROFESSIONAL },
  { value: InstitutionType.HIGHSCHOOL, label: InstitutionType.HIGHSCHOOL },
  { value: InstitutionType.SECONDARY, label: InstitutionType.SECONDARY },
];

type NewInstitutionsListItemT = InstitutionList & {
  __isNew__: boolean;
  deprecated: boolean;
};

export const NEW_INSTITUTIONS_LIST_ITEM: NewInstitutionsListItemT = {
  __isNew__: true,
  id: null,
  fullName: '',
  items: [],
  created: null,
  updated: null,
  deprecated: false,
};
