import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';

export const NAVIGATION = [
  {
    to: urls.ROUTE_SKILLS,
    text: phrases.NAVIGATION_LINK_SKILLS,
  },
  {
    to: urls.ROUTE_ROLES,
    text: phrases.NAVIGATION_LINK_ROLES,
  },
  {
    to: urls.ROUTE_COMPANIES_SINGLE,
    text: phrases.NAVIGATION_LINK_COMPANIES,
  },
  {
    to: urls.ROUTE_CERTIFICATIONS,
    text: phrases.NAVIGATION_LINK_CERTIFICATIONS,
  },
  {
    to: urls.ROUTE_INSTITUTIONS_SINGLE,
    text: phrases.NAVIGATION_LINK_UNIVERSITIES,
  },
  {
    to: urls.ROUTE_DEGREES,
    text: phrases.NAVIGATION_LINK_DEGREES,
  },
  {
    to: urls.ROUTE_MAJORS_SINGLE,
    text: phrases.NAVIGATION_LINK_MAJORS,
  },
  {
    to: urls.ROUTE_INDUSTRIES,
    text: phrases.NAVIGATION_LINK_INDUSTRIES,
  },
];
