import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Select from 'react-select';
import classNames from 'classnames';

import { EditableTableWrapper, TypeaheadSearchFieldWrapper } from 'components';
import { Button, CheckboxList, LabeledDropdown } from '@air/components';
import { CustomerCompanyForm } from 'features';

import { PAGE_START, REQUEST_PAGING_SIZE, SortOrderT } from 'constants/api';
import * as phrases from 'constants/phrases';
import * as urls from 'constants/urls';
import { NEW_ITEM } from 'constants/dictionaries';
import {
  CRITERIA_ERRORS_MAPPING,
  SORTABLE_DICTIONARIES_COLUMNS,
  SortFieldsT,
} from 'constants/criteria';
import {
  customerCompaniesTableColumns,
  LicenseTypeOptions,
} from 'constants/customerCompanies';

import { FetchCriteriaParamsT } from 'domain/dictionaries/criteria';
import { CheckboxOptionT, SelectOptionT } from '@air/domain/Forms/types';
import { getCellComponentForCriteria } from 'domain/tableHelpers';
import {
  useCustomerCompaniesContext,
  useCustomerCompaniesMethods,
} from 'providers/CustomerCompaniesProvider';
import { dictionarySelectors } from 'selectors';
import { EventType, NotificationEvent } from '@air/api';
import {
  aggregateTimeout,
  MessageConsumer,
  registerSSESubscriber,
} from '@air/lib/server-notifications';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';
import {
  updateCompanyManagersSelection,
  prepareOptionsArrayValues,
  prepareSelectedCompanyIds,
} from 'domain/dictionaries/companies';

import styles from './CustomerCompaniesView.css';

type Props = RouteComponentProps<{
  companyId?: string;
}>;

const ACCOUNT_MANAGER_EVENTS = [
  EventType.CUSTOMERCOMPANYACCOUNTMANAGERUPDATED,
  EventType.CUSTOMERCOMPANYACCOUNTMANAGERDELETED,
];

export const CustomerCompaniesView: React.FC<Props> = (props) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    props.match?.params?.companyId
  );
  const [pageNumber, setPageNumber] = useState(PAGE_START);
  const [keyword, setKeyword] = useState('');
  const [licenseType, setLicenseType] = useState([] as SelectOptionT[]);
  const [companyManagersList, setCompanyManagersList] = useState(
    [] as CheckboxOptionT[]
  );

  const items = useCustomerCompaniesContext(dictionarySelectors.items);
  const isLoading = useCustomerCompaniesContext(dictionarySelectors.isLoading);
  const total = useCustomerCompaniesContext(dictionarySelectors.total);
  const sortField = useCustomerCompaniesContext(dictionarySelectors.sortField);
  const sortOrder = useCustomerCompaniesContext(dictionarySelectors.sortOrder);
  const companyManagers = useCustomerCompaniesContext(
    dictionarySelectors.companyManagers
  );
  const {
    fetchDictionaryItems,
    updateDictionaryItem,
    fetchCompanyManagers,
    updateItem,
  } = useCustomerCompaniesMethods();

  const subscriptions = useRef([]);

  const sseConsumers = useRef({
    updates: new MessageConsumer(aggregateTimeout(1000)),
  });

  useEffect(() => {
    subscriptions.current.push(
      sseConsumers.current.updates.subscribe((events: NotificationEvent[]) => {
        events.forEach((event) => {
          if (ACCOUNT_MANAGER_EVENTS.includes(event.eventType)) {
            fetchCompanyManagers().then((result) => {
              setCompanyManagersList((state) => {
                // update a single company locally by event.companyId
                updateItem({ newLocalItem: event.payload });
                return updateCompanyManagersSelection(result, state);
              });
            });
          }
        });
      })
    );
    registerSSESubscriber(
      (event: NotificationEvent | SSEConnectionErrorEvent) => {
        return sseConsumers.current.updates.onEventReceived(event);
      }
    );
  }, [fetchCompanyManagers, fetchDictionaryItems, updateItem]);

  useEffect(() => {
    if (companyManagersList?.length === 0 && companyManagers?.length > 0) {
      setCompanyManagersList(companyManagers);
    }
  }, [companyManagersList, companyManagers]);

  const selectAccountManagers = (values: CheckboxOptionT[]) => {
    fetchDictionaryItems(
      {
        keyword,
        sortField,
        sortOrder,
        licenseType: prepareOptionsArrayValues(licenseType),
        includeId: prepareSelectedCompanyIds(values),
      },
      true
    );
    setCompanyManagersList(values);
  };

  const dictionary = useMemo(
    () => ({
      items,
      isLoading,
      total,
      sortField,
      sortOrder,
    }),
    [isLoading, items, sortField, sortOrder, total]
  );

  useEffect(() => {
    // first fetching of items
    fetchDictionaryItems({
      page: PAGE_START,
      size: REQUEST_PAGING_SIZE,
    });
    fetchCompanyManagers();
  }, [fetchDictionaryItems, fetchCompanyManagers]);

  const loadMoreItems = useCallback(() => {
    if (total !== items?.length && !isLoading) {
      const page = pageNumber + 1;
      fetchDictionaryItems(
        {
          page,
          keyword,
          sortField,
          sortOrder,
          licenseType: prepareOptionsArrayValues(licenseType),
          includeId: prepareSelectedCompanyIds(companyManagersList),
        },
        true
      );
      setPageNumber(page);
    }
  }, [
    pageNumber,
    setPageNumber,
    isLoading,
    fetchDictionaryItems,
    sortField,
    sortOrder,
    keyword,
    items,
    total,
    licenseType,
    companyManagersList,
  ]);

  const onUpdateSearchField = useCallback(
    (props: FetchCriteriaParamsT) => {
      setPageNumber(PAGE_START);
      setKeyword(props?.keyword || '');
      fetchDictionaryItems({
        page: PAGE_START,
        sortField,
        sortOrder,
        licenseType: prepareOptionsArrayValues(licenseType),
        includeId: prepareSelectedCompanyIds(companyManagersList),
        ...props,
      });
    },
    [
      sortField,
      sortOrder,
      licenseType,
      fetchDictionaryItems,
      setPageNumber,
      companyManagersList,
    ]
  );

  const onSetLicenseType = useCallback(
    (options: SelectOptionT[]) => {
      setLicenseType(options);
      setPageNumber(PAGE_START);
      fetchDictionaryItems({
        keyword,
        page: PAGE_START,
        sortField,
        sortOrder,
        licenseType: prepareOptionsArrayValues(options),
        includeId: prepareSelectedCompanyIds(companyManagersList),
      });
    },
    [
      fetchDictionaryItems,
      keyword,
      sortField,
      sortOrder,
      setPageNumber,
      companyManagersList,
    ]
  );

  const handleCellClick = (companyId: string) => {
    // clicking on companies title cell
    setSelectedCompanyId(companyId);
    history.pushState(
      {},
      document.title,
      urls.makeCustomerCompanyRoute(companyId)
    );
  };

  const handleCreateNewCompanyClick = () => {
    setSelectedCompanyId(NEW_ITEM);
    history.pushState(
      {},
      document.title,
      urls.makeCustomerCompanyRoute(NEW_ITEM)
    );
  };

  const handleCloseForm = () => {
    setSelectedCompanyId(null);
    history.pushState({}, document.title, urls.ROUTE_CUSTOMER_COMPANIES);
  };

  const fetchSortedItems = (
    sortField?: SortFieldsT,
    sortOrder?: SortOrderT
  ) => {
    setPageNumber(PAGE_START);
    fetchDictionaryItems({
      page: PAGE_START,
      sortField,
      sortOrder,
      keyword,
      licenseType: prepareOptionsArrayValues(licenseType),
      includeId: prepareSelectedCompanyIds(companyManagersList),
    });
  };

  return (
    <div className={styles.organizationView}>
      <div className={styles.tableControls}>
        <div className={styles.column}>
          <Button
            onClick={handleCreateNewCompanyClick}
            variant={Button.variants.CHIP}
          >
            <span>{phrases.BUTTON_CREATE_CUSTOMER_COMPANY}</span>
          </Button>
        </div>
        <div className={styles.column}>
          <TypeaheadSearchFieldWrapper
            onUpdateSearchField={onUpdateSearchField}
            searchFieldName="keyword"
            setSearchField={setKeyword}
          />
        </div>
        <div className={classNames(styles.column, styles.licenseWrapper)}>
          <Select
            isMulti
            classNamePrefix="licenseMultiSelect"
            options={LicenseTypeOptions}
            placeholder={phrases.CRITERIA_COLUMN_DROPDOWN_TITLE_LICENSE_STATUS}
            value={licenseType}
            onChange={onSetLicenseType}
            name="licenseType"
          />
        </div>
        <div className={styles.column}>
          <LabeledDropdown
            icon="applicant-icon"
            tooltipText={phrases.CUSTOMER_COMPANY_ACCOUNT_MANAGERS}
            tooltipContainerClassName={styles.companyManagersTooltip}
          >
            <CheckboxList
              wrapperClassName={styles.companyManagersCheckboxList}
              options={companyManagersList}
              onChange={(values: CheckboxOptionT[]) =>
                selectAccountManagers(values)
              }
              placement="bottom-end"
            />
          </LabeledDropdown>
        </div>
      </div>
      <div className={styles.tableView}>
        <EditableTableWrapper
          title={phrases.TOP_NAV_CUSTOMER_COMPANIES}
          sortOrder={sortOrder}
          sortField={sortField}
          sortableColumns={SORTABLE_DICTIONARIES_COLUMNS}
          fetchSortedItems={fetchSortedItems}
          columns={customerCompaniesTableColumns}
          data={dictionary}
          loadMoreItems={loadMoreItems}
          updateData={updateDictionaryItem}
          onCellClick={(id) => handleCellClick(id)}
          getCellComponent={getCellComponentForCriteria}
          errorMapping={CRITERIA_ERRORS_MAPPING}
          pageNumber={pageNumber}
        />
      </div>
      {selectedCompanyId && (
        <div className={styles.organizationFormWrapper}>
          <CustomerCompanyForm
            closeForm={handleCloseForm}
            keyword={keyword}
            licenseType={prepareOptionsArrayValues(licenseType)}
            companyId={selectedCompanyId}
          />
        </div>
      )}
    </div>
  );
};

CustomerCompaniesView.displayName = 'CustomerCompaniesView';
