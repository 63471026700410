/*
 * App routes
 */

import { MajorsTabs, CompanyTabs, InstitutionsTabs } from '@air/constants/tabs';
import { getUrlWithParamsAndCurrentTab } from '@air/constants/apiEndpoints';

export const ROOT_ROUTE = `/`;
export const ROUTE_DICTIONARIES = '/dictionaries';
export const ROUTE_CUSTOMER_COMPANIES = '/customer-companies';
export const makeCustomerCompanyRoute = (companyId?: string) =>
  `/customer-companies/${companyId}`;

export const ROUTE_SKILLS = `${ROUTE_DICTIONARIES}/skills`;
export const ROUTE_ROLES = `${ROUTE_DICTIONARIES}/roles`;
export const ROUTE_COMPANIES = `${ROUTE_DICTIONARIES}/companies`;
export const ROUTE_COMPANIES_SINGLE = getUrlWithParamsAndCurrentTab({
  url: `${ROUTE_DICTIONARIES}/companies`,
  tab: CompanyTabs.Companies,
});
export const ROUTE_CERTIFICATIONS = `${ROUTE_DICTIONARIES}/certifications`;
export const ROUTE_INSTITUTIONS = `${ROUTE_DICTIONARIES}/institutions`;
export const ROUTE_INSTITUTIONS_SINGLE = getUrlWithParamsAndCurrentTab({
  url: `${ROUTE_DICTIONARIES}/institutions`,
  tab: InstitutionsTabs.Institutions,
});
export const ROUTE_DEGREES = `${ROUTE_DICTIONARIES}/degrees`;
export const ROUTE_MAJORS = `${ROUTE_DICTIONARIES}/majors`;
export const ROUTE_MAJORS_SINGLE = getUrlWithParamsAndCurrentTab({
  url: `${ROUTE_DICTIONARIES}/majors`,
  tab: MajorsTabs.Majors,
});
export const ROUTE_INDUSTRIES = `${ROUTE_DICTIONARIES}/industries`;
