import { SkillUpsertRequest } from '@air/api';
import { ModifiedSkillResponse } from './criteria';
import {
  prepareAliasesForBackend,
  prepareJobSpecializationsForBackend,
} from 'domain/dictionaries/dictionaries';

export const prepareSkillsForBackend = (
  skills: ModifiedSkillResponse[]
): SkillUpsertRequest[] => {
  return skills.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      shortName: item.shortName?.trim(),
      aliases: prepareAliasesForBackend(item.aliases),
      specializations: prepareJobSpecializationsForBackend(
        item.specializations
      ),
      index: item.index || 0,
      language: item.language || false,
      deprecated: item.deprecated,
    };
  });
};
