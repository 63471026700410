import React, { useReducer, useMemo, useCallback } from 'react';
import { AdminProfileContext, AdminProfileContextT } from 'context';

import useAuthenticateAction from '@air/hooks/useAuthenticateAction';
import useLogoutAction from '@air/hooks/useLogoutAction';
import useAutologinAction, {
  getUserProfileAction,
} from '@air/hooks/useAutologinAction';
import authReducer, {
  AuthReducerAction,
  State,
} from '@air/utils/auth/authReducer';
import { LoggedInAdminInfoResponse } from '@air/api/models';

import * as apiEndpoints from 'constants/apiEndpoints';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

const initialState: State<LoggedInAdminInfoResponse> = {
  authDataReceived: false,
  authError: null,
  authenticated: false,
  user: null,
  timeOut: false,
  invalidToken: false,
};

type AppReducer<T> = (
  state: State<T>,
  action: AuthReducerAction<T>
) => State<T>;

export const AdminProfileProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<AppReducer<LoggedInAdminInfoResponse>>(
    authReducer,
    initialState
  );
  const userProfileUrl = apiEndpoints.ADMIN_API;
  const authenticate = useAuthenticateAction<LoggedInAdminInfoResponse>(
    userProfileUrl,
    dispatch
  );
  const logout = useLogoutAction<LoggedInAdminInfoResponse>(dispatch);
  const getUserProfile = useCallback(
    () => getUserProfileAction(userProfileUrl, dispatch),
    [userProfileUrl, dispatch]
  );

  useAutologinAction(userProfileUrl, dispatch);

  const { authDataReceived, timeOut } = state;
  const uiIsReady = authDataReceived && timeOut;

  const adminProfileContextValue = useMemo(() => {
    return {
      ...state,
      // @ts-ignore commented out due to the wrong user response
      isUserLoaded: !!state.user?.userId || !!state.user?.id, // TODO - should be userId, remove when BE is fixed
      uiIsReady,
      methods: {
        authenticate,
        logout,
        getUserProfile,
      },
    };
  }, [state, authenticate, logout, uiIsReady, getUserProfile]);

  return (
    <AdminProfileContext.Provider value={adminProfileContextValue}>
      {children}
    </AdminProfileContext.Provider>
  );
};

export const useAdminProfileContext = <Selected,>(
  selector: (state: AdminProfileContextT) => Selected
) => {
  return useContextSelector(AdminProfileContext, selector);
};

export const useAdminProfileMethods = () => {
  return useEqualContextSelector(
    AdminProfileContext,
    (state: AdminProfileContextT) => state.methods,
    R.shallowEqualObjects
  );
};

AdminProfileProvider.displayName = 'AdminProfileProvider';
