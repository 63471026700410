import React from 'react';
import { Column, Row } from 'react-table';

import { UpdateFieldParamsT } from 'domain/dictionaries/criteria';
import { SelectOptionT } from '@air/domain/Forms/types';

// imports from styles
import styles from './SelectCell.css';

type Props = {
  row: Row;
  column: Column;
  removeItem: (id: string) => void;
  updateData: ({ value, id, fieldName }: UpdateFieldParamsT) => void;
  disabled?: boolean;
  options?: SelectOptionT[];
};

export const SelectCell = React.memo((props: Props) => {
  const {
    row: { original },
    column,
    updateData,
    disabled,
    options = [],
  } = props;

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    updateData({ value, id: original.id, fieldName: column.id });
  };

  return (
    <div className={styles.selectCell}>
      <select
        disabled={disabled}
        onChange={onChange}
        value={original[column.id] || options[0]}
      >
        {options.map((item: SelectOptionT) => {
          return (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
});

SelectCell.displayName = 'SelectCell';
