import * as phrases from 'constants/phrases';
import { CRITERIA_COLUMNS } from 'constants/criteria';
import {
  DataSourceType,
  LicenseType,
  MatchMinerStatus,
  MatchScoutStatus,
  RoleName,
} from '@air/api';

export const customerCompaniesTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_NAME,
    accessor: CRITERIA_COLUMNS.NAME,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CUSTOMER_EMAIL,
    accessor: CRITERIA_COLUMNS.EMAIL,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FROM_EMAIL,
    accessor: CRITERIA_COLUMNS.CONTACT_EMAIL,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_LICENSE,
    accessor: CRITERIA_COLUMNS.LICENSE_TYPE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_TRIAL_EXPIRATION,
    accessor: CRITERIA_COLUMNS.LICENSE_EXPIRATION,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ATS,
    accessor: CRITERIA_COLUMNS.ATS_TYPE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_PIPL_ENABLED,
    accessor: CRITERIA_COLUMNS.PIPL_ENABLED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MM_STATUS,
    accessor: CRITERIA_COLUMNS.MM_STATUS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MS_STATUS,
    accessor: CRITERIA_COLUMNS.MS_STATUS,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_APPLICANT_EMAILS_ENABLED,
    accessor: CRITERIA_COLUMNS.APPLICANT_EMAILS_ENABLED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_APPLICANT_STATS_ENABLED,
    accessor: CRITERIA_COLUMNS.APPLICANT_STATS_ENABLED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ACCOUNT_MANAGER,
    accessor: CRITERIA_COLUMNS.ACCOUNT_MANAGER,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
];

export const LicenseTypeOptions = [
  { value: LicenseType.TRIALEXPIRED, label: phrases.TRIAL_EXPIRED },
  {
    value: LicenseType.TRIALEXPIREDFULLREQUESTED,
    label: phrases.TRIAL_EXPIRED_FULL_REQUESTED,
  },
  { value: LicenseType.TRIALACTIVE, label: phrases.TRIAL_ACTIVE },
  {
    value: LicenseType.TRIALACTIVEFULLREQUESTED,
    label: phrases.TRIAL_ACTIVE_FULL_REQUESTED,
  },
  { value: LicenseType.FULL, label: phrases.TRIAL_FULL },
];

export const EXTERNAL_ATS_LIST = [
  DataSourceType.ORACLETALEO,
  DataSourceType.GREENHOUSEATS,
  DataSourceType.LEVERATS,
];

export const enrichmentRolesOptions = [
  { value: RoleName.APPLICANT, label: RoleName.APPLICANT },
  { value: RoleName.CUSTOMERADMIN, label: RoleName.CUSTOMERADMIN },
  { value: RoleName.RECRUITER, label: RoleName.RECRUITER },
  { value: RoleName.SYSTEMADMIN, label: RoleName.SYSTEMADMIN },
  { value: RoleName.ADMINPORTAL, label: RoleName.ADMINPORTAL },
];

export const mmStatusOptions = [
  { value: MatchMinerStatus.DISABLED, label: MatchMinerStatus.DISABLED },
  {
    value: MatchMinerStatus.ACCESSREQUESTED,
    label: MatchMinerStatus.ACCESSREQUESTED,
  },
  {
    value: MatchMinerStatus.SETUPINPROGRESS,
    label: MatchMinerStatus.SETUPINPROGRESS,
  },
  {
    value: MatchMinerStatus.ERRORSETUPINPROGRESS,
    label: MatchMinerStatus.ERRORSETUPINPROGRESS,
    isDisabled: true,
  },
  { value: MatchMinerStatus.ENABLED, label: MatchMinerStatus.ENABLED },
];

export const matchScoutStatusOptions = [
  { value: MatchScoutStatus.DISABLED, label: MatchScoutStatus.DISABLED },
  {
    value: MatchScoutStatus.ACCESSREQUESTED,
    label: MatchScoutStatus.ACCESSREQUESTED,
  },
  { value: MatchScoutStatus.ENABLED, label: MatchScoutStatus.ENABLED },
];

export const LOGO_DROPZONE_CONFIG = {
  noKeyboard: true,
  noDragEventsBubbling: true,
  noClick: true,
  accept: '.jpeg,.jpg,.png,.svg,.webp',
  maxFiles: 1,
  maxSize: 16777216, // 16Mb - same as on BE
  multiple: false,
};
