import { CRITERIA_COLUMNS } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { CompanyTabs } from '@air/constants/tabs';

export const COMPANY_TABS_OPTIONS = [
  {
    value: CompanyTabs.Companies,
    label: phrases.COMPANY_TAB_COMPANIES,
  },
  {
    value: CompanyTabs.CompanyGroups,
    label: phrases.COMPANY_TAB_COMPANY_GROUPS,
  },
  {
    value: CompanyTabs.CompanySizes,
    label: phrases.COMPANY_TAB_COMPANY_SIZES,
  },
];

export const companySizesTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MIN_SIZE,
    accessor: CRITERIA_COLUMNS.MIN_SIZE,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_MAX_SIZE,
    accessor: CRITERIA_COLUMNS.MAX_SIZE,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
];

export const NEW_COMPANY_SIZES_ITEM = {
  __isNew__: true,
  fullName: '',
  shortName: '',
  minSize: 0,
  maxSize: 0,
  created: '',
  updated: '',
};

export const companiesTableColumns = [
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ID,
    accessor: CRITERIA_COLUMNS.ID,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_FULL_NAME,
    accessor: CRITERIA_COLUMNS.FULLNAME,
    required: true,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ALIASES,
    accessor: CRITERIA_COLUMNS.ALIASES,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_COUNTRY,
    accessor: CRITERIA_COLUMNS.COUNTRY,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_STATE,
    accessor: CRITERIA_COLUMNS.STATE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CITY,
    accessor: CRITERIA_COLUMNS.CITY,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_EMPLOYEES,
    accessor: CRITERIA_COLUMNS.EMPLOYEES,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_REVENUE,
    accessor: CRITERIA_COLUMNS.REVENUE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_INDUSTRY,
    accessor: CRITERIA_COLUMNS.INDUSTRY,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_COMPANY_TYPE,
    accessor: CRITERIA_COLUMNS.COMPANY_TYPE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_ENRICHED,
    accessor: CRITERIA_COLUMNS.ENRICHED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_SOURCE,
    accessor: CRITERIA_COLUMNS.SOURCE,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_CREATED,
    accessor: CRITERIA_COLUMNS.CREATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_UPDATED,
    accessor: CRITERIA_COLUMNS.UPDATED,
  },
  {
    Header: phrases.CRITERIA_COLUMN_TITLE_DEPRECATED,
    accessor: CRITERIA_COLUMNS.DEPRECATED,
    required: true,
  },
];
