import React, { useMemo, useState, useCallback } from 'react';
import { toast } from '@air/third-party/toast';
import { ExtraResponseParamsT } from '@air/domain/Common/apiTypes';

import { MajorsContext, DictionaryContextT } from 'context';
import {
  ModifiedCriteriaListResponse,
  FetchCriteriaParamsT,
  DmsDictionaryResponse,
  prepareDictionaryObjectForUI,
} from 'domain/dictionaries/criteria';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { DmsEducationMajor } from '@air/api';
import { downloadFile } from 'domain/dictionaries/dictionaries';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

const DEFAULT_FILE_NAME = 'majors_dictionary';

export const MajorsProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] =
    useState<ModifiedCriteriaListResponse>(DEFAULT_DICTIONARY_ITEM);

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      const { sortField, sortOrder } = params;
      setDictionaryItems((state) => ({
        ...state,
        sortField: sortField || state?.sortField,
        sortOrder: sortOrder || state?.sortOrder,
        isLoading: true,
      }));
      DictionariesApi.fetchDictionary<DmsDictionaryResponse<DmsEducationMajor>>(
        params,
        sharedUrls.DICTIONARY_API_MAJORS
      ).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: DmsDictionaryResponse<DmsEducationMajor>) => {
          setDictionaryItems((state) => {
            return prepareDictionaryObjectForUI<
              DmsDictionaryResponse<DmsEducationMajor>
            >(state, response, params);
          });
        }
      );
    },
    []
  );

  const exportDictionaryItems = useCallback(() => {
    const exportUrl = sharedUrls.makeDictionaryExportUrl(
      sharedUrls.CATEGORY_MAJOR
    );
    DictionariesApi.exportDictionary(exportUrl).fork(
      () => toast.error(phrases.DICTIONARY_EXPORT_ERROR),
      (response: Blob, extraParams: ExtraResponseParamsT) => {
        return downloadFile(response, extraParams?.headers, DEFAULT_FILE_NAME);
      }
    );
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      items: dictionaryItems.items as DmsEducationMajor[],
      updatedItems: dictionaryItems.updatedItems as DmsEducationMajor[],
      methods: {
        fetchDictionaryItems,
        exportDictionaryItems,
      },
    };
  }, [dictionaryItems, fetchDictionaryItems, exportDictionaryItems]);

  return (
    <MajorsContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </MajorsContext.Provider>
  );
};

export const useMajorsContext = <Selected,>(
  selector: (state: DictionaryContextT<DmsEducationMajor>) => Selected
) => {
  return useContextSelector(MajorsContext, selector);
};

export const useMajorsMethods = () => {
  return useEqualContextSelector(
    MajorsContext,
    (state: DictionaryContextT<DmsEducationMajor>) => state.methods,
    R.shallowEqualObjects
  );
};

MajorsProvider.displayName = 'MajorsProvider';
