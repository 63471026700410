import R from '@air/third-party/ramda';
import { GetCellComponentParamsT } from 'domain/dictionaries/dictionaries';
import { CRITERIA_COLUMNS, PRIORITY_OPTIONS } from 'constants/criteria';
import {
  ACCREDITATION_OPTIONS,
  INSTITUTION_TYPE_OPTIONS,
} from 'constants/instututions';
import { MAX_NUMBER_OF_EMPLOYEES } from '@air/domain/Common/CompanySizeConverter';

const inputFields = [
  CRITERIA_COLUMNS.FULLNAME,
  CRITERIA_COLUMNS.SHORTNAME,
  CRITERIA_COLUMNS.COUNTRY,
  CRITERIA_COLUMNS.STATE,
  CRITERIA_COLUMNS.CITY,
];
const textareaFields = [CRITERIA_COLUMNS.ALIASES, CRITERIA_COLUMNS.COMPANY];
const checkboxFields = [
  CRITERIA_COLUMNS.DEPRECATED,
  CRITERIA_COLUMNS.LANGUAGE,
  CRITERIA_COLUMNS.MANAGERIAL,
  CRITERIA_COLUMNS.ENRICHED,
  CRITERIA_COLUMNS.PIPL_ENABLED,
  CRITERIA_COLUMNS.APPLICANT_EMAILS_ENABLED,
  CRITERIA_COLUMNS.MM_STATUS,
  CRITERIA_COLUMNS.MS_STATUS,
  CRITERIA_COLUMNS.APPLICANT_STATS_ENABLED,
];
const selectPriorityFields = [
  CRITERIA_COLUMNS.INDEX,
  CRITERIA_COLUMNS.PRIORITY,
];
const amountFields = [CRITERIA_COLUMNS.ITEMS];
const numberFields = [CRITERIA_COLUMNS.MIN_SIZE, CRITERIA_COLUMNS.MAX_SIZE];
export const HIDDEN_CONTENT_COLUMNS = [CRITERIA_COLUMNS.ID];

export const getCellComponentForCriteria = ({
  cell,
  row,
  removeItem,
  loadOptions,
  specializations,
  onCellClick,
  disabled,
}: GetCellComponentParamsT) => {
  const fieldName = cell.column.id.toString();

  if (row.original.__isNew__ && HIDDEN_CONTENT_COLUMNS.includes(fieldName)) {
    return '';
  } else if (amountFields.includes(fieldName)) {
    return cell.render('AmountCell');
  } else if (fieldName === CRITERIA_COLUMNS.NAME && onCellClick) {
    return cell.render('TextCell', { onCellClick });
  } else if (fieldName === CRITERIA_COLUMNS.SPECIALIZATIONS) {
    return cell.render('MultiselectCell', {
      disabled,
      loadOptions: () => loadSpecializations(specializations),
      options: specializations,
      defaultOptions: true,
      value: specializations.filter(
        (it) =>
          row.original.specializations?.includes(it.value) ||
          row.original.specializations?.find(
            (item: { value: string }) => item.value === it.value
          )
      ),
    });
  } else if (
    (disabled &&
      !checkboxFields.includes(fieldName) &&
      fieldName !== CRITERIA_COLUMNS.SIMILAR_GROUPS) ||
    (!row.original.__isNew__ && fieldName === CRITERIA_COLUMNS.FULLNAME)
  ) {
    if (fieldName === CRITERIA_COLUMNS.ATS_TYPE) {
      return cell.render('TextCell', { defaultValue: 'Undefined' });
    }
    return cell.render('TextCell');
  } else if (checkboxFields.includes(fieldName)) {
    return cell.render('CheckboxCell', { disabled, removeItem });
  } else if (inputFields.includes(fieldName)) {
    return cell.render('InputCell', { disabled });
  } else if (numberFields.includes(fieldName)) {
    return cell.render('InputCell', {
      disabled,
      type: 'number',
      max: MAX_NUMBER_OF_EMPLOYEES,
    });
  } else if (selectPriorityFields.includes(fieldName)) {
    return cell.render('SelectCell', { disabled, options: PRIORITY_OPTIONS });
  } else if (fieldName === CRITERIA_COLUMNS.ACCREDITATION) {
    return cell.render('SelectCell', {
      disabled,
      options: ACCREDITATION_OPTIONS,
    });
  } else if (fieldName === CRITERIA_COLUMNS.INSTITUTION_TYPE) {
    return cell.render('SelectCell', {
      disabled,
      options: INSTITUTION_TYPE_OPTIONS,
    });
  } else if (fieldName === CRITERIA_COLUMNS.SIMILAR_GROUPS) {
    return cell.render('MultiselectCell', {
      disabled,
      loadOptions,
      value: row.original.similarGroups,
    });
  } else if (textareaFields.includes(fieldName)) {
    return cell.render('TextareaCell', { disabled });
  }
  return cell.render('TextCell');
};

export const loadSpecializations = (
  specializations: { label: string; value: string }[]
) => {
  // we don't use `UNKNOWN` specialization in admin portal
  const filteredSpecializations = R.filter(
    (it) => it.value !== 'UNKNOWN',
    specializations
  );
  return Promise.resolve(filteredSpecializations);
};
