import * as React from 'react';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import classNames from 'classnames';

import {
  CertificationsProvider,
  RolesProvider,
  DegreesProvider,
  SkillsProvider,
  InstitutionsListsProvider,
  InstitutionsProvider,
  CompaniesProvider,
  CompanySizesProvider,
  MajorGroupsProvider,
  MajorsProvider,
  IndustriesProvider,
  RoleCategoriesProvider,
  CustomerCompaniesProvider,
} from 'providers';

import { DictionaryView, LeftPanel } from 'components';
import {
  CompaniesView,
  InstitutionsView,
  MajorsView,
  CustomerCompaniesView,
  RolesView,
} from 'features';
import * as urls from 'constants/urls';
import * as phrases from 'constants/phrases';
import { NEW_SKILL_ITEM, skillsTableColumns } from 'constants/skills';
import { degreesTableColumns } from 'constants/degrees';
import {
  certificationsTableColumns,
  NEW_CERTIFICATION_ITEM,
} from 'constants/certifications';
import { industriesTableColumns } from 'constants/industries';

import styles from 'features/LandingView/LandingView.css';
import {
  useCertificationsContext,
  useCertificationsMethods,
} from 'providers/CertificationsProvider';
import {
  useDegreesContext,
  useDegreesMethods,
} from 'providers/DegreesProvider';
import {
  useIndustriesContext,
  useIndustriesMethods,
} from 'providers/IndustriesProvider';
import { useSkillsContext, useSkillsMethods } from 'providers/SkillsProvider';
import {
  useSSESubscription,
  registerSSESubscriber,
  MessageConsumer,
  aggregateTimeout,
} from '@air/lib/server-notifications';
import { useRef, useEffect } from 'react';
import { NotificationEvent, EventType } from '@air/api';
import { SSEConnectionErrorEvent } from '@air/lib/server-notifications/Connection';
import { ADMIN_NOTIFICATION_URL } from '@air/constants/apiEndpoints';
import { toast } from '@air/third-party/toast';

const ERROR_EVENTS = [
  EventType.FLECUSTOMERCOMPANYSETTINGSUPDATEFAILED,
  EventType.DSSCUSTOMERCOMPANYSETTINGSUPDATEFAILED,
] as const;

type ErrorEvent = { eventType: typeof ERROR_EVENTS[number] };

function isErrorEvent(
  event: NotificationEvent | SSEConnectionErrorEvent
): event is ErrorEvent {
  return ERROR_EVENTS.includes(event.eventType as ErrorEvent['eventType']);
}

export const Routes = (props: { showLeftPanel: boolean }) => {
  const { showLeftPanel } = props;

  const sseConsumers = useRef({
    errors: new MessageConsumer(aggregateTimeout(1000)),
  });
  const subscriptions = useRef([]);

  useSSESubscription(ADMIN_NOTIFICATION_URL, true);

  useEffect(() => {
    subscriptions.current.push(
      sseConsumers.current.errors.subscribe((events: NotificationEvent[]) => {
        events.forEach((event) => {
          toast.error(event.payload.errorMsg);
        });
      })
    );
    registerSSESubscriber(
      (event: NotificationEvent | SSEConnectionErrorEvent) => {
        if (isErrorEvent(event)) {
          return sseConsumers.current.errors.onEventReceived(event);
        }
      }
    );
  }, []);

  return (
    <div className={styles.main}>
      <Switch>
        <Route path={urls.ROUTE_DICTIONARIES}>
          {showLeftPanel && <LeftPanel />}
          <div
            className={classNames(styles.view, {
              [styles.fullWidth]: !showLeftPanel,
            })}
          >
            <Switch>
              <Route
                path={urls.ROUTE_SKILLS}
                render={(props) => (
                  <SkillsProvider>
                    <DictionaryView
                      tableTitle={phrases.NAVIGATION_LINK_SKILLS}
                      tableColumns={skillsTableColumns}
                      dictionaryContext={useSkillsContext}
                      dictionaryContextMethods={useSkillsMethods}
                      defaultEmptyItem={NEW_SKILL_ITEM}
                      {...props}
                    />
                  </SkillsProvider>
                )}
              />

              <Route path={urls.ROUTE_ROLES}>
                {(props: RouteChildrenProps) => (
                  <RolesProvider>
                    <RoleCategoriesProvider>
                      <RolesView {...props} />
                    </RoleCategoriesProvider>
                  </RolesProvider>
                )}
              </Route>

              <Route
                path={urls.ROUTE_COMPANIES}
                render={(props: RouteChildrenProps) => (
                  <CompanySizesProvider>
                    <CompaniesProvider>
                      <CompaniesView {...props} />
                    </CompaniesProvider>
                  </CompanySizesProvider>
                )}
              />
              <Route path={urls.ROUTE_CERTIFICATIONS}>
                {(props: RouteChildrenProps) => (
                  <CertificationsProvider>
                    <DictionaryView
                      tableTitle={phrases.NAVIGATION_LINK_CERTIFICATIONS}
                      tableColumns={certificationsTableColumns}
                      dictionaryContext={useCertificationsContext}
                      dictionaryContextMethods={useCertificationsMethods}
                      defaultEmptyItem={NEW_CERTIFICATION_ITEM}
                      {...props}
                    />
                  </CertificationsProvider>
                )}
              </Route>

              <Route path={urls.ROUTE_INSTITUTIONS}>
                {(props: RouteChildrenProps) => (
                  <InstitutionsListsProvider>
                    <InstitutionsProvider>
                      <InstitutionsView {...props} />
                    </InstitutionsProvider>
                  </InstitutionsListsProvider>
                )}
              </Route>

              <Route path={urls.ROUTE_DEGREES}>
                {(props: RouteChildrenProps) => (
                  <DegreesProvider>
                    <DictionaryView
                      tableTitle={phrases.NAVIGATION_LINK_DEGREES}
                      tableColumns={degreesTableColumns}
                      dictionaryContext={useDegreesContext}
                      dictionaryContextMethods={useDegreesMethods}
                      hideDeprecatedControl
                      isReadOnly
                      {...props}
                    />
                  </DegreesProvider>
                )}
              </Route>

              <Route path={urls.ROUTE_MAJORS}>
                {(props: RouteChildrenProps) => (
                  <MajorGroupsProvider>
                    <MajorsProvider>
                      <MajorsView {...props} />
                    </MajorsProvider>
                  </MajorGroupsProvider>
                )}
              </Route>

              <Route path={urls.ROUTE_INDUSTRIES}>
                {(props: RouteChildrenProps) => (
                  <IndustriesProvider>
                    <DictionaryView
                      tableTitle={phrases.NAVIGATION_LINK_INDUSTRIES}
                      tableColumns={industriesTableColumns}
                      dictionaryContext={useIndustriesContext}
                      dictionaryContextMethods={useIndustriesMethods}
                      hideDeprecatedControl
                      {...props}
                    />
                  </IndustriesProvider>
                )}
              </Route>

              <Redirect to={urls.ROUTE_SKILLS} />
            </Switch>
          </div>
        </Route>
        <Route exact path={urls.makeCustomerCompanyRoute(':companyId?')}>
          {(
            props: RouteComponentProps<{
              companyId?: string;
            }>
          ) => {
            return (
              <div className={classNames(styles.view, styles.fullWidth)}>
                <CustomerCompaniesProvider>
                  <CustomerCompaniesView {...props} />
                </CustomerCompaniesProvider>
              </div>
            );
          }}
        </Route>
      </Switch>
    </div>
  );
};
