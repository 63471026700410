import React, { useEffect, useRef } from 'react';
import { Column, Row } from 'react-table';

import { UpdateFieldParamsT } from 'domain/dictionaries/criteria';

// imports from styles
import styles from './TextareaCell.css';

type Props = {
  row: Row;
  column: Column;
  removeItem: (id: string) => void;
  updateData: ({ value, id, fieldName }: UpdateFieldParamsT) => void;
  disabled?: boolean;
};

export const TextareaCell = React.memo((props: Props) => {
  const {
    row: { original },
    column,
    updateData,
    disabled,
  } = props;

  const originalItemValue = original[column.id] || '';
  const inputRef = useRef(originalItemValue);

  useEffect(() => {
    inputRef.current.value = originalItemValue;
  }, [originalItemValue]);

  const onBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value !== originalItemValue) {
      updateData({ value, id: original.id, fieldName: column.id });
    }
  };

  // by checking for original.__isUpdated__  we are updating storage on first touch event
  // and later updating only on onBlur event
  // if we update on onChange event every time it's quite slow
  return (
    <div className={styles.textareaCell}>
      <textarea
        ref={inputRef}
        disabled={disabled}
        onChange={original.__isUpdated__ ? null : onBlur}
        onBlur={onBlur}
      />
    </div>
  );
});

TextareaCell.displayName = 'TextareaCell';
