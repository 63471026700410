import React from 'react';
import { AdminProfileProvider } from 'providers';
import { Toast } from '@air/components';
import { LandingView } from 'features';
import { CacheProvider } from '@air/providers/CacheProvider';

function App() {
  return (
    <AdminProfileProvider>
      <CacheProvider>
        <Toast />
        <LandingView />
      </CacheProvider>
    </AdminProfileProvider>
  );
}

export default App;
