import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  Button,
  ConfirmationModal,
  FormDropdown,
  Paragraph,
  UIText,
} from '@air/components';
import * as phrases from 'constants/phrases';
import { CustomerCompanyExtendedT } from 'domain/customerCompanies';
import { mmStatusOptions } from 'constants/customerCompanies';
import { toast } from '@air/third-party/toast';
import { CustomerCompaniesContextT } from 'context';

import sharedStyles from 'features/CustomerCompaniesView/CustomerCompanуForm/CustomerCompanyForm.css';

type Props = {
  isDisabled: boolean;
  selectedCompany: CustomerCompanyExtendedT;
  setLoading: (param: boolean) => void;
  dropIndexationResults: CustomerCompaniesContextT['methods']['dropIndexationResults'];
  getSelectedCompany: () => void;
};

export const MMSettingsSection: React.FC<Props> = ({
  isDisabled,
  selectedCompany,
  setLoading,
  dropIndexationResults,
  getSelectedCompany,
}) => {
  const [isDroppingIndexation, setIsDroppingIndexation] = useState(false);
  const [showDropIndexationConfirmation, setDropIndexationConfirmation] =
    useState(false);

  const { control } = useFormContext();

  const onConfirm = async () => {
    setIsDroppingIndexation(true);
    setLoading(true);
    try {
      await dropIndexationResults(selectedCompany.id);
      getSelectedCompany();
      toast.success(phrases.CUSTOMER_COMPANY_INDEXATION_SUCCESS);
    } catch (e) {
      toast.error(phrases.CUSTOMER_COMPANY_INDEXATION_ERROR);
    }
    setDropIndexationConfirmation(false);
    setIsDroppingIndexation(false);
    setLoading(false);
  };

  return (
    <div className={sharedStyles.formGroup}>
      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_GROUP_MATCH_MINER}
      </UIText>
      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="mmStatus"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormDropdown
              hasWarning={false}
              isDisabled={isDisabled}
              id="mmStatus"
              options={mmStatusOptions}
              label={phrases.CUSTOMER_COMPANY_LABEL_MM_STATUS}
              {...field}
            />
          )}
        />
        <Paragraph className={sharedStyles.formGroupExplanation}>
          <span>
            {phrases.CUSTOMER_COMPANY_MM_REQUESTED}:{' '}
            {selectedCompany.mmRequested}
          </span>
          <br />
          <span>
            {phrases.CUSTOMER_COMPANY_INDEXED_CANDIDATES}:{' '}
            {selectedCompany.mmIndexedCandidatesCount}
          </span>
          {selectedCompany.mmIndexedCandidatesCount > 0 && (
            <Button
              variant={Button.variants.INLINE}
              className={sharedStyles.clearIndexationButton}
              onClick={() => setDropIndexationConfirmation(true)}
              disabled={isDroppingIndexation}
            >
              {phrases.CUSTOMER_COMPANY_ACTION_CLEAR_INDEXED}
            </Button>
          )}
        </Paragraph>
      </div>
      <ConfirmationModal
        onConfirm={onConfirm}
        onCancel={() => setDropIndexationConfirmation(false)}
        confirmLabel={phrases.CUSTOMER_COMPANY_DROP_INDEXED_ACTION}
        isOpen={showDropIndexationConfirmation}
        isLoading={isDroppingIndexation}
      >
        <Paragraph className={sharedStyles.confirmation}>
          {phrases.getCustomerCompanyIndexationConfirmation(
            selectedCompany.name
          )}
        </Paragraph>
      </ConfirmationModal>
    </div>
  );
};
