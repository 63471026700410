import React, { useMemo, useState, useCallback } from 'react';
import { toast } from '@air/third-party/toast';

import { MajorGroupsContext, DictionaryContextT } from 'context';
import {
  ModifiedCriteriaListResponse,
  FetchCriteriaParamsT,
  prepareDictionaryObjectForUI,
  DmsDictionaryResponse,
  ModifiedEducationMajorGroup,
} from 'domain/dictionaries/criteria';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { EducationMajorGroup } from '@air/api';
import { downloadFile } from 'domain/dictionaries/dictionaries';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { ExtraResponseParamsT } from '@air/domain/Common/apiTypes';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

const DEFAULT_FILE_NAME = 'major_groups_dictionary';

export const MajorGroupsProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] =
    useState<ModifiedCriteriaListResponse>(DEFAULT_DICTIONARY_ITEM);

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      const { sortField, sortOrder } = params;
      setDictionaryItems((state) => ({
        ...state,
        sortField: sortField || state?.sortField,
        sortOrder: sortOrder || state?.sortOrder,
        isLoading: true,
      }));

      DictionariesApi.fetchDictionary<
        DmsDictionaryResponse<EducationMajorGroup>
      >(params, sharedUrls.DICTIONARY_API_MAJOR_GROUPS).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: DmsDictionaryResponse<EducationMajorGroup>) => {
          setDictionaryItems((state) => {
            return prepareDictionaryObjectForUI(state, response, params);
          });
        }
      );
    },
    []
  );

  const exportDictionaryItems = useCallback(() => {
    const exportUrl = sharedUrls.makeDictionaryExportUrl(
      sharedUrls.CATEGORY_MAJOR_GROUP
    );
    DictionariesApi.exportDictionary(exportUrl).fork(
      () => toast.error(phrases.DICTIONARY_EXPORT_ERROR),
      (response: Blob, extraParams: ExtraResponseParamsT) => {
        return downloadFile(response, extraParams?.headers, DEFAULT_FILE_NAME);
      }
    );
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      items: dictionaryItems.items as EducationMajorGroup[],
      updatedItems: dictionaryItems.updatedItems as EducationMajorGroup[],
      methods: {
        fetchDictionaryItems,
        exportDictionaryItems,
      },
    };
  }, [dictionaryItems, fetchDictionaryItems, exportDictionaryItems]);

  return (
    <MajorGroupsContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </MajorGroupsContext.Provider>
  );
};

export const useMajorGroupsContext = <Selected,>(
  selector: (state: DictionaryContextT<ModifiedEducationMajorGroup>) => Selected
) => {
  return useContextSelector(MajorGroupsContext, selector);
};

export const useMajorGroupsMethods = () => {
  return useEqualContextSelector(
    MajorGroupsContext,
    (state: DictionaryContextT<ModifiedEducationMajorGroup>) => state.methods,
    R.shallowEqualObjects
  );
};

MajorGroupsProvider.displayName = 'MajorGroupsProvider';
