import React from 'react';
import { Column, Row } from 'react-table';

type Props = {
  row: Row;
  column: Column;
};

export const AmountCell = React.memo((props: Props) => {
  const {
    row: { original },
    column,
  } = props;

  return <div>{original[column.id].length}</div>;
});

AmountCell.displayName = 'AmountCell';
