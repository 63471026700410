import {
  FetchCriteriaParamsT,
  ModifiedCertificationResponse,
  ModifiedCompanyResponse,
  ModifiedCompanySizeResponse,
  ModifiedEducationMajorGroup,
  ModifiedIndustryResponse,
  ModifiedInstitutionResponse,
  ModifiedRoleCategoryResponse,
  ModifiedRoleResponse,
  ModifiedSkillResponse,
  UpdateFieldParamsT,
} from 'domain/dictionaries/criteria';
import { SortOrderT } from 'constants/api';
import { DEFAULT_SORT_FIELD } from 'constants/criteria';
import {
  DmsEducationDegree,
  DmsEducationMajor,
  CustomerCompanyResponseV3,
  CustomerCompanyCreateRequestV3,
  CustomerCompanyUpdateRequestV3,
  CustomerCompanyAccountManagerEventPayload,
} from '@air/api';
import {
  BillingReportPayload,
  UploadCompanyLogoParamsT,
} from 'domain/customerCompaniesApi';
import { CustomerCompanyT } from 'domain/customerCompanies';
import { createContext } from 'use-context-selector';
import { CheckboxOptionT } from '@air/domain/Forms/types';

export type DictionaryContextT<I> = {
  isLoading?: boolean;
  items: Array<I>;
  updatedItems: Array<I> | null;
  total?: number;
  sortField?: string;
  sortOrder?: SortOrderT;
  methods?: {
    fetchDictionaryItems?: (
      params: FetchCriteriaParamsT,
      isLoadingMore?: boolean
    ) => Promise<void>;
    updateDictionaryItem?: ({
      id,
      value,
      fieldName,
      item,
    }: UpdateFieldParamsT) => void;
    discardDictionaryItemsChanges?: () => void;
    publishDictionaryItemsChanges?: () => void;
    removeAddedDictionaryItem?: (id: string) => void;
    exportDictionaryItems?: () => void;
  };
  companyManagers?: CheckboxOptionT[];
};

type DefaultDictionaryContextValueT = {
  isLoading: boolean;
  items: [];
  updatedItems: [];
  total: number;
  sortField: string;
  sortOrder: SortOrderT;
};

const defaultDictionaryContextValue: DefaultDictionaryContextValueT = {
  isLoading: null,
  items: [],
  updatedItems: [],
  total: 0,
  sortField: DEFAULT_SORT_FIELD,
  sortOrder: SortOrderT.desc,
};

export const SkillsContext = createContext<
  DictionaryContextT<ModifiedSkillResponse>
>(defaultDictionaryContextValue);

export const CertificationsContext = createContext<
  DictionaryContextT<ModifiedCertificationResponse>
>(defaultDictionaryContextValue);

export const DegreesContext = createContext<
  DictionaryContextT<DmsEducationDegree>
>(defaultDictionaryContextValue);

export const CompaniesContext = createContext<
  DictionaryContextT<ModifiedCompanyResponse>
>(defaultDictionaryContextValue);

export const CompanySizesContext = createContext<
  DictionaryContextT<ModifiedCompanySizeResponse>
>(defaultDictionaryContextValue);

export const InstitutionsContext = createContext<
  DictionaryContextT<ModifiedInstitutionResponse>
>(defaultDictionaryContextValue);

export const InstitutionsListsContext = createContext<
  DictionaryContextT<ModifiedInstitutionResponse> // TODO List
>(defaultDictionaryContextValue);

export const MajorGroupsContext = createContext<
  DictionaryContextT<ModifiedEducationMajorGroup>
>(defaultDictionaryContextValue);

export const MajorsContext = createContext<
  DictionaryContextT<DmsEducationMajor>
>(defaultDictionaryContextValue);

export const RoleCategoriesContext = createContext<
  DictionaryContextT<ModifiedRoleCategoryResponse>
>(defaultDictionaryContextValue);

export const RolesContext = createContext<
  DictionaryContextT<ModifiedRoleResponse>
>(defaultDictionaryContextValue);

export const IndustriesContext = createContext<
  DictionaryContextT<ModifiedIndustryResponse>
>(defaultDictionaryContextValue);

export type UpdateItemParamsT = {
  newItem?: CustomerCompanyUpdateRequestV3;
  newLocalItem?: CustomerCompanyAccountManagerEventPayload;
  companyId?: string;
};

export type CustomerCompaniesContextT = DictionaryContextT<CustomerCompanyT> & {
  companyManagers: CheckboxOptionT[];
  methods?: {
    uploadLogoForCustomerCompany?: (params: UploadCompanyLogoParamsT) => void;
    removeLogoForCustomerCompany?: (companyId: string) => void;
    createNewItem?: (
      newItem: CustomerCompanyCreateRequestV3
    ) => Promise<CustomerCompanyResponseV3>;
    updateItem?: (
      props: UpdateItemParamsT
    ) => Promise<CustomerCompanyResponseV3>;
    getItemById?: (companyId: string) => Promise<CustomerCompanyResponseV3>;
    removeItem?: (companyId: string) => void;
    dropIndexationResults?: (companyId: string) => void;
    getBillingReport?: (payload: BillingReportPayload) => void;
    fetchCompanyManagers?: () => Promise<CheckboxOptionT[]>;
  };
};

const customerCompaniesContextDefaultValue = {
  ...defaultDictionaryContextValue,
  companyManagers: null as CheckboxOptionT[],
};

export const CustomerCompaniesContext =
  createContext<CustomerCompaniesContextT>(
    customerCompaniesContextDefaultValue
  );
