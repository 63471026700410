export const findItemById = (items: any[], id: string | number) => {
  return items?.find((item) => String(item.id) === String(id));
};

export const transformArrayIntoObjectByFieldName = (
  list: { [key: string]: any }[],
  fieldName: string
) => {
  return (list || []).reduce((memo, item) => {
    // values can be null from BE but on FE objects can't have nulls as keys
    return { ...memo, [item[fieldName] || '']: item };
  }, {});
};

export function nullifyEmptyStrings<I extends { [key: string]: any }>(item: I) {
  return Object.keys(item).reduce((memo: any, key: string) => {
    const initialValue = item[key];
    // replace empty strings with nulls for BE
    // otherwise BE validation will not work
    const computedValue = initialValue === '' ? null : initialValue;

    return { ...memo, [key]: computedValue };
  }, {});
}
