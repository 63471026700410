import { ModifiedIndustryResponse } from './criteria';
import { Industry } from '@air/api';

export const prepareIndustriesForBackend = (
  items: ModifiedIndustryResponse[]
): Industry[] => {
  return items.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
    };
  });
};
