import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteProps } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { TypeaheadSearchField } from '@air/components';
import { useDebounce } from '@air/utils/hooks';
import { REQUEST_PAGING_SIZE } from 'constants/api';
import { INPUT_DEBOUNCE_TIME } from '@air/constants/app';
import { FetchCriteriaParamsT } from 'domain/dictionaries/criteria';
import { TypeaheadSearchFieldVariants } from '@air/components/TypeaheadSearchField/TypeaheadSearchField';

const DEFAULT_CLEARING_TIMEOUT = 400;

// imports from styles
import styles from './TypeaheadSearchFieldWrapper.css';

type Props = {
  setSearchField: Dispatch<SetStateAction<string>>;
  searchFieldName: 'keyword';
  onUpdateSearchField?: (params?: FetchCriteriaParamsT) => void;
  clearingTimeout?: number;
  className?: string;
  variant?: TypeaheadSearchFieldVariants;
} & RouteProps;

export const TypeaheadSearchFieldWrapper: React.FC<Props> = ({
  setSearchField,
  searchFieldName,
  onUpdateSearchField,
  variant,
  clearingTimeout = DEFAULT_CLEARING_TIMEOUT,
  className,
}) => {
  const [hoverOverRejectIcon, setHoverOverRejectIcon] = useState(false);
  const [isClearingInput, triggerClearingAnimation] = useState(false);
  const ref = useRef<HTMLInputElement>();
  const methods = useForm<{
    keyword: string;
  }>({
    defaultValues: {
      keyword: '',
    },
    shouldUnregister: true,
  });
  const { watch, setValue, handleSubmit } = methods;

  const currentSearchNameField = watch(searchFieldName);
  const setFocus = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const removeInput = useCallback(() => {
    triggerClearingAnimation(true);
    setHoverOverRejectIcon(false);
    onUpdateSearchField();
    setSearchField('');

    setTimeout(() => {
      triggerClearingAnimation(false);
      setSearchField('');
      setValue(searchFieldName, '');
      setFocus();
    }, clearingTimeout);
  }, [
    setFocus,
    setValue,
    onUpdateSearchField,
    searchFieldName,
    setSearchField,
    clearingTimeout,
  ]);

  const onUpdateCallback = useCallback(
    ({ keyword }) => {
      if (keyword === undefined) return;
      const value = keyword.trim();
      setSearchField(value);
      onUpdateSearchField({
        keyword: value,
        size: REQUEST_PAGING_SIZE,
      });
    },
    [onUpdateSearchField, setSearchField]
  );

  const onSubmit = useDebounce(onUpdateCallback, INPUT_DEBOUNCE_TIME);

  const submitForm = useMemo(
    () => handleSubmit(onSubmit),
    [handleSubmit, onSubmit]
  );

  return (
    <FormProvider {...methods}>
      <form
        className={classNames(styles.typeaheadSearchFieldWrapper, className)}
        onSubmit={submitForm}
      >
        <TypeaheadSearchField
          variant={variant}
          currentSearchNameValue={currentSearchNameField}
          isClearingInput={isClearingInput}
          nameFilterField={searchFieldName}
          hoverOverRejectIcon={hoverOverRejectIcon}
          setHoverOverRejectIcon={setHoverOverRejectIcon}
          removeInput={removeInput}
          onSubmit={submitForm}
          handleChange={submitForm}
          ref={ref}
        />
      </form>
    </FormProvider>
  );
};

TypeaheadSearchFieldWrapper.displayName = 'TypeaheadSearchFieldWrapper';
