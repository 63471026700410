import { DictionaryValidationErrorType } from '@air/api';
import { SortOrderT } from 'constants/api';
import * as phrases from 'constants/phrases';
import { CustomerCompaniesT } from 'domain/dictionaries/criteria';

export const CRITERIA_COLUMNS = {
  ID: 'id',
  FULLNAME: 'fullName',
  SHORTNAME: 'shortName',
  ALIASES: 'aliases',
  SPECIALIZATIONS: 'specializations',
  INDEX: 'index',
  PRIORITY: 'priority',
  CREATED: 'created',
  UPDATED: 'updated',
  DEPRECATED: 'deprecated',
  LANGUAGE: 'language',
  COMPANY: 'company',
  SIMILAR_GROUPS: 'similarGroups',
  MANAGERIAL: 'managerial',
  ACCREDITATION: 'accreditation',
  INSTITUTION_TYPE: 'type',
  COUNTRY: 'country',
  STATE: 'state',
  CITY: 'city',
  LIST: 'list',
  ITEMS: 'items',
  LEVEL: 'level',
  MIN_SIZE: 'minSize',
  MAX_SIZE: 'maxSize',
  EMPLOYEES: 'employees',
  REVENUE: 'revenue',
  INDUSTRY: 'industry',
  COMPANY_TYPE: 'companyType',
  ENRICHED: 'enriched',
  SOURCE: 'source',
  // organizations/customer companies
  NAME: 'name',
  EMAIL: 'email',
  CONTACT_EMAIL: 'contactEmail',
  ACCOUNT_MANAGER: 'accountManager',
  PIPL_ENABLED: 'piplEnabled',
  LICENSE_TYPE: 'licenseType',
  LICENSE_EXPIRATION: 'trialExpiration',
  ATS_TYPE: 'atsType',
  MM_STATUS: 'mmStatus',
  MS_STATUS: 'msStatus',
  APPLICANT_EMAILS_ENABLED: 'applicantEmailNotificationsEnabled',
  APPLICANT_STATS_ENABLED: 'applicantStatsEnabled',
};

export const SORTABLE_DICTIONARIES_COLUMNS = [
  CRITERIA_COLUMNS.ID,
  CRITERIA_COLUMNS.FULLNAME,
  CRITERIA_COLUMNS.NAME,
  CRITERIA_COLUMNS.UPDATED,
];

export enum SortFieldsT {
  id = 'id',
  fullName = 'fullName',
  updated = 'updated',
}

export const PRIORITY_OPTIONS = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];

export const CRITERIA_ERRORS_MAPPING = {
  [DictionaryValidationErrorType.FULLNAMEABSENT]:
    phrases.CRITERIA_ERROR_FULLNAMEABSENT,
  [DictionaryValidationErrorType.FULLNAMEDUPLICATE]:
    phrases.CRITERIA_ERROR_FULLNAMEDUPLICATE,
  [DictionaryValidationErrorType.SHORTNAMEDUPLICATE]:
    phrases.CRITERIA_ERROR_SHORTNAMEDUPLICATE,
  [DictionaryValidationErrorType.ALIASDUPLICATE]:
    phrases.CRITERIA_ERROR_ALIASDUPLICATE,
  [DictionaryValidationErrorType.MINSIZEABSENT]:
    phrases.CRITERIA_ERROR_MINSIZEABSENT,
  [DictionaryValidationErrorType.MAXSIZEABSENT]:
    phrases.CRITERIA_ERROR_MAXSIZEABSENT,
  [DictionaryValidationErrorType.MINMAXSIZEOVERLAP]:
    phrases.CRITERIA_ERROR_MINMAXSIZEOVERLAP,
  [DictionaryValidationErrorType.MINMAXSIZEINTERNALOVERLAP]:
    phrases.CRITERIA_ERROR_MINMAXSIZEOVERLAP,
};

export const DEFAULT_SORT_FIELD = CRITERIA_COLUMNS.UPDATED;
export const DEFAULT_SORT_FIELD_ORDER = `${CRITERIA_COLUMNS.UPDATED};${SortOrderT.desc}`;
export const FULLNAME_SORT_FIELD = CRITERIA_COLUMNS.FULLNAME;
export const UPDATED_COLUMN_ASC_SORT = `${CRITERIA_COLUMNS.UPDATED};${SortOrderT.asc}`;

export const DEFAULT_DICTIONARY_ITEM: CustomerCompaniesT = {
  items: [],
  total: 0,
  isLoading: null,
  updatedItems: [],
  sortField: DEFAULT_SORT_FIELD,
  sortOrder: SortOrderT.desc,
  companyManagers: null,
};
