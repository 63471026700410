import { ModifiedCompanySizeResponse } from 'domain/dictionaries/criteria';
import { CheckboxOptionT, SelectOptionT } from '@air/domain/Forms/types';
import R from '@air/third-party/ramda';
import { CustomerCompanyAccountManagerResponse } from '@air/api';

export const prepareCompanySizesForBackend = (
  items: ModifiedCompanySizeResponse[]
): ModifiedCompanySizeResponse[] => {
  return items.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      minSize: item.minSize,
      maxSize: item.maxSize,
    };
  });
};

export const prepareOptionsArrayValues = (options: SelectOptionT[]) => {
  return options?.map((option) => option.value) as string[];
};

export const prepareSelectedCompanyIds = (values: CheckboxOptionT[]) => {
  return values.reduce((memo: number[], item): number[] => {
    return item.checked ? [...memo, ...(item.value as number[])] : memo;
  }, []);
};

export const updateCompanyManagersSelection = (
  newValues: CheckboxOptionT[],
  oldValues?: CheckboxOptionT[]
) => {
  if (oldValues) {
    return newValues.map((item) => {
      const updatedValue = oldValues.find((el) => el.label === item.label);
      return {
        ...item,
        checked: updatedValue ? updatedValue?.checked : true,
      };
    });
  }
  return newValues;
};

export const prepareCompanyManagersListForUI = (
  items: CustomerCompanyAccountManagerResponse[]
): CheckboxOptionT[] => {
  const companyManagersHash = items.reduce(
    (memo: Record<string, number[]>, item) => {
      return R.isNullOrEmpty(item.accountManager)
        ? memo
        : {
            ...memo,
            [item.accountManager]: [
              ...(memo[item.accountManager] || []),
              item.companyId,
            ],
          };
    },
    {}
  );

  return Object.keys(companyManagersHash).map((key): CheckboxOptionT => {
    return {
      checked: true,
      label: key,
      value: companyManagersHash[key],
      disabled: false,
      tooltip: null,
    };
  });
};
