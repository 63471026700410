import { ControllerFieldState } from 'react-hook-form';
import R from '@air/third-party/ramda';

import {
  DataSourceResponse,
  ImsItem,
  LicenseType,
  MatchMinerStatus,
  RoleName,
  CustomerCompanyResponseV3,
  CustomerCompanyCreateRequestV3,
  CustomerCompanyUpdateRequestV3,
  DataSourceSettingsDetailsResponseV3,
  MatchScoutStatus,
} from '@air/api';
import * as phrases from 'constants/phrases';
import { DataSourceType } from '@air/api/models/DataSourceType';
import { prepareDateForUI } from 'domain/dictionaries/criteria';
import { EXTERNAL_ATS_LIST } from 'constants/customerCompanies';

export type LogoT = {
  existing?: { name?: string; id?: string };
  new?: Partial<File>;
};

type CustomerCompanyCommonFields = {
  name: string;
  email: string;
  contactEmail: string;
  accountManager?: string;
  piplEnabled: boolean;
  applicantStatsEnabled: boolean;
  applicantEmailNotificationsEnabled: boolean;
};

export type CustomerCompanyT = CustomerCompanyCommonFields & {
  id: string;
  created: string;
  updated: string;
  licenseType: LicenseType;
  trialExpiration: string;
  atsType: DataSourceType;
  mmStatus: boolean;
  // msStatus: boolean;
};

export type CustomerCompanyFormT = CustomerCompanyCommonFields & {
  licenseType: { value: LicenseType; label: LicenseType };
  enrichAllowRole: { value: RoleName; label: RoleName };
  trialPeriodInDays?: number;
  trialExpired: Date | '';
  adminFirstName?: string;
  adminLastName?: string;
  adminEmail?: string;
  adminPassword?: string;
  atsType: DataSourceType | '';
  mmStatus: { value: MatchMinerStatus; label: MatchMinerStatus };
  msStatus: { value: MatchScoutStatus; label: MatchScoutStatus };
  shouldProcessOldJobs?: boolean;
  defaultPullJobStatuses?: string;
  pullJobStatuses?: string;
  closedJobStatuses?: string;
};

const transformArrayIntoString = (arr: string[]) => {
  return arr?.length ? arr.join(', ') : '';
};

const transformStringIntoArray = (str: string) => {
  return str?.length ? str.split(',').map((item: string) => item.trim()) : [];
};

export const prepareCustomerCompaniesForUITable = (
  items: ImsItem[],
  atsItems: DataSourceResponse[]
): CustomerCompanyT[] => {
  if (R.isNullOrEmpty(items) && R.isNullOrEmpty(atsItems)) return [];

  const atsItemsObject = atsItems?.reduce(
    (memo: Record<string, any>, atsItem) => {
      memo[atsItem.companyId as number] = atsItem;
      return memo;
    },
    {}
  );

  return items.map((item) => {
    return {
      id: String(item.id),
      name: item.name,
      email: item.email,
      contactEmail: item.settings.contactEmail,
      created: prepareDateForUI(item.created),
      updated: prepareDateForUI(item.updated),
      licenseType: item.license.type,
      trialExpiration: prepareDateForUI(item.license.expired),
      atsType: atsItemsObject[item.id]?.type,
      piplEnabled: item.settings.piplEnabled,
      mmStatus: atsItemsObject[item.id]?.matchMinerEnable,
      msStatus: item.matchScout.status === MatchScoutStatus.ENABLED,
      // msStatus: atsItemsObject[item.id]?.matchMinerEnable,
      applicantEmailNotificationsEnabled:
        item.settings.applicantEmailNotificationsEnabled,
      applicantStatsEnabled: item.settings.applicantStatsEnabled,
      accountManager: item.settings.accountManager,
    };
  });
};

export const prepareCustomerCompanyForUIForm = (
  customerCompany: CustomerCompanyResponseV3
): CustomerCompanyFormT => {
  // showing selected company from table as a form
  // does not have admin fields because they are not editable, only creatable
  const dataSourceSettings =
    customerCompany.dataSources?.[0]?.dataSourceSettings;
  const matchMinerSettings =
    customerCompany.dataSources?.[0]?.matchMinerSettings;
  return {
    name: customerCompany.name,
    email: customerCompany.email,
    contactEmail: customerCompany.settings.contactEmail,
    accountManager: customerCompany.settings.accountManager || '',
    piplEnabled: customerCompany.settings.piplEnabled,
    atsType: dataSourceSettings?.type || '',
    applicantStatsEnabled: customerCompany.settings.applicantStatsEnabled,
    applicantEmailNotificationsEnabled:
      customerCompany.settings.applicantEmailNotificationsEnabled,
    licenseType: {
      value: customerCompany.license.type,
      label: customerCompany.license.type,
    },
    trialExpired: customerCompany.license.expired
      ? new Date(customerCompany.license.expired)
      : '',
    enrichAllowRole: {
      value: customerCompany.settings.enrichAllowRole,
      label: customerCompany.settings.enrichAllowRole,
    },
    mmStatus: {
      value: matchMinerSettings?.status,
      label: matchMinerSettings?.status,
    },
    msStatus: {
      value: customerCompany.matchScout?.status,
      label: customerCompany.matchScout?.status,
    },
    shouldProcessOldJobs: dataSourceSettings?.shouldProcessOldJobs,
    defaultPullJobStatuses: transformArrayIntoString(
      dataSourceSettings?.defaultPullJobStatuses
    ),
    pullJobStatuses: transformArrayIntoString(
      dataSourceSettings?.pullJobStatuses
    ),
    closedJobStatuses: transformArrayIntoString(
      dataSourceSettings?.closedJobStatuses
    ),
  };
};

// new company is being created
export const prepareNewCompanyFormForBE = (
  formData: CustomerCompanyFormT
): CustomerCompanyCreateRequestV3 => {
  return {
    name: formData.name,
    email: formData.email,
    settings: {
      contactEmail: formData.contactEmail,
      accountManager: formData.accountManager,
      piplEnabled: formData.piplEnabled,
      applicantStatsEnabled: formData.applicantStatsEnabled,
      applicantEmailNotificationsEnabled:
        formData.applicantEmailNotificationsEnabled,
      enrichAllowRole: formData.piplEnabled
        ? formData.enrichAllowRole.value
        : RoleName.RECRUITER,
    },
    license: {
      type: formData.licenseType.value as LicenseType,
      trialPeriodInDays:
        formData.licenseType.value === LicenseType.FULL
          ? null
          : Number(formData.trialPeriodInDays),
    },
    customerAdmin: {
      firstName: formData.adminFirstName,
      lastName: formData.adminLastName,
      email: formData.adminEmail,
      password: formData.adminPassword,
    },
  };
};

// existing company has changes to be saved on BE
export const prepareExistingCompanyFormForBE = (
  formData: CustomerCompanyFormT,
  extraParams: {
    specificNotificationSender: boolean;
    dataSourceSettings: DataSourceSettingsDetailsResponseV3;
  }
): CustomerCompanyUpdateRequestV3 => {
  const dataSourceExtras = EXTERNAL_ATS_LIST.includes(
    formData.atsType as DataSourceType
  )
    ? {
        shouldProcessOldJobs: formData.shouldProcessOldJobs,
        pullJobStatuses: transformStringIntoArray(formData.pullJobStatuses),
        closedJobStatuses: transformStringIntoArray(formData.closedJobStatuses),
      }
    : {};
  const matchMinerExtras = formData.mmStatus.value
    ? {
        matchMinerSettings: {
          status: formData.mmStatus.value,
        },
      }
    : {};
  const matchScoutSettings = formData.msStatus.value
    ? { matchScout: { status: formData.msStatus.value } }
    : {};

  return {
    name: formData.name,
    email: formData.email,
    settings: {
      contactEmail: formData.contactEmail,
      accountManager: formData.accountManager,
      piplEnabled: formData.piplEnabled,
      applicantStatsEnabled: formData.applicantStatsEnabled,
      applicantEmailNotificationsEnabled:
        formData.applicantEmailNotificationsEnabled,
      enrichAllowRole: formData.piplEnabled
        ? formData.enrichAllowRole.value
        : RoleName.RECRUITER,
      // this is not used on FE but BE asked to send it back to them because life is hard
      specificNotificationSender: extraParams.specificNotificationSender,
    },
    license: {
      type: formData.licenseType.value as LicenseType,
      expired: formData.trialExpired ? formData.trialExpired.getTime() : null,
    },
    ...matchScoutSettings,
    dataSources:
      R.isEmpty(matchMinerExtras) && R.isEmpty(dataSourceExtras)
        ? []
        : [
            {
              ...(extraParams.dataSourceSettings.datasourceId
                ? { dataSourceId: extraParams.dataSourceSettings.datasourceId }
                : {}),
              ...(formData.atsType ? { type: formData.atsType } : {}),
              ...matchMinerExtras,
              dataSourceSettings: {
                ...extraParams.dataSourceSettings,
                closedJobStatuses:
                  extraParams.dataSourceSettings.closedJobStatuses || [],
                pullJobStatuses:
                  extraParams.dataSourceSettings.pullJobStatuses || [],
                ...dataSourceExtras,
              },
            },
          ],
  };
};

export const DEFAULT_TRIAL_DAYS = 30;

export type CustomerCompanyExtendedT = {
  form: CustomerCompanyFormT;
  id: string;
  name: string;
  created: string;
  updated: string;
  logoFilename: string;
  fullVersionRequested: string;
  mmIndexedCandidatesCount: number;
  dataSourceSettings: DataSourceSettingsDetailsResponseV3;
  mmRequested: string;
  // this is not used on FE but BE asked to send it back to them because life is hard
  specificNotificationSender?: boolean;
};

// transform BE company data to be shown in customer company form UI
export const getCompanyData = (
  selectedCompany?: CustomerCompanyResponseV3
): CustomerCompanyExtendedT => {
  if (selectedCompany) {
    const matchMinerSettings =
      selectedCompany.dataSources?.[0]?.matchMinerSettings;
    const dataSourceSettings =
      selectedCompany.dataSources?.[0]?.dataSourceSettings;
    return {
      form: prepareCustomerCompanyForUIForm(selectedCompany),
      // ids come as numbers from BE but on FE we have 'new' that is a string
      id: String(selectedCompany?.id),
      created: prepareDateForUI(selectedCompany.created),
      updated: prepareDateForUI(selectedCompany.updated),
      logoFilename: selectedCompany.settings.logoFilename,
      fullVersionRequested: prepareDateForUI(selectedCompany.license.requested),
      mmIndexedCandidatesCount: matchMinerSettings?.indexedCandidatesCount,
      mmRequested: prepareDateForUI(matchMinerSettings?.requested),
      name: selectedCompany.name,
      specificNotificationSender:
        selectedCompany.settings.specificNotificationSender,
      dataSourceSettings,
    };
  }

  return {
    form: {
      name: '',
      email: '',
      contactEmail: '',
      piplEnabled: false,
      applicantEmailNotificationsEnabled: false,
      applicantStatsEnabled: true,
      licenseType: { value: LicenseType.FULL, label: LicenseType.FULL },
      trialExpired: null,
      atsType: '',
      enrichAllowRole: { value: RoleName.RECRUITER, label: RoleName.RECRUITER },
      trialPeriodInDays: DEFAULT_TRIAL_DAYS,
      adminFirstName: '',
      adminLastName: '',
      adminEmail: '',
      adminPassword: '',
      mmStatus: { value: undefined, label: undefined },
      msStatus: { value: undefined, label: undefined },
    },
    id: null,
    created: null,
    updated: null,
    logoFilename: null,
    fullVersionRequested: null,
    mmIndexedCandidatesCount: null,
    mmRequested: null,
    name: null,
    dataSourceSettings: {},
  };
};

export const getFormLabel = (
  label: string,
  fieldState: ControllerFieldState,
  replaceError?: boolean
) => {
  if (fieldState.error?.type === 'required') {
    const errorPrefix = R.isNullOrEmpty(fieldState.error)
      ? ''
      : `${fieldState.error.message} `;
    return `${errorPrefix}${replaceError ? '' : label}`;
  }
  return R.isNullOrEmpty(fieldState.error) ? label : fieldState.error.message;
};

export const MAX_FIELD_LENGTH = 128;

export const defaultFieldValidationRules = {
  required: {
    value: true,
    message: phrases.FORM_ERROR_MISSING,
  },
};
