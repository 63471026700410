import React, { useEffect } from 'react';

import { getUrlWithParamsAndCurrentTab } from '@air/constants/apiEndpoints';
import { TabsControl } from '@air/components';
import { TabValues } from '@air/constants/tabs';
import { TabsControlPropsT } from '@air/components/TabsControl/TabsControl';

export const TabControlsWrapper = <T extends TabValues>(
  props: TabsControlPropsT<T>
) => {
  const { selectedTab } = props;
  useEffect(() => {
    history.pushState(
      {},
      '',
      getUrlWithParamsAndCurrentTab({
        url: location.pathname,
        tab: selectedTab,
      })
    );
  }, [selectedTab]);

  return <TabsControl<TabValues> {...props} />;
};

TabControlsWrapper.displayName = 'TabControlsWrapper';
