import React, { useEffect } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { FormField, Paragraph, Toggle, UIText } from '@air/components';
import * as phrases from 'constants/phrases';
import { getFormLabel } from 'domain/customerCompanies';

import sharedStyles from 'features/CustomerCompaniesView/CustomerCompanуForm/CustomerCompanyForm.css';

type Props = {
  control?: Control<Record<string, any>, object>;
  isDisabled: boolean;
  isLoaded: boolean;
  formValues: { [key: string]: any };
  setValue: UseFormSetValue<Record<string, any>>;
};

export const ATSSettingsSection: React.FC<Props> = ({
  control,
  isDisabled,
  formValues,
  setValue,
  isLoaded,
}) => {
  useEffect(() => {
    if (isLoaded && !formValues.closedJobStatuses) {
      setValue('shouldProcessOldJobs', false);
    }
  }, [formValues.closedJobStatuses, setValue, isLoaded]);
  return (
    <div className={sharedStyles.formGroup}>
      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_GROUP_ATS}
      </UIText>
      <Paragraph className={sharedStyles.description}>
        {phrases.CUSTOMER_COMPANY_FORM_GROUP_ATS_DESC}
      </Paragraph>
      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_SUBGROUP_ATS_PULL}
      </UIText>

      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="defaultPullJobStatuses"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              type="text"
              isDisabled
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_DEFAULT_STATUSES,
                fieldState
              )}
              {...field}
            />
          )}
        />
        <Controller
          name="pullJobStatuses"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              type="text"
              isDisabled={isDisabled}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_CUSTOM_STATUSES,
                fieldState
              )}
              {...field}
            />
          )}
        />
      </div>

      <UIText small className={sharedStyles.formGroupTitle}>
        {phrases.CUSTOMER_COMPANY_FORM_SUBGROUP_ATS_CLOSE}
      </UIText>

      <div className={sharedStyles.inputsWrapper}>
        <Controller
          name="closedJobStatuses"
          control={control}
          render={({ field, fieldState }) => (
            <FormField
              type="text"
              isDisabled={isDisabled}
              label={getFormLabel(
                phrases.CUSTOMER_COMPANY_LABEL_STATUSES,
                fieldState
              )}
              {...field}
            />
          )}
        />
      </div>

      <div className={sharedStyles.inputsWrapper}>
        <label className={sharedStyles.toggleLabel}>
          <Controller
            name="shouldProcessOldJobs"
            control={control}
            render={({ field }) => (
              <Toggle
                name="shouldProcessOldJobs"
                disabled={isDisabled || !formValues.closedJobStatuses}
                onChange={field.onChange}
                checked={field.value}
                className={sharedStyles.toggleButton}
              />
            )}
          />
          <Paragraph small>
            {phrases.CUSTOMER_COMPANY_LABEL_PROCESS_OLD_JOBS}
          </Paragraph>
        </label>
      </div>
    </div>
  );
};
