import {
  TitleUpsertRequest,
  TitleAliasResponse,
  TitleCategoryUpsertRequest,
} from '@air/api';
import {
  ModifiedRoleResponse,
  FetchCriteriaParamsT,
  constructSortString,
  ModifiedRoleCategoryResponse,
} from './criteria';
import { PAGE_START, REQUEST_PAGING_SIZE } from 'constants/api';
import {
  prepareAliasesForBackend,
  prepareJobSpecializationsForBackend,
} from 'domain/dictionaries/dictionaries';

export type FetchRolesParamsT = FetchCriteriaParamsT & {
  managerial?: boolean;
};

export const mapSimilarGroups = (options: TitleAliasResponse[]) =>
  options.map((option) => ({
    value: option.id,
    label: option.fullName,
  }));

export const prepareRolesForBackend = (
  roles: ModifiedRoleResponse[]
): TitleUpsertRequest[] => {
  return roles.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      aliases: prepareAliasesForBackend(item.aliases),
      specializations: prepareJobSpecializationsForBackend(
        item.specializations
      ),
      similarGroups: (item.similarGroups || []).map((group) => ({
        id: group.value,
        fullName: group.label,
      })),
      managerial: item.managerial,
      priority: item.priority || 0,
      deprecated: item.deprecated,
    } as TitleUpsertRequest;
  });
};

export const prepareRoleCategoriesForBackend = (
  categories: ModifiedRoleCategoryResponse[]
): TitleCategoryUpsertRequest[] => {
  return categories.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      items: item.items,
      deprecated: item.deprecated,
      specializations: prepareJobSpecializationsForBackend(
        item.specializations
      ),
    };
  });
};

export const prepareFetchRolesParams = (
  params: FetchRolesParamsT
): FetchRolesParamsT => {
  return {
    keyword: params.keyword,
    includeDeprecated: params.includeDeprecated,
    managerial: params.managerial,
    page: params.page || PAGE_START,
    sort: constructSortString(params.sortField, params.sortOrder),
    size: REQUEST_PAGING_SIZE,
  };
};
