import R from '@air/third-party/ramda';
import { Cell, Row } from 'react-table';
import { downloadBlob, getFileNameFromHeader } from '@air/utils/downloadFiles';
import { DEFAULT_SORT_FIELD_ORDER } from 'constants/criteria';
import {
  ModifiedCriteriaComputedResponse,
  ModifiedCriteriaListResponse,
} from 'domain/dictionaries/criteria';

export type GetCellComponentParamsT = {
  cell: Cell;
  row: Row;
  hiddenTextColumns?: string[];
  removeItem?: (id: string) => void;
  onCellClick?: (id: string) => void;
  loadOptions?: any;
  specializations?: { label: string; value: string }[];
  disabled?: boolean;
};

export const getItemErrorMessage = (
  errors: { [key: string]: string[] },
  errorMapping: { [key: string]: string }
): string => {
  if (errors) {
    const errorMessagesArray = Object.keys(errors).map(
      // key contains the id of the error (DictionaryValidationErrorType)
      // value contains ids of intersecting items (like duplicated)
      (key) => {
        const ids = errors[key] ? errors[key].join(', ') : null;
        // Example: Aliases are duplicated, see 33123.
        return ids ? `${errorMapping[key]}, see ${ids}` : errorMapping[key];
      }
    );
    return errorMessagesArray.join(', ');
  }
};

export const DEFAULT_FILE_EXTENSION = 'jsonl';

export const downloadFile = (
  response: Blob,
  headers: any,
  DEFAULT_FILE_NAME: string
) => {
  const [fileName, fileExtension] = getFileNameFromHeader(
    headers,
    DEFAULT_FILE_NAME,
    DEFAULT_FILE_EXTENSION
  );
  return downloadBlob(fileName, `.${fileExtension}`)(response);
};

export function computeDictionaryExpandedItem<
  I extends { id?: string | number }
>(updatedItems: Array<I>, items: Array<I>, expandedItemId: number) {
  const allItems = [...updatedItems, ...items];
  return allItems.find((item) => String(item.id) === String(expandedItemId));
}

export const prepareAliasesForBackend = (aliases?: string | string[]) => {
  if (!aliases) return [];
  if (Array.isArray(aliases)) return aliases;

  return (aliases as string).split(',').reduce((memo, item) => {
    return item ? [...memo, item.trim() as string] : memo;
  }, []);
};

export const prepareJobSpecializationsForBackend = (
  specializations: { label: string; value: string }[] | string[]
): string[] =>
  R.map<{ label: string; value: string } | string, string>((it) => {
    return typeof it === 'string' ? it : it.value;
  }, specializations);

export function resetToDefaultDictionaryState(
  state: ModifiedCriteriaListResponse
) {
  return {
    ...state,
    items: state.items.map((item) => ({ ...item, errors: null })),
    sort: DEFAULT_SORT_FIELD_ORDER,
    updatedItems: [] as Array<ModifiedCriteriaComputedResponse>,
  };
}
