import React, { useMemo, useState, useCallback } from 'react';
import { toast } from '@air/third-party/toast';

import { CompaniesContext, DictionaryContextT } from 'context';
import {
  ModifiedCriteriaListResponse,
  FetchCriteriaParamsT,
  prepareDictionaryObjectForUI,
  ModifiedCompanyResponse,
} from 'domain/dictionaries/criteria';
import { ExtraResponseParamsT } from '@air/domain/Common/apiTypes';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { CompanyListResponse } from '@air/api';
import { downloadFile } from 'domain/dictionaries/dictionaries';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';
import * as sharedUrls from '@air/constants/apiEndpoints';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import R from '@air/third-party/ramda';

const DEFAULT_FILE_NAME = 'company_dictionary';

export const CompaniesProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] =
    useState<ModifiedCriteriaListResponse>(DEFAULT_DICTIONARY_ITEM);

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      const { sortField, sortOrder } = params;
      setDictionaryItems((state) => ({
        ...state,
        sortField: sortField || state?.sortField,
        sortOrder: sortOrder || state?.sortOrder,
        isLoading: true,
      }));

      DictionariesApi.fetchDictionary<CompanyListResponse>(
        params,
        sharedUrls.DICTIONARY_API_COMPANY
      ).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: CompanyListResponse) => {
          setDictionaryItems((state) => {
            return prepareDictionaryObjectForUI(state, response, params);
          });
        }
      );
    },
    []
  );

  const exportDictionaryItems = useCallback(() => {
    const exportUrl = sharedUrls.makeDictionaryExportUrl(
      sharedUrls.CATEGORY_COMPANY
    );
    DictionariesApi.exportDictionary(exportUrl).fork(
      () => toast.error(phrases.DICTIONARY_EXPORT_ERROR),
      (response: Blob, extraParams: ExtraResponseParamsT) => {
        return downloadFile(response, extraParams?.headers, DEFAULT_FILE_NAME);
      }
    );
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      items: dictionaryItems.items as ModifiedCompanyResponse[],
      updatedItems: dictionaryItems.updatedItems as ModifiedCompanyResponse[],
      methods: {
        fetchDictionaryItems,
        exportDictionaryItems,
      },
    };
  }, [dictionaryItems, fetchDictionaryItems, exportDictionaryItems]);

  return (
    <CompaniesContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </CompaniesContext.Provider>
  );
};

export const useCompaniesContext = <Selected,>(
  selector: (state: DictionaryContextT<ModifiedCompanyResponse>) => Selected
) => {
  return useContextSelector(CompaniesContext, selector);
};

export const useCompaniesMethods = () => {
  return useEqualContextSelector(
    CompaniesContext,
    (state: DictionaryContextT<ModifiedCompanyResponse>) => state.methods,
    R.shallowEqualObjects
  );
};

CompaniesProvider.displayName = 'CompaniesProvider';
