import { ReactText } from 'react';
import R from '@air/third-party/ramda';
import dayjs from 'dayjs';
import {
  findItemById,
  transformArrayIntoObjectByFieldName,
} from 'domain/listHelpers';
import {
  SkillResponse,
  Certification,
  DictionaryUpsertResponse,
  AccreditationResponse,
  SkillListFullResponse,
  InstitutionListResponse,
  InstitutionListListResponse,
  CertificationListResponse,
  TitleListResponse,
  InstitutionType,
  InstitutionResponse,
  DmsEducationDegree,
  InstitutionList,
  TitleResponse,
  DictionaryIdentifiableItem,
  EducationMajorGroup,
  DmsEducationMajor,
  CompanySizeUpsertRequest,
  Company,
  DictionaryItem,
  DmsCompanyEmployeesInfo,
  DmsCompanyRevenueInfo,
} from '@air/api';

import { CRITERIA_COLUMNS, DEFAULT_SORT_FIELD } from 'constants/criteria';
import { SortOrderT } from 'constants/api';
import { DATE_FORMAT_AMERICAN } from 'constants/tables';
import { toast } from '@air/third-party/toast';
import * as phrases from 'constants/phrases';
import { SelectOptionT, CheckboxOptionT } from '@air/domain/Forms/types';
import { mapSimilarGroups } from 'domain/dictionaries/roles';
import { CustomerCompanyT } from 'domain/customerCompanies';

export type CriteriaResponse = (
  | SkillResponse
  | InstitutionList
  | TitleResponse
  | Certification
  | DmsEducationDegree
  | Company
) & { specializations?: string[] };

type CriteriaAdditionalFields = {
  errors?: string[];
  __isNew__?: boolean;
  __isUpdated__?: boolean;
};

export type ModifiedCriteriaResponse = CriteriaAdditionalFields & {
  id?: number | string;
  fullName?: string;
  shortName?: string;
  aliases?: string;
  deprecated?: boolean;
  created?: string | number;
  updated?: string | number;
};

export type ModifiedSkillResponse = ModifiedCriteriaResponse & {
  index?: number;
  language?: boolean;
  specializations?: string[] | { label: string; value: string }[];
};
export type ModifiedCertificationResponse = ModifiedCriteriaResponse & {
  index?: number;
  company?: string;
  specializations?: string[] | { label: string; value: string }[];
};
export type ModifiedRoleResponse = ModifiedCriteriaResponse & {
  similarGroups?: SelectOptionT[];
  managerial?: boolean;
  priority?: boolean;
  specializations?: string[] | { label: string; value: string }[];
};

export type ModifiedRoleCategoryResponse = ModifiedCriteriaResponse & {
  items: Array<DictionaryIdentifiableItem>;
  specializations: { label: string; value: string }[];
};

export type ModifiedInstitutionResponse = ModifiedCriteriaResponse & {
  accreditation: AccreditationResponse;
  type: InstitutionType;
  items: InstitutionResponse[];
  country: string;
  state: string;
  city: string;
  amount?: number;
};

export type ModifiedCompanySizeResponse = CompanySizeUpsertRequest &
  CriteriaAdditionalFields;

export type ModifiedCompanyResponse = Company &
  CriteriaAdditionalFields & {
    accountManager: string;
  };

export type ModifiedDmsEducationDegree = DmsEducationDegree &
  CriteriaAdditionalFields & {
    amount?: number;
  };

export type ModifiedEducationMajorGroup = EducationMajorGroup &
  CriteriaAdditionalFields & {
    amount?: number;
  };

export type ModifiedIndustryResponse = ModifiedCriteriaResponse &
  CriteriaAdditionalFields & {
    id?: number | string;
    fullName?: string;
  };

export type ModifiedDictionaryGroupItem<I> = ModifiedCriteriaResponse & {
  items?: I[];
};

export type ModifiedCriteriaComputedResponse =
  | ModifiedSkillResponse
  | ModifiedCertificationResponse
  | ModifiedRoleResponse
  | ModifiedRoleCategoryResponse
  | ModifiedInstitutionResponse
  | ModifiedDmsEducationDegree
  | ModifiedCompanyResponse;

export interface ModifiedCriteriaListResponse {
  // extended CriteriaListFullResponse
  isLoading?: boolean;
  items: Array<ModifiedCriteriaComputedResponse>;
  updatedItems: Array<ModifiedCriteriaComputedResponse> | null;
  total?: number;
  sortField?: string;
  sortOrder?: SortOrderT;
}

export type CustomerCompaniesT = {
  isLoading?: boolean;
  items: Array<CustomerCompanyT>;
  updatedItems: Array<CustomerCompanyT> | null;
  total?: number;
  sortField?: string;
  sortOrder?: SortOrderT;
  companyManagers: CheckboxOptionT[];
};

export type DmsDictionaryResponse<I> = {
  items: I[];
  total: number;
};

export type FetchCriteriaParamsT = {
  keyword?: string;
  size?: number;
  includeDeprecated?: boolean;
  managerial?: boolean;
  page?: number;
  sortField?: string;
  sortOrder?: SortOrderT;
  sort?: string;
  atsType?: string;
  licenseType?: string[];
  includeId?: number[] | string[];
};

export type UpdateFieldValueT =
  | string
  | number
  | boolean
  | DictionaryIdentifiableItem[]
  | DmsEducationMajor[];

export type UpdateFieldParamsT = {
  id?: string;
  value?: UpdateFieldValueT;
  item?: ModifiedCriteriaComputedResponse | DictionaryIdentifiableItem[];
  fieldName?: string | ReactText;
};

export type UpdateFieldsExtendedParamsT = {
  localState: ModifiedCriteriaListResponse;
  updatedField: UpdateFieldParamsT;
};

export const constructSortString = (
  sortField: string = DEFAULT_SORT_FIELD,
  sortOrder: string = SortOrderT.desc
): string => {
  return `${sortField};${sortOrder}`;
};

const getRangeNumber = (amount?: number, currency?: string): string => {
  if (currency && amount) {
    return `${amount} ${currency}`;
  }
  // no nulls should be returned
  return String(amount || '');
};

const prepareRangeString = (
  data?: DmsCompanyEmployeesInfo | DmsCompanyRevenueInfo
): string => {
  if (!data || (!data.current && !data.startingFrom && !data.to)) return '';

  const currency = (data as DmsCompanyRevenueInfo).currency;

  if (currency) {
    return data.current
      ? getRangeNumber(data.current, currency)
      : `${getRangeNumber(data.startingFrom, currency)} - ${getRangeNumber(
          data.to,
          currency
        )}`;
  }

  return data.current
    ? getRangeNumber(data.current)
    : `${getRangeNumber(data.startingFrom)} - ${getRangeNumber(data.to)}`;
};

const getCriteriaField = (field?: string) => {
  return field || '';
};

type AdditionalDictFieldsT = {
  aliases?: any;
  shortName?: string;
  employees?: string;
  revenue?: string;
  similarGroups?: SelectOptionT[];
};

export const prepareDateForUI = (
  date: number,
  dateFormat: string = DATE_FORMAT_AMERICAN
): string => {
  return date ? dayjs(date).format(dateFormat) : null;
};

export const prepareDictionaryForUI = (
  dictionary: CriteriaResponse[]
): ModifiedCriteriaComputedResponse[] => {
  if (R.isNullOrEmpty(dictionary)) {
    return [];
  }
  return dictionary.map((item: CriteriaResponse) => {
    const additionalFields: AdditionalDictFieldsT = {};
    if ('aliases' in item) {
      additionalFields.aliases = (item.aliases || []).join(', ');
    }
    if ('shortName' in item) {
      additionalFields.shortName = getCriteriaField(item.shortName);
    }
    if ('employees' in item) {
      additionalFields.employees = prepareRangeString(item.employees);
    }
    if ('revenue' in item) {
      additionalFields.revenue = prepareRangeString(item.revenue);
    }
    if ('similarGroups' in item) {
      additionalFields.similarGroups = mapSimilarGroups(item.similarGroups);
    }

    return {
      ...item,
      __isNew__: false,
      created: prepareDateForUI(item.created),
      updated: prepareDateForUI(item.updated),
      ...additionalFields,
    };
  });
};

export function prepareDictionaryObjectForUI<R extends { items: unknown[] }>(
  localState: ModifiedCriteriaListResponse,
  response:
    | R // TODO: eventually leave only generic R
    | SkillListFullResponse
    | InstitutionListListResponse
    | InstitutionListResponse
    | CertificationListResponse
    | TitleListResponse,
  params?: FetchCriteriaParamsT
): ModifiedCriteriaListResponse {
  const fetchedItems = prepareDictionaryForUI(response?.items);
  return {
    ...response,
    sortField: localState.sortField,
    sortOrder: localState.sortOrder,
    updatedItems: localState.updatedItems,
    items: params?.page ? [...localState.items, ...fetchedItems] : fetchedItems,
    isLoading: false,
  };
}

// map updated item into the existing items
export const computeUpdatedItems = (
  items: ModifiedCriteriaComputedResponse[],
  id: number | string,
  updatedField: { [key: string]: UpdateFieldValueT }
): ModifiedCriteriaComputedResponse[] => {
  return items.map((item: ModifiedCriteriaComputedResponse) => {
    return String(item.id) === String(id) ? { ...item, ...updatedField } : item;
  });
};

// update table item in locally in the list of added/edited items
// prepare for sending to BE
export const computeUpdatedItemsInUpdatedList = ({
  localState,
  updatedField,
}: UpdateFieldsExtendedParamsT) => {
  const { item, id, fieldName, value } = updatedField;
  const oldUpdatedItems = localState?.updatedItems || [];
  let updatedItems;
  const itemPresentInUpdatedItems = findItemById(localState?.updatedItems, id);
  // item was just added to the list and it is empty
  if (
    (item as ModifiedCriteriaComputedResponse)?.__isNew__ &&
    R.isNullOrEmpty(itemPresentInUpdatedItems)
  ) {
    // in this case value is empty, just adding a new empty item to the table
    updatedItems = [item, ...oldUpdatedItems];
  } else {
    const extraFields: { amount?: number } = {};
    if (
      updatedField.item &&
      CRITERIA_COLUMNS.ITEMS in updatedField.item &&
      CRITERIA_COLUMNS.ITEMS === fieldName
    ) {
      extraFields.amount = (value as DictionaryIdentifiableItem[]).length;
    }
    const updatedFieldData = { [fieldName]: value, __isUpdated__: true };
    // item is not present in updatedItems yet
    if (R.isNullOrEmpty(itemPresentInUpdatedItems)) {
      const itemToUpdate = findItemById(localState?.items, id);
      const updatedItem = {
        ...itemToUpdate,
        ...updatedFieldData,
        ...extraFields,
      };
      updatedItems = [...oldUpdatedItems, updatedItem];
    } else {
      // item is present in updatedItems - was already edited
      updatedItems = computeUpdatedItems(oldUpdatedItems, id, {
        ...updatedFieldData,
        ...extraFields,
      });
    }
  }

  return { ...localState, updatedItems };
};

type SkillsResponseIntoObjectsT = {
  savedItemsObject: { [key: string]: ModifiedCriteriaComputedResponse };
  invalidItemsObject: { [key: string]: ModifiedCriteriaComputedResponse };
};

export const transformCriteriaResponseIntoObjects = (
  response: DictionaryUpsertResponse
): SkillsResponseIntoObjectsT => {
  const savedItemsObject = transformArrayIntoObjectByFieldName(
    response.savedItems,
    CRITERIA_COLUMNS.FULLNAME
  );
  const invalidItemsObject = transformArrayIntoObjectByFieldName(
    response.invalidItems,
    CRITERIA_COLUMNS.FULLNAME
  );
  return { savedItemsObject, invalidItemsObject };
};

// map invalid items with errors to the updatedItems array
export const mapUpdatedItemsToInvalidItems = (
  oldUpdatedItems: ModifiedCriteriaComputedResponse[],
  invalidItemsObject: any
): ModifiedCriteriaComputedResponse[] => {
  return oldUpdatedItems.reduce(
    (
      memo: ModifiedCriteriaComputedResponse[] | [],
      item: ModifiedCriteriaComputedResponse
    ) => {
      const currentInvalidItem = invalidItemsObject[item.fullName];
      if (!currentInvalidItem) {
        return memo;
      }
      return [...memo, { ...item, errors: currentInvalidItem.errors }];
    },
    []
  );
};

export const getNewlyAddedItems = (
  items: ModifiedCriteriaComputedResponse[]
): ModifiedCriteriaComputedResponse[] => {
  if (!items?.length) {
    return [];
  }
  const groupedItems = R.groupBy((el) => el.__isNew__ + '', items);
  return groupedItems.true || [];
};

export const mapPresentItemsToUpdatedItems = (
  items: ModifiedCriteriaComputedResponse[],
  updatedItems: ModifiedCriteriaComputedResponse[]
): ModifiedCriteriaComputedResponse[] => {
  if (!updatedItems?.length) return items || [];
  return items.map((item) => {
    const updatedItem = updatedItems?.find(
      (updatedItem: ModifiedCriteriaComputedResponse) =>
        updatedItem?.id === item?.id // TODO: fullName?
    );
    return updatedItem || item;
  });
};

export const notifyAfterPublishingChanges = (
  response: DictionaryUpsertResponse,
  allItemsValid?: boolean
) => {
  if (allItemsValid) {
    toast.success(phrases.CRITERIA_PUBLISHING_SUCCESS);
  } else {
    const savedItemsLength = response.savedItems.length;
    const invalidItemsLength = response.invalidItems.length;
    const ids = response.invalidItems.reduce(
      (memo, el) => (el.id ? [...memo, el.id] : memo),
      []
    );
    toast.error(
      phrases.GET_INVALID_CRITERIA_PUBLISHING_MESSAGE(invalidItemsLength, ids),
      {
        closeOnClick: true,
      }
    );

    if (savedItemsLength) {
      toast.success(
        phrases.GET_VALID_CRITERIA_PUBLISHING_MESSAGE(savedItemsLength),
        {
          closeOnClick: true,
        }
      );
    }
  }
};

/**
 * This method transforms the response from publishing dictionary
 * and prepares the items to be shown in UI
 * @param {ModifiedCriteriaListResponse} state - dictionaryItems that are currently stored in dictionary provider
 * @param {R} response - saved or invalid items from back-end (DictionaryUpsertResponse and others)
 */
export function computeUpdatedDictionaryItems<R extends { [key: string]: any }>(
  state: ModifiedCriteriaListResponse,
  response: R
) {
  const updatedItemsLength = state.updatedItems.length;
  // enforce structure needed for EditableTable
  const savedItems = prepareDictionaryForUI(response?.savedItems);
  // savedItemsObject incorporates both successfully updated (already existing) items
  // and newly created ones
  const {
    savedItemsObject,
    invalidItemsObject,
    // @ts-ignore
  } = transformCriteriaResponseIntoObjects({ ...response, savedItems });

  // update items that were not saved (add error messages from BE)
  const updatedItems = mapUpdatedItemsToInvalidItems(
    state?.updatedItems,
    invalidItemsObject
  );

  // if there are invalid (not saved) items from BE leave state.items as they are
  // because invalid items are already saved in state.updatedItems on the previous step
  const computedItems =
    updatedItemsLength === response.invalidItems.length
      ? state?.items
      : state?.items?.map((item: DictionaryItem) => {
          const savedItem = savedItemsObject[item.fullName];
          if (savedItem) {
            // remove the items that were updated on BE (and are already present in state.items
            // leave out newly added items
            delete savedItemsObject[item.fullName];
          }
          return (
            savedItem || {
              ...item,
              errors: invalidItemsObject?.[item.fullName]?.errors,
            }
          );
        });

  return {
    ...state,
    updatedItems,
    // now savedItemsObject has only the items that were newly added, prepend those
    items: [...R.values(savedItemsObject), ...computedItems],
  };
}
