import { LoginRequest, LoggedInAdminInfoResponse } from '@air/api/models';
import { createContext } from 'use-context-selector';

export type AdminProfileContextT = {
  user: LoggedInAdminInfoResponse | null;
  isUserLoaded?: boolean;
  authenticated: boolean;
  authError: string | null;
  authDataReceived: boolean;
  uiIsReady: boolean;
  invalidToken: boolean;
  methods: {
    authenticate?: (userData: LoginRequest) => void;
    logout?: () => void;
    getUserProfile?: () => void;
  };
};

export const AdminProfileContext = createContext<AdminProfileContextT>({
  user: null,
  authenticated: false,
  authError: null,
  authDataReceived: false,
  uiIsReady: false,
  invalidToken: false,
  methods: {},
});
