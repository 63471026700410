/* Top Navigation */
export const TOP_NAV_DICTIONARIES = 'Dictionaries';
export const TOP_NAV_CUSTOMER_COMPANIES = 'Organizations';

/* Left Menu */
export const NAVIGATION_LINK_SKILLS = 'Skills';
export const NAVIGATION_LINK_ROLES = 'Roles';
export const NAVIGATION_LINK_COMPANIES = 'Companies';
export const NAVIGATION_LINK_CERTIFICATIONS = 'Certifications';
export const NAVIGATION_LINK_UNIVERSITIES = 'Universities';
export const NAVIGATION_LINK_DEGREES = 'Degrees';
export const NAVIGATION_LINK_MAJORS = 'Majors';
export const NAVIGATION_LINK_INDUSTRIES = 'Industries';

/* criteria */
export const GET_INVALID_CRITERIA_PUBLISHING_MESSAGE = (
  itemsLength: number,
  ids: number[]
) => {
  const singular = itemsLength === 1;
  const idsMessage = ids?.length > 0 ? `\nIDs: ${ids.join(', ')}` : '';
  return `${itemsLength} item${singular ? '' : 's'} ${
    singular ? 'is' : 'are'
  } not published.${idsMessage}`;
};
export const GET_VALID_CRITERIA_PUBLISHING_MESSAGE = (itemsLength: number) => {
  const singular = itemsLength === 1;
  return `${itemsLength} item${singular ? '' : 's'} ${
    singular ? 'is' : 'are'
  } published successfully`;
};
export const CRITERIA_PUBLISHING_SUCCESS = 'Changes published successfully';

/* Dictionaries */
export const DICTIONARY_FETCHING_ERROR = 'Error fetching dictionary';
export const DICTIONARY_PUBLISHING_ERROR = 'Error publishing dictionary';
export const DICTIONARY_EXPORT_ERROR = 'Error exporting dictionary';

/* controls */
export const YES = 'Yes';
export const NO = 'No';
export const REMOVE_ADDED_ITEM = 'Remove added item';
export const HIDE_DEPRECATED = 'Hide deprecated';
export const SHOW_MANAGERIAL = 'Show Managerial Only';
export const BUTTON_DISCARD = 'Discard';
export const BUTTON_SAVE = 'Publish changes';
export const BUTTON_ADD_ITEM = 'Add Item';
export const BUTTON_CREATE_CUSTOMER_COMPANY = 'Create Organization';
export const BUTTON_EXPORT = 'Export seeds';
export const BEFORE_UNLOAD_MESSAGE =
  'There are unpublished changes. If you leave this page, your changes will be lost';
export const LOAD_MORE = 'Load more';
export const LOADING = 'Loading...';

export const CRITERIA_COLUMN_TITLE_ID = 'Id';
export const CRITERIA_COLUMN_TITLE_FULL_NAME = 'Full name';
export const CRITERIA_COLUMN_TITLE_SHORT_NAME = 'Short name';
export const CRITERIA_COLUMN_TITLE_ALIASES = 'Aliases';
export const CRITERIA_COLUMN_TITLE_SPECIALIZATIONS = 'Specializations';
export const CRITERIA_COLUMN_TITLE_INDEX = 'Priority (Index)';
export const CRITERIA_COLUMN_TITLE_CREATED = 'Created';
export const CRITERIA_COLUMN_TITLE_UPDATED = 'Updated';
export const CRITERIA_COLUMN_TITLE_DEPRECATED = 'Deprecated';
export const CRITERIA_COLUMN_TITLE_LANGUAGE = 'Is language';
export const CRITERIA_COLUMN_TITLE_COMPANY = 'Company';
export const CRITERIA_COLUMN_TITLE_SIMILAR_GROUPS = 'Similar groups';
export const CRITERIA_COLUMN_TITLE_MANAGERIAL = 'Is Managerial';
export const CRITERIA_COLUMN_TITLE_INCLUDED_ACTIVE_ROLES =
  'Included Active Roles';
export const CRITERIA_COLUMN_TITLE_LEVEL = 'Level';

export const CRITERIA_COLUMN_TITLE_ACCREDITATION = 'Accreditation';
export const CRITERIA_COLUMN_TITLE_INSTITUTION_TYPE = 'Type';
export const CRITERIA_COLUMN_TITLE_COUNTRY = 'Location Country';
export const CRITERIA_COLUMN_TITLE_STATE = 'Location State';
export const CRITERIA_COLUMN_TITLE_CITY = 'Location City';

export const CRITERIA_COLUMN_TITLE_MIN_SIZE = 'Min Size';
export const CRITERIA_COLUMN_TITLE_MAX_SIZE = 'Max Size';
export const CRITERIA_COLUMN_TITLE_EMPLOYEES = 'Employees';
export const CRITERIA_COLUMN_TITLE_REVENUE = 'Revenue';
export const CRITERIA_COLUMN_TITLE_INDUSTRY = 'Industry';
export const CRITERIA_COLUMN_TITLE_COMPANY_TYPE = 'Company Type';
export const CRITERIA_COLUMN_TITLE_ENRICHED = 'Enriched';
export const CRITERIA_COLUMN_TITLE_SOURCE = 'Source';

export const CRITERIA_COLUMN_TITLE_UNI_LIST_AMOUNT =
  'Included Active Universities';
export const CRITERIA_COLUMN_TITLE_MAJOR_LIST_AMOUNT = 'Included Majors';

export const CRITERIA_COLUMN_TITLE_NAME = 'Name';
export const CRITERIA_COLUMN_TITLE_CUSTOMER_EMAIL = 'Customer email';
export const CRITERIA_COLUMN_TITLE_FROM_EMAIL = '"From" email';
export const CRITERIA_COLUMN_TITLE_LICENSE = 'License';
export const CRITERIA_COLUMN_TITLE_TRIAL_EXPIRATION = 'Trial expiration';
export const CRITERIA_COLUMN_TITLE_ATS = 'ATS';
export const CRITERIA_COLUMN_DROPDOWN_TITLE_LICENSE_STATUS = 'License status';
export const CRITERIA_COLUMN_TITLE_PIPL_ENABLED = 'PIPL enabled';
export const CRITERIA_COLUMN_TITLE_MM_STATUS = 'MatchMiner Status';
export const CRITERIA_COLUMN_TITLE_MS_STATUS = 'MatchScout Status';
export const CRITERIA_COLUMN_TITLE_APPLICANT_EMAILS_ENABLED =
  'Applicant emails enabled';
export const CRITERIA_COLUMN_TITLE_APPLICANT_STATS_ENABLED =
  'Applicant stats enabled';
export const CRITERIA_COLUMN_TITLE_ACCOUNT_MANAGER = 'Account Manager';

export const CUSTOMER_COMPANY_FORM_TITLE = 'Create an organization';

export const CRITERIA_ERROR_FULLNAMEABSENT = 'Full name is missing';
export const CRITERIA_ERROR_FULLNAMEDUPLICATE = 'Full name is duplicated';
export const CRITERIA_ERROR_SHORTNAMEDUPLICATE = 'Short name is duplicated';
export const CRITERIA_ERROR_ALIASDUPLICATE = 'Aliases are duplicated';
export const CRITERIA_ERROR_MINSIZEABSENT = 'Min size shall not be blank';
export const CRITERIA_ERROR_MAXSIZEABSENT = 'Max size shall not be blank';
export const CRITERIA_ERROR_MINMAXSIZEOVERLAP =
  'Min and max sizes are overlapping';

export const INSTITUTIONS_TAB_UNIVERSITIES = 'Universities';
export const INSTITUTIONS_TAB_UNIVERSITY_LISTS = 'University lists';
export const LIST_TITLE_INPUT_PLACEHOLDER = 'Enter the list name';
export const INSTITUTIONS_LIST_TYPEAHEAD_PLACEHOLDER =
  'Type to find an institution';
export const ROLES_LIST_TYPEAHEAD_PLACEHOLDER = 'Type to find a role';

export const MAJORS_TAB_MAJORS = 'Majors';
export const MAJORS_TAB_MAJOR_GROUPS = 'Major groups';

export const ROLES_TAB_ROLES = 'Roles';
export const ROLES_TAB_ROLE_CATEGORIES = 'Role categories';

export const COMPANY_TAB_COMPANIES = 'Companies';
export const COMPANY_TAB_COMPANY_GROUPS = 'Company groups';
export const COMPANY_TAB_COMPANY_SIZES = 'Company sizes';

// customer company form
export const CUSTOMER_COMPANY_NOT_FOUND = 'Company does not exist';
export const CUSTOMER_COMPANY_FORM_GROUP_ORGANIZATION = 'Organization';
export const CUSTOMER_COMPANY_FORM_GROUP_ADMIN = 'Customer admin';
export const CUSTOMER_COMPANY_FORM_GROUP_APPLICANT = 'Applicant settings';
export const CUSTOMER_COMPANY_FORM_GROUP_LICENSE = 'License settings';
export const CUSTOMER_COMPANY_FORM_GROUP_PIPL = 'PIPL Settings';
export const CUSTOMER_COMPANY_FORM_GROUP_MATCH_MINER = 'Match Miner';
export const CUSTOMER_COMPANY_FORM_GROUP_MATCH_SCOUT = 'Match Scout';
export const CUSTOMER_COMPANY_FORM_GROUP_ATS = 'ATS Settings';
export const CUSTOMER_COMPANY_FORM_GROUP_ATS_DESC =
  'If there is more than one status, add them separated by comma';
export const CUSTOMER_COMPANY_FORM_SUBGROUP_ATS_PULL = 'Pull jobs in statuses';
export const CUSTOMER_COMPANY_FORM_SUBGROUP_ATS_CLOSE =
  'Close jobs in statuses';
export const CUSTOMER_COMPANY_LABEL_NAME = 'Organization name';
export const CUSTOMER_COMPANY_LABEL_EMAIL = 'Organization email';
export const CUSTOMER_COMPANY_LABEL_ACCOUNT_MANAGER = 'Account manager';
export const CUSTOMER_COMPANY_LABEL_ATS_TYPE = 'ATS type';
export const CUSTOMER_COMPANY_LABEL_ADMIN_FIRSTNAME = 'Admin first name';
export const CUSTOMER_COMPANY_LABEL_ADMIN_LASTNAME = 'Admin last name';
export const CUSTOMER_COMPANY_LABEL_ADMIN_EMAIL = 'Admin email';
export const CUSTOMER_COMPANY_LABEL_ADMIN_PASSWORD = 'Admin password';
export const CUSTOMER_COMPANY_LABEL_TRIAL_DAYS = 'Trial period days';
export const CUSTOMER_COMPANY_LABEL_TRIAL_EXPIRATION_DATE =
  'Trial expiration date';
export const TRIAL_EXPIRED = 'Trial expired';
export const TRIAL_EXPIRED_FULL_REQUESTED = 'Trial expired - full requested';
export const TRIAL_ACTIVE = 'Trial active';
export const TRIAL_ACTIVE_FULL_REQUESTED = 'Trial active - full requested';
export const TRIAL_FULL = 'Full';
export const CUSTOMER_COMPANY_LABEL_PIPL_ENABLED = 'PIPL enabled';
export const CUSTOMER_COMPANY_LABEL_EMAILS_ENABLED = 'Emails to applicants';
export const CUSTOMER_COMPANY_LABEL_PROCESS_OLD_JOBS =
  'Process and close old jobs as well';
export const CUSTOMER_COMPANY_LABEL_STATS_ENABLED =
  'Statistics to the applicant portal';
export const CUSTOMER_COMPANY_LABEL_ENRICH_ROLES = 'Min role for enrichment';
export const CUSTOMER_COMPANY_LABEL_MM_STATUS = 'Match Miner Status';
export const CUSTOMER_COMPANY_LABEL_MS_STATUS = 'Match Scout Status';
export const CUSTOMER_COMPANY_FORM_ACTION_CREATE = 'Create';
export const CUSTOMER_COMPANY_FORM_ACTION_SAVE = 'Save';
export const CUSTOMER_COMPANY_FORM_ACTION_CANCEL = 'Cancel';
export const CUSTOMER_COMPANY_FORM_ACTION_CLOSE = 'Close';
export const BILLING_REPORT_DATE_FROM_LABEL = 'From date';
export const BILLING_REPORT_DATE_TO_LABEL = 'To date';
export const BILLING_REPORT_SECTION_LABEL = 'Billing report';
export const BILLING_REPORT_SUBMIT_BUTTON_TEXT = 'Export report';

export const CUSTOMER_COMPANY_LICENSE_REQUESTED = 'Full Version Requested:';
export const CUSTOMER_COMPANY_MM_REQUESTED = 'MM Last Requested';
export const getCustomerCompanyIndexationConfirmation = (companyName: string) =>
  `Are you sure you want to clear indexation results for ${companyName.toUpperCase()} on ${ENV_NAME}?`;

export const CUSTOMER_COMPANY_LABEL_DEFAULT_STATUSES = 'Default statuses';
export const CUSTOMER_COMPANY_LABEL_CUSTOM_STATUSES = 'Custom statuses';
export const CUSTOMER_COMPANY_LABEL_STATUSES = 'Statuses';

export const getCustomerCompanySaveSuccessMessage = (
  companyName: string,
  isNew?: boolean
) => {
  return isNew
    ? `${companyName.toUpperCase()} was successfully created on ${ENV_NAME}`
    : `Changes for ${companyName.toUpperCase()} were successfully saved on ${ENV_NAME}`;
};

export const CUSTOMER_COMPANY_MISSING_PASSWORD = 'Missing password';

export const CUSTOMER_COMPANY_LOGO_UPLOAD = 'Upload logo';
export const CUSTOMER_COMPANY_CREATED = 'Created: ';
export const CUSTOMER_COMPANY_UPDATED = 'Last updated: ';
export const CUSTOMER_COMPANY_ACCOUNT_MANAGERS = 'Account managers';
export const CUSTOMER_ENRICHMENT_ROLES_HINT =
  'Roles from most powerful to least powerful: ROLE_ADMIN_PORTAL > ROLE_SYSTEM_ADMIN > ROLE_CUSTOMER_ADMIN > ROLE_RECRUITER > ROLE_APPLICANT';

export const FORM_ERROR_MISSING = 'Missing';
export const FORM_ERROR_INVALID = 'Invalid value';

export const getTableItemsAmountMessage = (
  currentAmount?: number,
  total?: number
) => {
  // currentAmount of shown items can be bigger than total from back-end because
  // currentAmount may include manually added items by user not sent to back-end yet
  return `Showing ${currentAmount} of ${total} items`;
};

export const NOT_SUPPORTED_FILE_ERROR = 'This file is not supported.';

export const CUSTOMER_COMPANY_INDEXED_CANDIDATES = 'Indexed Candidates';
export const CUSTOMER_COMPANY_DROP_INDEXED_ACTION = 'Clear';
export const CUSTOMER_COMPANY_ACTION_CLEAR_INDEXED = 'Clear indexation results';
export const CUSTOMER_COMPANY_REMOVE_SUCCESS =
  'Company was successfully removed.';
export const CUSTOMER_COMPANY_INDEXATION_ERROR = 'Unable to clear indexation';

export const CUSTOMER_COMPANY_DELETE_KEYWORD = 'DELETE';
export const CONFIRM_LEAVE_PAGE =
  'Are you sure you want to leave this page? Your changes will be lost.';
export const getCustomerCompanyRemoveConfirmation = (companyName: string) =>
  `Are you sure you want to delete ${companyName.toUpperCase()} on ${ENV_NAME}?\nIf yes, enter ${CUSTOMER_COMPANY_DELETE_KEYWORD} into the input below`;
export const CUSTOMER_COMPANY_REMOVE_ACTION = 'Delete the organization';
export const CUSTOMER_COMPANY_CONFIRM_ACTION = 'Delete';
export const CUSTOMER_COMPANY_INDEXATION_SUCCESS = 'Indexation was cleared';
export const CUSTOMER_COMPANY_REMOVE_ACTION_ERROR = 'Unable to remove company.';

export const NO_RESULTS = 'No results found';
