import React from 'react';
import { NavLink, RouteProps } from 'react-router-dom';

import { NAVIGATION } from 'constants/navigation';

// imports from styles
import styles from './LeftPanel.css';

type Props = RouteProps;

export const LeftPanel: React.FC<Props> = () => {
  return (
    <div className={styles.leftPanel}>
      <div className={styles.navigation}>
        {NAVIGATION.map((item) => {
          return (
            <NavLink
              key={item.text}
              to={item.to}
              className={styles.navigationLink}
              activeClassName={styles.selected}
            >
              {item.text}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

LeftPanel.displayName = 'LeftPanel';
