import { DictionaryContextT } from 'context';

export const items = <T>(state: DictionaryContextT<T>) => state.items;
export const isLoading = <T>(state: DictionaryContextT<T>) => state.isLoading;
export const updatedItems = <T>(state: DictionaryContextT<T>) =>
  state.updatedItems;
export const total = <T>(state: DictionaryContextT<T>) => state.total;
export const sortField = <T>(state: DictionaryContextT<T>) => state.sortField;
export const sortOrder = <T>(state: DictionaryContextT<T>) => state.sortOrder;
export const companyManagers = <T>(state: DictionaryContextT<T>) =>
  state.companyManagers;
