import R from '@air/third-party/ramda';
import { transformFileName, wrapInQuotes } from '@air/utils/strings';
import { CompanyEmployeesInfo, CompanyRevenueInfo } from '@air/api';

/* common */
export const AND = 'and';

/* Left Sidebar */

export const UTILITIES_HEADER = 'Utilities';
export const JOB_DESCRIPTION_BUTTON_TEXT = 'Job description';
export const UPLOADED_FILES_BUTTON_TEXT = 'Upload Resume';
export const GENERATE_REPORT_BUTTON_TEXT = 'Generate report';
export const DISABLED_REPORT_CONTROL_BUTTON_TOOLTIP =
  'The reporting capability is not available when the trial is expired';

/*
  Kanban FeatureView headers.
*/

export const DRAFT_ATS_JOBS_CONFIRMATION_TEXT =
  'Applied changes and the draft will be removed';
export const SCREENING_CLOSED_CONFIRMATION_TEXT =
  'The screening will be closed';
export const CLOSED_SCREENING_CONFIRMATION_TEXT =
  'The screening will be started again';
export const UNDO_CONFIRMATION_BUTTON = 'Undo';
export const UNDO_ACTION_UNDONE = 'Action Undone';
export const UNDO_ACTION_SCHEDULED = 'Callback Scheduled';

/* Job Requisition */
export const TOTAL = 'Total';
export const LINEUP_TAB_ACTIVE_TITLE = 'Applied';
export const LINEUP_TAB_PASSIVE_TITLE = 'Passive';
export const LINEUP_TAB_MATCH_MINER_TITLE = 'MatchMiner';
export const LINEUP_TAB_MATCH_SCOUT_TITLE = 'MatchScout';
export const LINEUP_TAB_MATCH_MINER_TITLE_SHORT = 'MMiner';
export const LINEUP_TAB_MATCH_SCOUT_TITLE_SHORT = 'MScout';
export const JOB_DESCRIPTION_DEFAULT_TITLE = 'Job Description';
export const READONLY_NO_DESCRIPTION_PLACEHOLDER =
  'There is no job description provided';
export const EDITABLE_NO_DESCRIPTION_PLACEHOLDER =
  'Type your job description here';
export const REQUISITION_OWNER_PREFIX = 'ATS Job Owner';
export const REQUISITION_CREATOR_PREFIX = 'by';
export const getRequisitionOwnersLabel = (label: string, count: number) =>
  `${label}${count ? `s` : ''}:`;
export const getRestRequisitionOwnersLabel = (count: number) =>
  `${count ? `+${count} more` : ''}`;

/* Navbar buttons */
export const SETTINGS_BUTTON_LABEL = 'Settings';
export const HELP_CENTER_BUTTON_LABEL = 'Help center';
export const WALLPAPERS_BUTTON_LABEL = 'Wallpapers';

/* Settings */
export const SETTINGS_HEADER = 'Settings';

/* User Management */
export const USER_MANAGEMENT_TAB = 'User Management';
export const USER_MANAGEMENT_NO_USERS_PLACEHOLDER = 'No users yet';
export const USER_MANAGEMENT_ALL_USERS_PLACEHOLDER = 'All users';
export const USER_MANAGEMENT_NO_USERS_FOUND = (search: string) =>
  `No ${search} user was found`;

/* General */
export const GENERAL_TAB = 'General';
export const GENERAL_DEFAULT_SPECIALIZATION_TITLE = 'Default specialization';
export const GENERAL_DEFAULT_SPECIALIZATION_DESCRIPTION =
  'The selected specialization will be applied automatically when you create the job. The specialization adjusts the criteria, red flags, and dictionary items available for this job.';
export const GENERAL_JOB_SPECIALIZATION_LABEL = 'Default job specialization';

/* Help Center */
export const HELP_CENTER_HEADER = 'Help Center';
export const HELP_CENTER_ON_BOARDING_TITLE = 'Onboarding';
export const HELP_CENTER_ON_BOARDING_TEXT =
  'Do you want to watch onboarding again? Restart to explore our functionality again!';
export const HELP_CENTER_RESTART_ON_BOARDING_BUTTON_TEXT = 'Restart onboarding';
export const HELP_CENTER_USER_GUIDE_TITLE = 'User Guide';
export const HELP_CENTER_USER_GUIDE_TEXT =
  'Our knowledge library that includes descriptions of almost all functions';
export const HELP_CENTER_USER_GUIDE_BUTTON_TEXT = 'Open guide';
export const HELP_CENTER_MESSAGE_TITLE =
  'Have a question or some thoughts to share?';
export const HELP_CENTER_MESSAGE_TEXT =
  'Send us a message - we will get back to you shortly.';
export const HELP_CENTER_MESSAGE_LABEL = 'Your question or feedback';
export const HELP_CENTER_MESSAGE_PLACEHOLDER = 'Type in your message here';
export const HELP_CENTER_SEND_MESSAGE_BUTTON_TEXT = 'Send message';
export const HELP_CENTER_SENDING_BUTTON_TEXT = 'Sending ...';
export const HELP_CENTER_UPLOAD_FILE_BUTTON_TEXT = 'Upload file attachment';
export const HELP_CENTER_FILE_SIZE_LIMIT_TEXT = 'Files size limit:';
export const HELP_CENTER_SUCCESS_SUBMIT_TITLE = 'Thank you!';
export const HELP_CENTER_SUCCESS_SUBMIT_TEXT =
  'Your message has been successfully sent to the team. We appreciate your interest in our product. We will contact you by email shortly.';
export const HELP_CENTER_SUCCESS_SUBMIT_BUTTON_TEXT = 'One more question';
export const HELP_CENTER_CLOSE_CONFIRMATION_TEXT =
  'Are you sure? It seems like you wanted to send a question.';
export const HELP_CENTER_SUBMIT_ERROR =
  'Error while submitting your message. Please, try again.';

export const SEARCH_FILTER_PLACEHOLDER = 'Search...';
export const SEARCH_FILTER_PLACEHOLDER_FILTER = 'Type to filter';
export const OWN_SEARCHES_FILTER = 'My jobs only';
export const MY_INDICATOR = 'my';

export const EMPTY_SIDELIST_PLACEHOLDER = 'NO RESULTS FOUND';
export const ONBOARDING_STEP_1_TITLE =
  'Create accurate search criteria for jobs';
export const ONBOARDING_STEP_1_DESCRIPTION_1 =
  'Create job drafts and add criteria of various types: skills, roles, education and others.';
export const ONBOARDING_STEP_1_DESCRIPTION_2 =
  'Choose one of three priorities:<br /><strong>Mandatory,</strong> for criteria without which the candidate will be immediately rejected;<br /><strong>Important,</strong> for missed criteria that clarifies experience while a mismatch leads to rejected for both priorities;<br /><strong>Optional</strong> which is a nice-to-have criteria.';
export const ONBOARDING_STEP_1_DESCRIPTION_3 =
  'Also, you can configure <strong>Red Flags</strong> to automatically filter out candidates with specific undesired patterns.';

export const ONBOARDING_STEP_1_DESCRIPTION_ARRAY = [
  ONBOARDING_STEP_1_DESCRIPTION_1,
  ONBOARDING_STEP_1_DESCRIPTION_2,
  ONBOARDING_STEP_1_DESCRIPTION_3,
];
export const ONBOARDING_STEP_2_TITLE =
  'See the best candidates and hidden gems in your resume database';
export const ONBOARDING_STEP_2_DESCRIPTION =
  "It doesn't matter if you upload the resume or if it's a resume from the ATS, Employa will select the best ones for you based on your search criteria and assign them to the appropriate groups. Then you can select \n" +
  'the ones you are interested in or reject those which are not suitable for you.';
export const ONBOARDING_STEP_3_TITLE =
  'Found suitable candidates?\nLet them know!';
export const ONBOARDING_STEP_3_DESCRIPTION =
  "Send letters and questionnaires to based on groups of candidates. Customize them with our smart templates that adapt to each candidate personally. You don't have to worry about a candidate not responding to you because of a generic email.";

/* Trial */
export const TRIAL_VERSION_LABEL = 'Trial version';
export const getNumberOfDaysLeftMessage = (count: number) =>
  `${count} days left`;
export const getNumberOfDaysLeftToastMessage = (count: number) =>
  `${count} days of your trial left`;
export const TRIAL_LAST_DAY_LEFT = 'The last day left';
export const TRIAL_TOAST_LAST_DAY_LEFT = 'The last day of your trial left';
export const TRIAL_EXPIRED = 'Expired';
export const TRIAL_REQUEST_FULL = 'Request Full Version';
export const TRIAL_SCHEDULE_CALL = 'Schedule a call';
export const TRIAL_EXPIRED_NOTIFICATION = 'Trial expired. View-mode only';
export const TRIAL_REQUESTED_NOTIFICATION = 'Full version requested';
export const TRIAL_FULL_VERSION_REQUEST_ERROR = `We can't process the request, please try again later`;
export const TRIAL_NO_INFO =
  "Can't get license information, please try again later or contact support.";

/*
  Search criteria form.
*/
export const MANDATORY_SECTION_TITLE = 'Mandatory';
export const MANDATORY_SECTION_TITLE_TOOLTIP =
  'Minimum qualifications candidates must have in their resume';
export const IMPORTANT_SECTION_TITLE = 'Important';
export const IMPORTANT_SECTION_TITLE_TOOLTIP =
  'Qualifications that will be clarified with candidates if missing from their resume';
export const OPTIONAL_SECTION_TITLE = 'Optional';
export const OPTIONAL_SECTION_TITLE_TOOLTIP =
  'Additional qualifications that are a plus';
export const MANDATORY_CRITERIA_ICON_TITLE = 'Mandatory criteria';
export const IMPORTANT_CRITERIA_ICON_TITLE = 'Important criteria';
export const OPTIONAL_CRITERIA_ICON_TITLE = 'Optional criteria';
export const MANDATORY_CRITERIA_LABEL = 'Mandatory';
export const IMPORTANT_CRITERIA_LABEL = 'Important';
export const OPTIONAL_CRITERIA_LABEL = 'Optional';

export const NO_NAME_CRITERIA = 'No name';
export const NO_LOCATION_CARD_LABEL = 'Location'; // e.g. 'Location too far', 'Location missing' etc
export const OPENED_IMPORTANCE_SECTION_LABEL = 'Set priority';
export const SKILL_CRITERIA_TOP_LABEL_SINGULAR = 'Skill';
export const SKILL_CRITERIA_TOP_LABEL_PLURAL = 'Skills';
export const SKILLS_CARD_TOOLTIP_SUBTITLE = 'Equivalent skills:';
export const SKILLS_CARD_TOOLTIP_NO_RESULTS =
  'No equivalent skills found in our database.';
export const ROLE_CRITERIA_TOP_LABEL_SINGULAR = 'Role';
export const ROLE_CRITERIA_TOP_LABEL_PLURAL = 'Roles';
export const RETURN_FROM_PRIORITY_SETTING_BUTTON = 'Back';
export const ANY_MAJOR_LABEL = 'Any Major';
export const MAJOR_LABEL = 'Major';
export const MAJOR_CRITERIA_TOP_LABEL_SINGULAR = 'Major';
export const MAJOR_CRITERIA_TOP_LABEL_PLURAL = 'Majors';
export const ANY_INSTITUTION_LABEL = 'Any University';
export const INSTITUTION_LABEL = 'University';
export const INSTITUTION_CRITERIA_TOP_LABEL_SINGULAR = 'University';
export const INSTITUTION_CRITERIA_TOP_LABEL_PLURAL = 'Universities';
export const ANY_DEGREE_LABEL = 'Any Degree';
export const DEGREE_LABEL = 'Degree';
export const DEGREE_CRITERIA_TOP_LABEL_SINGULAR = 'Degree';
export const DEGREE_CRITERIA_TOP_LABEL_PLURAL = 'Degrees';
export const CERTIFICATION_CRITERIA_TOP_LABEL_SINGULAR = 'Certification';
export const CERTIFICATION_CRITERIA_TOP_LABEL_PLURAL = 'Certifications';
export const INDUSTRY_CRITERIA_TOP_LABEL_SINGULAR = 'Industry';
export const INDUSTRY_CRITERIA_TOP_LABEL_PLURAL = 'Industries';
export const COMPANY_CRITERIA_TOP_LABEL_SINGULAR = 'Company';
export const COMPANY_CRITERIA_TOP_LABEL_PLURAL = 'Companies';
export const LOCATION_CRITERIA_TOP_LABEL_SINGULAR = 'Location';
export const LOCATION_CRITERIA_TOP_LABEL_PLURAL = 'Locations';
export const MANAGERIAL_CRITERIA_TOP_LABEL = 'Management';
export const PROFESSIONAL_CRITERIA_TOP_LABEL = 'Work XP';
export const QUESTION_CRITERIA_TOP_LABEL = 'Question';
export const GET_US_ONLY_COMPANY_TITLE = (label: string) => `U.S. ${label}`;

export const UNWANTED_LABEL = 'Unwanted';
export const UNWANTED_GROUP_LABEL = 'Unwanted Group';
export const NOT_FOUND_LABEL = 'Not found';
export const NOT_FOUND_GROUP_LABEL = 'Group not found';
export const NOT_RECENT_LABEL = 'Not recent';
export const NOT_RECENT_GROUP_LABEL = 'Group not recent';
export const GROUP_LABEL = 'Group';
export const RELATED_SKILLS_TOOLTIP =
  'Experience includes related skills such as:';

export const OR_STACK_ITEM_LABEL = 'Or';
export const IDEAL_STACK_ITEM_LABEL = 'Ideal';
export const ACCEPTABLE_STACK_ITEM_LABEL = 'Acceptable';
export const EQUIVALENT_EXPERIENCE_STACK_LABEL = 'Or equivalent';
export const EQUIVALENT_EXPERIENCE_DROPDOWN_LABEL = 'Equivalent Experience';
export const EQUIVALENT_EXPERIENCE_CARD_FOOTER_TEXT = 'Equivalent Experience';
export const EQUIVALENT_EXPERIENCE_YEAR_TEXT = 'year';

export const SEARCH_FORM_TOOLBAR_ADD_SKILL = 'skill';
export const SEARCH_FORM_TOOLBAR_ADD_MAJOR = 'major';
export const SEARCH_FORM_TOOLBAR_ADD_DEGREE = 'degree';
export const SEARCH_FORM_TOOLBAR_ADD_INSTITUTION = 'university';
export const SEARCH_FORM_TOOLBAR_ADD_CERTIFICATION = 'certification';
export const SEARCH_FORM_TOOLBAR_ADD_INDUSTRY = 'industry';
export const SEARCH_FORM_TOOLBAR_ADD_COMPANY = 'company';
export const SEARCH_FORM_TOOLBAR_ADD_LOCATION = 'location';
export const SEARCH_FORM_TOOLBAR_ADD_ROLE = 'role';
export const SEARCH_FORM_TOOLBAR_ADD_MANAGERIAL = 'management';
export const SEARCH_FORM_TOOLBAR_ADD_PROFESSIONAL = 'work xp';
export const SEARCH_FORM_TOOLBAR_ADD_QUESTION = 'question';

export const SEARCH_FORM_BLOCKER_JUST_SECOND = 'Just a second...';
export const SEARCH_FORM_BLOCKER_HEADER = 'Did you know?';
export const SEARCH_FORM_BLOCKER_TEXT =
  'We can assess applicants much faster than human recruiters could do! But it still takes a little time to analyze them all and suggest the best fit based on your criteria.';

export const EXPERIENCE_MANAGERIAL_TITLE_PLACEHOLDER = 'Managerial Experience';
export const EXPERIENCE_PROFESSIONAL_TITLE_PLACEHOLDER = 'Work Experience';
export const EXPERIENCE_IT_PROFESSIONAL_TITLE_PLACEHOLDER = 'IT Experience';

export const EXPERIENCE_ANY_JOB_TYPE_PLACEHOLDER = 'ANY JOB TYPE';
export const EXPERIENCE_IT_JOBS_ONLY_PLACEHOLDER = 'IT JOBS ONLY';

export const COMPANY_LOCATION_WORLDWIDE_PLACEHOLDER = 'WORLDWIDE';
export const COMPANY_LOCATION_US_ONLY_PLACEHOLDER = 'U.S. ONLY';

export const CRITERIA_CARD_PEOPLE_LABEL = 'people';
export const CRITERIA_CARD_PEOPLE_MANAGED_TITLE = 'people\nmanaged';

export const MAX_DISTANCE_INFO_LINE_TEXT = 'miles';
export const DISTANCE_TO_CRITERIA_LOCATION_INFO_LINE_TEXT = 'mi away';

export const RECENT_EXPERIENCE_RECENTLY_LABEL = 'recently';

export const LOCATION_ERROR_FETCHING_GOOGLE_PREDICTIONS =
  'Error fetching locations';
export const LOCATION_ERROR_FETCHING_GOOGLE_PLACE_DETAILS =
  'Error fetching location details';
export const COMPANY_SIZE_ERROR_FETCHING =
  'Error fetching list of company sizes';

export const OPTION_IS_NOT_FOUND = (inputValue: string) =>
  `<span>We can't find <b>${inputValue}</b></span></br>Make sure your search is spelled correctly.`;

export const LINEUP_CRITERIA_DROPDOWN_LABEL = 'Visible Criteria';
export const LINEUP_WATCHING_DROPDOWN_LABEL = 'Watching';
export const LINEUP_DISABLED_CRITERIA_TOOLTIP =
  'There are no criteria of this type.';
export const LINEUP_DISABLED_IMPORTANCE_TOOLTIP =
  'There are no criteria of this priority.';
export const LINEUP_TAB_SKILLS = 'Skills';
export const LINEUP_TAB_EDUCATION = 'Education';
export const LINEUP_TAB_EXPERIENCE = 'Experience';
export const LINEUP_TAB_COMPANY = 'Company';
export const LINEUP_TAB_LOCATION = 'Location';
export const LINEUP_TAB_QUESTION = 'Questions';

export const LINEUP_TABS_SORT_BEST_MATCHING_LABEL = 'Best matching';
export const LINEUP_TABS_SORT_RECENTLY_ADDED_LABEL = 'Recently added';
export const LINEUP_TABS_SORT_ALPHABETICAL_ORDER_LABEL = 'Alphabetical order';
export const LINEUP_TABS_SORT_TOOLTIP = 'Sort';

/* MATCH MINER */

export const MATCH_SERVICE_CLOSED_TITLE_NO_RESULTS = 'No retrieved candidates';
export const MATCH_SERVICE_CLOSED_TITLE_NO_RESULTS_DESC =
  'The search has not been initiated for this job, so no candidates are shown here. You can initiate the search only for jobs in screening status';

export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS = 'No suitable candidates';
export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_START_STANDALONE =
  'Once suitable candidates according to your search are found, they will appear here. You still can ';
export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_START =
  'Once suitable candidates according to your search are found, they will appear here. You still can add applicants to the position via the ATS or ';
export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_END_STANDALONE =
  ' your search criteria or upload resumes for screening in the <b>“Active”</b> tab.';
export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_ACTION = 'modify';
export const MATCH_SERVICE_SEARCHED_TITLE_NO_RESULTS_DESC_END =
  ' your search criteria';
export const MATCH_MINER_TAB_TOOLTIP =
  'Individuals from your CV database that are qualified for the position.';
export const MATCH_SCOUT_TAB_TOOLTIP =
  'Individuals from Employa database that are qualified for the position.';
export const MATCH_SERVICE_START_SEARCH = 'Start Search';
export const NO_APPLICANTS_MATCH_MINER_NO_SKILLS =
  'Let’s start digging out the gems from your candidate database! MatchMiner search will review candidates based on your current search criteria.';
export const NO_APPLICANTS_MATCH_MINER =
  'Let’s start digging out the gems from your candidate database! Search will be based on your current search criteria.';
export const NO_APPLICANTS_MATCH_SCOUT = `Let’s start sourcing from Employa candidates base! Search will be based on your current search criteria.`;
export const NO_APPLICANTS_NO_PRIORITY_CRITERIA = `Skill or Role with Mandatory or Important priority`;
export const NO_APPLICANTS_NO_SKILL_CRITERIA = `At least one skill or role that is not 'Unwanted'`;
export const NO_APPLICANTS_MATCH_MINER_NO_SKILLS_DESCRIPTION = [
  'Please',
  'modify your criteria',
  'to the following requirements',
  NO_APPLICANTS_NO_SKILL_CRITERIA,
  NO_APPLICANTS_NO_PRIORITY_CRITERIA,
];
export const NO_APPLICANTS_MATCH_SCOUT_NO_SKILLS_HEADER =
  'To start the search, add the criteria:';
export const NO_APPLICANTS_MATCH_SCOUT_DESCRIPTION_NOTE = [
  'Please note: we are sourcing',
  'tech candidates only.',
];
export const NO_APPLICANTS_MATCH_SCOUT_DESCRIPTION_NOTE_TOOLTIP =
  'Tech candidates refer to IT specialists with programming language skills.';
export const MATCH_SCOUT_START_GO_TO_CRITERIA = 'Go to criteria';
export const MATCH_MINER_REQUEST_GENERAL_DESCRIPTION =
  'The recruiting process collects many resumes from great candidates, only for them to get lost and discarded after the job closes. MatchMiner combs through the best candidates in your database, including prior applicants, and converts them into new hires!';
export const MATCH_SCOUT_REQUEST_GENERAL_DESCRIPTION =
  'This add-on will allow you to find new candidates within the Employa database. Please note: at the moment we are focused on tech candidates.';

export const MATCH_SERVICE_GET_ACCESS = 'Request\naccess';
export const MATCH_MINER_GET_ACCESS_DESC =
  'To request MatchMiner, please contact your administrator.';
export const MATCH_SCOUT_GET_ACCESS_DESC =
  'To request MatchScout, please contact your administrator.';

export const MATCH_SERVICE_ACCESS_REQUESTED = 'Access\nrequested';
export const MATCH_MINER_ACCESS_REQUESTED_DESC_START =
  'MatchMiner was requested successfully.';
export const MATCH_SCOUT_ACCESS_REQUESTED_DESC_START =
  'MatchScout was requested successfully.';
export const MATCH_SERVICE_ACCESS_REQUESTED_DESC_END =
  'Our sales team will contact you soon!';

export const MATCH_SERVICE_SETUP_IN_PROGRESS = 'Set up\nin progress';
export const getMatchMinerSetupProgressDesc = (amount?: number) => {
  return `<strong>${amount}</strong> candidate${
    amount === 1 ? '' : 's'
  } have been processed.\nIt may take some time to process the rest.`;
};
export const MATCH_SERVICE_SETUP_FAILED = 'Set up\nfailed';
export const MATCH_SERVICE_SETUP_FAILED_DESC =
  'Something went wrong. We were unable to process the candidates. Please ';
export const MATCH_SERVICE_SETUP_FAILED_DESC_ACTION = 'contact us';

export const MATCH_MINER_INACTIVE = 'MatchMiner is currently inactive.';
export const MATCH_SCOUT_INACTIVE = 'MatchScout is currently inactive.';
export const MATCH_SERVICE_INACTIVE_RECRUITER =
  'Contact your administrator to request it.';
export const MATCH_SERVICE_INACTIVE_ADMIN = ' to activate it again.';
export const MATCH_SERVICE_INACTIVE_ADMIN_ACTION = 'Request';

export const MATCH_MINER_NO_CRITERIA_TITLE = `No criteria to show`;
export const MATCH_MINER_NO_CRITERIA_DESCRIPTION = `No criteria columns to show due to visible criteria configuration.`;
export const MATCH_MINER_UPDATED_TOOLTIP = [
  `Since the start of the initial screening you've mined `,
  'candidates in total.',
];

/* END MATCH MINER */

/* START WATCHERS */
export const NO_WATCHING_USERS = 'No watching users yet.';
export const WATCHING_USERS_SUBTITLE = 'Watching users';
export const WATCHING_ACTION = 'Watch for updates';
export const WATCHING_DESCRIPTION =
  'Employa will send you email updates about new matched and pending candidates.';
export const ADD_WATCHERS_ACTION = 'Add Watchers';
export const NO_WATCHERS_FOUND_MESSAGE = 'No users match your search';
export const UNSUBSCRIBE_WATCHERS_HEADER = 'Unsubscribed';
export const UNSUBSCRIBE_WATCHERS_DESCRIPTION_START =
  'You will no longer receive emails for the ';
export const UNSUBSCRIBE_WATCHERS_DESCRIPTION_END =
  '. To receive emails again, go to the position page and add yourself in the Watcher settings.';

/* END WATCHERS */

export const ACTIVE_TAB_TOOLTIP =
  'Candidates that are interested in and have applied for the position.';
export const PASSIVE_TAB_TOOLTIP =
  'Potential candidates manually uploaded by you or another user for further screening.';
export const NO_APPLICANTS_EMPLOYA_ATS_PLACEHOLDER =
  'Once candidates are found, they will appear here. You can upload resumes for screening or find passive candidates in <b>"MatchMiner"</b> tab. ';
export const NO_APPLICANTS_EXTERNAL_ATS_PLACEHOLDER =
  'Once candidates are retrieved from the ATS, they will appear here. You can also search for passive candidates in the <b>“MatchMiner”</b> tab. ';

export const NO_APPLICANTS_TITLE_PASSIVE_STANDALONE = 'No uploaded candidates';
export const NO_APPLICANTS_DESC_PASSIVE_STANDALONE_SCREENING =
  'Once passive candidates are uploaded, they will appear here. Or you can also find passive candidates using the <b>“MatchMiner”</b> tab.';
export const NO_APPLICANTS_DESC_PASSIVE =
  'No one was found for this position.\nTo find them you can restart the screening process.';
export const NO_APPLICANTS_DESC_PASSIVE_STANDALONE_PAUSED =
  'No one was uploaded to this position.\nTo upload them you can restart the screening process.';

export const LINEUP_PROCESSING_APPLICANTS_PLACEHOLDER =
  'Currently processing applicants, and the best fit will appear here shortly';
export const LINEUP_PAUSED_APPLICANTS_PLACEHOLDER =
  'Screening is paused. The applicants will not be processed until screening is resumed';
export const LINEUP_CLOSED_APPLICANTS_PLACEHOLDER =
  'Screening is completed. The applicants will not be processed until screening is resumed';
export const LINEUP_EMPTY_SEARCH_PLACEHOLDER = (query: string) =>
  `No ${query} applicant was found`;

export const NO_APPLICANTS_STATE_TITLE = 'No candidates found yet';

export const CANDIDATE_PROFILE_CRITERIA_TAB_MATCHING = 'matching';
export const CANDIDATE_PROFILE_CRITERIA_TAB_CRITERIA = 'criteria';

export const CANDIDATE_STATUS_UPLOAD_LABEL = 'Upload';
export const CANDIDATE_STATUS_ACTIVE_LABEL = 'Matched';
export const CANDIDATE_STATUS_PENDING_LABEL = 'Pending';
export const CANDIDATE_STATUS_RECOMMENDEDFORREJECTION_LABEL = 'Rejected';
export const CANDIDATE_STATUS_MANUALSHORTLIST_LABEL = 'Selected';
export const CANDIDATE_STATUS_MANUALREJECTION_LABEL = 'Rejected Manually';
export const CANDIDATE_STATUS_ATSFAILEDTOPARSE_LABEL = 'Other';
export const CANDIDATE_STATUS_NOTPROCESSED_LABEL = 'Processing';

export const getExternalAtsTooltip = (atsName: string) =>
  `Open candidate page in ${atsName}.`;
export const CANDIDATE_PROFILE_NOTE_PLACEHOLDER = 'Leave a note';

export const CANDIDATE_STATUS_TOGGLE_MOVE_TO_APPLIED = 'Move to applied';
export const CANDIDATE_STATUS_TOGGLE_MOVE_TO_PASSIVE = 'Move to passive';
export const getMovingToAppliedNotification = ({
  fullName,
}: {
  fullName: string;
}) => `Moving ${fullName} to applied...`;
export const getMovingToPassiveNotification = ({
  fullName,
}: {
  fullName: string;
}) => `Moving ${fullName} to passive...`;

export const getMovedToAppliedNotification = ({
  fullName,
}: {
  fullName: string;
}) => `${fullName} moved to applied.`;
export const getMovedToPassiveNotification = ({
  fullName,
}: {
  fullName: string;
}) => `${fullName} moved to passive.`;

//CandidateProfile Header
export const CANDIDATE_PROFILE_HEADER_FIRST_NAME_TOOLTIP = 'First name';
export const CANDIDATE_PROFILE_HEADER_LAST_NAME_TOOLTIP = 'Last name';
export const CANDIDATE_PROFILE_PROCESSING_APPLICANT_TEXT =
  'Processing Applicant';
export const OPEN_IN_ATS_TEXT = 'Open in ATS';

// Contacts section
export const CANDIDATE_PROFILE_CONTACTS_PRIMARY_EMAIL = 'PRIMARY EMAIL';
export const CANDIDATE_PROFILE_CONTACTS_SECONDARY_EMAIL = 'SECONDARY EMAILS';
export const CANDIDATE_PROFILE_CONTACTS_EMAIL_PLACEHOLDER =
  'example@example.com';
export const CANDIDATE_PROFILE_NO_CONTACTS_HEADER = 'No contacts found';
export const CANDIDATE_PROFILE_NO_CONTACTS_TEXT_EXTERNAL_ATS =
  'You can add them in your ATS.';
export const CANDIDATE_PROFILE_NO_CONTACTS_TEXT_INTERNAL_ATS =
  'You can add the contacts manually.';
export const CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_LOCATION = 'LOCATION';
export const CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_PHONES = 'PHONES';
export const CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_EMAILS = 'EMAILS';
export const CANDIDATE_PROFILE_CONTACTS_SECTION_LABEL_LINKS = 'LINKS';
export const CANDIDATE_PROFILE_CONTACTS_TOAST_TEXT_COPIED = 'Text copied';
export const CANDIDATE_PROFILE_CONTACTS_INVALID_EMAIL_FORMAT_ERROR =
  'Invalid email format';

export const CANDIDATE_PROFILE_NO_CONTACTS_BUTTON_TEXT = 'ADD CONTACTS';

export const CANDIDATE_PROFILE_UPDATE_ERROR =
  'Could not update candidate profile info';
export const CANDIDATE_PROFILE_TIMELINE_TABS_STANDARD = 'Standard';
export const CANDIDATE_PROFILE_TIMELINE_TABS_GRAPHIC = 'Graphic';
export const CANDIDATE_PROFILE_CONTACTS_EMAILS_MAKE_PRIMARY = 'Make primary';
export const CANDIDATE_PROFILE_CONTACTS_PRIMARY_EMAIL_TOOLTIP =
  'This email will be used for sending emails';
export const CANDIDATE_PROFILE_CONTACTS_EMAILS_ADD_EMAIL_TEXT = 'Add email';
export const CANDIDATE_PROFILE_CONTACTS_SAVE_BUTTON_TEXT = 'Save';
export const CANDIDATE_PROFILE_CONTACTS_DISCARD_BUTTON_TEXT = 'Discard';
export const CANDIDATE_PROFILE_CONTACTS_CONFIRM_MODAL_TEXT = 'Save changes?';
export const CANDIDATE_PROFILE_ADD_CONTACT_BUTTON_RESTRAIN =
  'Please correct or remove invalid values before adding a new one';
export const CANDIDATE_PROFILE_CONTACTS_EDIT_BUTTON_TEXT = 'Edit contacts';
export const CANDIDATE_PROFILE_CONTACTS_CONFIRM_LEAVE_PAGE =
  'Are you sure you want to leave this page? Your changes will be lost.';
export const CANDIDATE_PROFILE_CONTACTS_FIRST_NAME_PLACEHOLDER = 'First name';
export const CANDIDATE_PROFILE_CONTACTS_LAST_NAME_PLACEHOLDER = 'Last name';
export const SHOW_MORE_CONTACT_BUTTON = 'Show more';
export const SHOW_LESS_CONTACT_BUTTON = 'Show less';

export const WORK_TIMELINE_UNKNOWN_COMPANY = 'Undefined Company';
export const WORK_TIMELINE_UNKNOWN_ROLE = 'Undefined Role';
export const WORK_TIMELINE_UNKNOWN_EXPERIENCE = 'Undefined Experience';
export const WORK_TIMELINE_TOP_TITLE_PRESENT = 'Now';
export const WORK_TIMELINE_TOP_TITLE_NO_HISTORY = 'No work history found';
export const WORK_TIMELINE_TOTAL_WORK_EXPERIENCE = 'Total Exp';
export const WORK_TIMELINE_GAP = 'Employment gap';
export const WORK_TIMELINE_TOTAL_WORK_EXPERIENCE_TOOLTIP =
  'Calculated experience is approximate due to missing years or months in employment records';

// buttons
export const MAKE_A_DRAFT_BUTTON = 'Make a draft';
export const START_SCREENING_DRAFT_SECTION_BUTTON = 'Start\nscreening';
export const IMPORT_CRITERIA_DRAFT_SECTION_BUTTON = 'Import criteria';
export const DISABLED_START_SCREENING_BUTTON_TOOLTIP =
  'You need to add at least one criterion to start screening.';
export const CONFIRM_BUTTON = 'Confirm';
export const DISCARD_CHANGES_SEARCH_FORM_BUTTON = 'Discard changes';
export const RESTART_SCREENING_CLOSED_SECTION_BUTTON = 'Restart screening';
export const MODIFY_CRITERIA_INTERVIEW_SECTION_BUTTON = 'Criteria & flags';
export const INTERVIEW_DRAFT_HAS_CHANGES_TOOLTIP_TEXT =
  'Unsaved criteria changes.';
export const PAUSE_SCREENING_INTERVIEW_SECTION_BUTTON = 'Pause screening';
export const RESUME_SCREENING_INTERVIEW_SECTION_BUTTON = 'Resume screening';
export const CLOSE_POSITION_INTERVIEW_SECTION_BUTTON = 'Close position';
export const APPLY_CHANGES_EDIT_INTERVIEW_SECTION_BUTTON = 'Apply changes';
export const BACK_TO_SCREENING_EDIT_INTERVIEW_SECTION_BUTTON =
  'Back to screening';
export const QUESTIONING_APPLICANTS_START_INTERVIEW_SECTION_BUTTON =
  'Send emails';
export const SEND_EMAILS_TEXT = 'Send emails';

// smart template
export const SEND_INVITATIONS_TEMPLATE_TITLE = `Smart Template Settings`;
export const SEND_INVITATIONS_PREVIEW_EMAIL_TITLE = `Email Preview`;
export const SEND_INVITATIONS_PREVIEW_LOADING = `Loading ...`;
export const SEND_INVITATIONS_TEMPLATE_SUB_TITLE = `Smart Template - is an easy way to engage candidates with found criteria in resume.`;
export const SMART_TEMPLATE_JOB_DESCRIPTION_LINK_LABEL = 'Job description link';
export const SMART_TEMPLATE_GREETINGS_LABEL = 'Greeting';
export const SMART_TEMPLATE_GREETINGS_TEXT = `Hi <strong>&#123;applicant_name&#125;</strong>`;
export const SMART_TEMPLATE_WELCOME_TEXT_LABEL = 'Welcome text';
export const SMART_TEMPLATE_WELCOME_TEXT_PLACEHOLDER =
  'It would be good to introduce yourself, add a position name, and provide a catchy company or project description';
export const SMART_TEMPLATE_VALUE_BASED_LABEL =
  'Smart template text (not editable)';
export const SMART_TEMPLATE_VALUE_BASED_TEXT = `
  <p>
    I noticed that you have been working as
    <strong>&#123;title in last company&#125;</strong> in your current
    position at <strong>&#123;last company name&#125;</strong> for
    more than
    <strong>&#123;work experience in last company&#125;</strong>
    year&#123;s&#125;.
  </p>
  <p>
    You seem to have enough years of experience with
    <strong>&#123;matched skills&#125;</strong>.
  </p>
  <p>
    Some "must-have" skills, such as <strong>&#123;skills with lack of info&#125;</strong>,
    are also listed on the resume, with no details provided. I would suggest adding
    more information if you are interested in the position.
  </p>
  <p>
    Please note that I could not find
    <strong>&#123;missed skills&#125;</strong> in your resume.
    Sometimes, such skills are omitted from candidates' resumes, and
    if you have relevant experience, I would suggest adding them
    before submission.
  </p>
`;
export const SMART_TEMPLATE_NOTE_TEXT =
  'Please note: This is example of sending email and some information might be omit if absent in resume.';
export const SMART_TEMPLATE_ERROR_NO_WELCOME_TEXT_AND_LINK =
  'Welcome text and job description link aren’t filled.';
export const SMART_TEMPLATE_ERROR_NO_WELCOME_TEXT =
  'Welcome text isn’t filled.';
export const SMART_TEMPLATE_ERROR_NO_LINK =
  'Job description link isn’t filled.';
export const SMART_TEMPLATE_ERROR_NOT_VALID_LINK =
  'Job description link isn’t valid.';

// enrichment buttons
export const ENRICH_PROFILE_BUTTON = 'Enrich\nprofile';
export const ENRICHING_BUTTON = 'Enriching...';
export const NO_DATA_ENRICHED_BUTTON = 'No data\nenriched';
export const NO_RESPONSE_TRY_LATER_BUTTON = 'No response\ntry later';
export const ENRICHED_BUTTON = 'Enriched';
export const ENRICH_AGAIN_BUTTON = 'Enrich\nagain?';
export const RECENTRLY_ENRICHED_LABEL = 'recently';
export const CANT_BE_ENRICHED_BUTTON = "Can't be\nenriched";
export const ENRICHMENT_DISABLED_FOR_APPLICANT =
  'The enrichment capability is disabled for this application as the applicant’s name or email is missing';
export const ENRICHMENT_TURN_OFF_TOOLTIP_LABEL = `The enrichment capability is turned off for your organization`;
export const ENRICHMENT_DISABLED_FOR_PAUSED_CLOSED_SEARCH =
  'The enrichment capability is not available for closed or paused searches';
export const ENRICHMENT_DISABLED_FOR_EXPIRED_TRIAL =
  'The enrichment capability is not available when the trial is expired';
export const ENRICHMENT_OUTDATED_TOOLTIP_LABEL =
  'Name or primary email address was updated after enrichment. Re-enrichment using the updated information is recommended.';

export const SELECT_PLACEHOLDER = 'Select ...';
export const LOADING_PLACEHOLDER = 'Loading ...';

export const CANDIDATE_PROFILE_EMPTY_FIRST_NAME = 'Unknown';
export const CANDIDATE_PROFILE_EMPTY_LAST_NAME = 'Candidate';
export const CANDIDATE_PROFILE_EMPTY_NAME = 'Unknown Candidate';
export const CANDIDATE_PROFILE_RESUME_TITLE = (fullName?: string) => {
  return `${fullName} resume`;
};
export const CANDIDATE_PROFILE_NO_DOCUMENTS_ATTACHED = 'No resumes attached.';
export const CANDIDATE_PROFILE_LOADING_RESUME_PREVIEW = 'Loading preview ...';
export const PREVIEW_FILE_UNAVAILABLE_START =
  "Couldn't preview file due to unsupported format. Try ";
export const PREVIEW_FILE_UNAVAILABLE_ACTION = 'downloading';
export const PREVIEW_FILE_UNAVAILABLE_END = ' instead.';

export const NO_CRITERIA_HEADER = 'No criteria set yet';
export const NO_CRITERIA_TEXT =
  'Feel free to add the criteria you want the candidates to fit. Or you can use criteria that already exist in another job.';
export const NO_CRITERIA_BUTTON_TEXT = 'Watch Tutorial';
export const IMPORT_CRITERIA_BLOCK_SCREEN_HEADER = 'Importing criteria ...';
export const IMPORT_CRITERIA_BLOCK_SCREEN_TEXT = 'Please wait';
export const IMPORT_CRITERIA_BUTTON_TEXT = 'Import criteria';
export const IMPORTING_CRITERIA_MODAL_CONFIRMATION_TEXT =
  'Import will override current criteria and flags.';
export const IMPORTING_CRITERIA_BUTTON_CONFIRMATION_TEXT = 'Import';
export const IMPORTING_CRITERIA_SELECT_INTERVIEW_TEXT = `Select the job from which you want import to the criteria`;
export const FINISH_IMPORTING_CRITERIA_TOAST_TEXT = 'Criteria imported';
export const USER_GREETING = 'Hello';
export const YOUTUBE_DRAFT_SETUP_TITLE = `Open tutorial video`;

export const JOB_SPECIALIZATION_DROPDOWN_LABEL = 'Specialization';

export const RED_FLAGS_SETTINGS_SECTION_NAME = 'Flags';

export const WARNING_SECTION_NAME = 'Flags';
export const WARNING_ASKED_CARD_ACTION_BUTTON_TEXT = 'Asked';
export const WARNING_UNCLARIFIED_CARD_ACTION_BUTTON_TEXT = 'Unclarified';
export const WARNING_EVALUATE_CARD_ACTION_BUTTON_TEXT = 'Evaluate';
export const WARNING_CARD_LABEL = 'Warning';
export const BACK_BUTTON = 'Back';

// Category labels
export const CATEGORY_SKILLS_LABEL = 'Skills';
export const CATEGORY_WORDINGS_LABEL = 'Wordings';
export const CATEGORY_EMPLOYMENT_LABEL = 'Employment';
export const CATEGORY_EDUCATION_LABEL = 'Education';

export const ACTION_TEXT_ASKED = 'Asked';
export const ACTION_TEXT_UNCLARIFIED = 'Unclarified';
export const ACTION_TEXT_IDEAL = 'Ideal';
export const ACTION_TEXT_ACCEPTABLE = 'Acceptable';
export const ACTION_TEXT_MISMATCH = 'Mismatch';

export const EVALUATE_INFO_TEXT = 'Please evaluate the answer.';
export const ASKED_INFO_TEXT = `Applicant's reply is pending. You may override the status.`;
export const IDEAL_STATUS_INFO_TEXT = `Ideal match of search criteria. You may override the status.`;
export const MISMATCH_STATUS_INFO_TEXT = `Mismatch for search criteria. You may override the status.`;
export const ACCEPTABLE_STATUS_INFO_TEXT = `Acceptable match of search criteria. You may override the status.`;
export const SEARCH_CRITERIA_LABEL_EXPLANATION = `The applicant will match if they have at least one of the skills in this stack.`;
export const WARNING_DISMISS_TEXT = 'Dismiss now';
export const WARNING_CONFIRM_TEXT = 'Confirm now';
export const WARNING_DISQUALIFY_TEXT = 'Disqualify';

export const WARNING_CARD_LABEL_NUMBER_OF_MATCHES = R.textByCount(
  'match',
  'matches'
);
export const WARNING_CARD_LABEL_NUMBER_OF_CASES = R.textByCount(
  'case',
  'cases'
);
export const WARNING_CARD_LABEL_NUMBER_OF_YEARS = R.textByCount(
  'year',
  'years'
);

export const WARNING_CARD_LABEL_JOB_HOPPING = (cases: number, years: number) =>
  `${WARNING_CARD_LABEL_NUMBER_OF_CASES(
    cases
  )}\nin ${WARNING_CARD_LABEL_NUMBER_OF_YEARS(years)}`;
export const PERIOD_CURRENT_YEAR = 'PRSNT\nDAY';

export const PIPL_STACK_LABEL = 'from pipl';
export const PIPL_COMPOSITE_STACK_LABEL = (title: string) =>
  `${title} ${PIPL_STACK_LABEL}`;
export const AT = 'at';
export const GRADUATION_YEAR_LABEL = 'Graduation year';

/* Kanban Sections */
export const KANBAN_SECTION_JOBS = 'ATS Jobs';
export const KANBAN_SECTION_DRAFTS = 'Drafts';
export const KANBAN_SECTION_SCREENING = 'Screening';
export const KANBAN_SECTION_PAUSED = 'Paused';
export const KANBAN_SECTION_CLOSED = 'Closed';
export const KANBAN_CARD_TOOLTIP_MESSAGE_SELECTED = 'Selected Candidates';
export const KANBAN_CARD_TOOLTIP_MESSAGE_MATCHED = 'Matched Candidates';
export const KANBAN_CARD_TOOLTIP_MESSAGE_PENDING = 'Pending Candidates';
export const KANBAN_CARD_TOOLTIP_MESSAGE_REJECTED = 'Rejected Candidates';
export const KANBAN_CARD_TOOLTIP_MESSAGE = 'Candidates';
export const KANBAN_NO_RESULTS = 'You don’t have any jobs yet';
export const KANBAN_NO_RESULTS_DESCRIPTION =
  'Create new job to find the best candidate for your position.';
export const KANBAN_NO_RESULTS_ACTION = 'New Job';
export const KANBAN_CARD_ACTIONS_DESCRIPTION = 'Options';
export const KANBAN_CARD_TRIAL_ACTION_DESCRIPTION =
  'You need the full version to enable the action';
export const KANBAN_ACTION_MOVE_TO_DRAFTS = 'Make a Draft';
export const KANBAN_ACTION_EDIT = 'Edit';
export const KANBAN_ACTION_DUPLICATE = 'Duplicate';
export const KANBAN_ACTION_DISCARD = 'Discard';
export const KANBAN_ACTION_PAUSE = 'Pause';
export const KANBAN_ACTION_REOPEN = 'Reopen';
export const KANBAN_ACTION_RESUME_SCREENING = 'Resume screening';
export const KANBAN_ACTION_CLOSE = 'Close';
export const KANBAN_ACTION_DELETE = 'Delete';
export const KANBAN_ACTION_RESTART_SCREENING = 'Restart screening';

export const DRAFT_DISCARD_CONFIRMATION = 'Discard this draft?';
export const DRAFT_DISCARDED_TOAST_TEXT = 'Draft discarded';
export const KANBAN_MOVE_TO_DRAFTS_SUCCESS = 'Job moved to drafts';
export const KANBAN_MOVE_SCREENING_PAUSED = 'Screening paused';
export const KANBAN_MOVE_SCREENING_CLOSED = 'Screening closed';
export const KANBAN_MOVE_SCREENING_RESUMED = 'Screening resumed';
export const KANBAN_MOVE_SCREENING_RESTARTED = 'Screening restarted';
export const KANBAN_STATS_CONTROL_EXPAND = 'Expand stats';
export const KANBAN_STATS_CONTROL_MINIMIZE = 'Minimize stats';

export const DRAFT_SECTION_HEADER = 'Draft';
export const SCREENING_SECTION_HEADER = 'Screening';
export const PAUSED_SECTION_HEADER = 'Paused';
export const CLOSED_SECTION_HEADER = 'Closed';
export const ATS_JOB_HEADER = 'ATS job';

export const getLoadMoreJobsButtonTitle = (amountOfJobsLeftToLoad: number) => {
  const JOBS = R.textByCount('more job', 'more jobs');
  return `Load ${JOBS(amountOfJobsLeftToLoad)}`;
};

export const JOB_PANEL_NEW_JOB_TITLE = 'New Job';
export const JOB_PANEL_EDIT_JOB_TITLE = 'Editing Job';
export const JOB_PANEL_NEW_DRAFT_TITLE = 'New Draft';
export const JOB_PANEL_JOB_TITLE_LABEL = 'Job Title (required)';
export const JOB_PANEL_NEW_JOB_BUTTON_TEXT = 'Create new job';
export const JOB_PANEL_EDIT_JOB_BUTTON_TEXT = 'Save changes';
export const JOB_PANEL_NEW_DRAFT_BUTTON_TEXT = 'Make a draft';
export const JOB_PANEL_JOB_SPECIALIZATION_LABEL = 'Job specialization';
export const JOB_PANEL_EMPTY_SPECIALIZATIONS_TOOLTIP_TEXT =
  'Specialization adjusts the criteria, red flags, and dictionary items available for this job.';

export const SAVE_WITH_SPECIALIZATION_MODAL_CONFIRM_TEXT =
  'Please Note: Changing the specialization will clear your previously set red flags.';
export const SAVE_WITH_SPECIALIZATION_MODAL_CONFIRM_BUTTON =
  'Change specialization';
/**
 * Standalone ATS: New requisition flow
 */
export const NEW_REQUISITION_START_BUTTON_ONE_LINE = 'New job';
export const NEW_REQUISITION_JOB_TITLE_PLACEHOLDER = 'Requisition name';
export const NEW_REQUISITION_DEFAULT_JOB_TITLE = 'Untitled Requisition';
export const NEW_REQUISITION_JOB_TITLE_HINT =
  'Type your requisition name and press <b>Enter</b>.';
export const NEW_REQUISITION_SEARCH_CREATED_STATUS = 'Created';
export const NEW_REQUISITION_SEARCH_OPENED_STATUS = 'Opened';
/**
 * User Management
 */
export const USER_CARD_INACTIVE_LABEL = 'Inactive';
export const USER_CARD_ATS_ROLE_LABEL = 'Ats Role';
export const USER_CARD_GENERAL_ROLE_LABEL = 'Role';
export const USER_CARD_RESET_PASSWORD = 'Reset\nPassword';
export const USER_CARD_RESET_PASSWORD_CONFIRMATION = 'Confirm\nreset';
export const USER_CARD_RESET_PASSWORD_SUCCESS = 'Reset link\nsent';
export const USER_CARD_DEACTIVATE_USER = 'Deactivate';
export const USER_CARD_RE_ACTIVATE_USER = 'Re-activate';
export const ROLES_SUPERADMIN = 'Superadmin';
export const ROLES_RECRUITER = 'Recruiter';
export const ROLES_ADMIN = 'Recruiter';
export const ROLES_APPLICANT = 'Applicant';
export const ROLES_ADMIN_PORTAL = 'Admin';
export const ROLES_CUSTOMER_ADMIN = 'Administrator';
export const USER_CURRENT_ROLE = (roleLabel: string) => `You, ${roleLabel}`;
export const ADD_NEW_USER_BUTTON = 'add\nuser';
export const ADD_NEW_USER_LABEL = 'New User';
export const PENDING_LABEL = 'Pending';
export const PENDING_USER_STATUS = 'invalid\ndata';
export const WARNING_USER_STATUS = 'not\ninvited';
export const INVITE_USER_ACTION_TEXT = 'Invite';
export const CANCEL_ACTION_TEXT = 'Remove';
export const EMPTY_USER_PLACEHOLDER = 'Username';
export const INPUT_PLACEHOLDER = 'Type here';
export const FIRST_NAME_LABEL = 'First name';
export const LAST_NAME_LABEL = 'Last name';
export const EMAIL_LABEL = 'Email';
export const EMAIL_LABEL_SET_PRIMARY = 'Set as primary email';
export const ERROR_MISSING_VALUE = 'Missing';
export const ERROR_INCORRECT_VALUE = 'Invalid value';
export const CREATE_USER_REQUEST_SUCCESS = (
  firstName: string,
  lastName: string
) => `${firstName} ${lastName} was successfully invited`;
export const CREATE_USER_REQUEST_WARNING =
  'This user was not created, please try again';
export const EMAIL_ALREADY_EXIST = 'Email already exists';
export const ALREADY_EXIST = 'Already exists';
export const ROLE_FILTER_RECRUITERS = 'Recruiters';
export const ROLE_FILTER_ADMINS = 'Administrators';
export const STATUS_FILTER_ACTIVE_USERS = 'Active users';
export const STATUS_FILTER_INACTIVE_USERS = 'Inactive users';
export const ROLE_FILTER_LABEL = 'Role';
export const STATUS_FILTER_LABEL = 'Status';

export const COMPANY_EMPLOYEES = (employees: CompanyEmployeesInfo) => {
  if (R.isNil(employees)) return null;

  const { current, startingFrom, to } = employees;

  if (current) {
    return `${current} employees`;
  }

  if (startingFrom && to) {
    return `${startingFrom}
    - ${to} employees`;
  }

  if (startingFrom && !to) {
    return `Over ${startingFrom} employees`;
  }

  if (!startingFrom && to) {
    return `Less than ${to} employees`;
  }
  return;
};

export const COMPANY_REVENUE = (
  revenue: CompanyRevenueInfo,
  withRevenue?: boolean
) => {
  if (R.isNil(revenue)) return null;
  const { current, startingFrom, to, currency } = revenue;
  let amount: string;

  if (current) {
    amount = `${new Intl.NumberFormat().format(current)} ${currency}`;
  }

  if (startingFrom && to) {
    amount = `${new Intl.NumberFormat().format(startingFrom)}
      - ${new Intl.NumberFormat().format(to)} ${currency}`;
  }

  if (startingFrom && !to) {
    amount = `Over ${new Intl.NumberFormat().format(startingFrom)} ${currency}`;
  }

  if (!startingFrom && to) {
    amount = `Less than ${new Intl.NumberFormat().format(to)} ${currency}`;
  }

  if (amount) {
    return withRevenue ? `${amount} revenue` : amount;
  }

  return;
};

export const COMPANY_HEADER = 'Company details';
export const COMPANY_INDUSTRY_LABEL = 'industry';
export const COMPANY_LABEL = 'company';
export const COMPANY_LABEL_REVENUE = 'Revenue';
export const COMPANY_LABEL_FOUNDED = 'Founded';

export const CHANGES_REMOVAL_CONFIRMATION = 'Your changes will be lost';
export const REOPEN_SCREENING_CONFIRMATION = 'Reopen this job?';
export const QUESTIONNAIRE_FORM_HEADER = 'Sending Questions';
export const QUESTIONNAIRE_FORM_DESCRIPTION =
  'Applicants added to the chosen groups later will also receive questions.';
export const QUESTIONNAIRE_FORM_SUBMIT_BUTTON = 'Start autosending';
export const QUESTIONNAIRE_FORM_STOP_BUTTON = 'Stop autosending';
export const QUESTIONNAIRE_FORM_SUBMIT_DISABLED_TOOLTIP =
  'No selected candidate groups';

// uploads
export const UPLOADER_BUTTON_TEXT = 'Upload';
export const UPLOADING_TITLE = 'Uploading';
export const UPLOADS_FILES_TITLE = 'Files';
export const UPLOADING_LEAVE_PAGE =
  'Are you sure you want to leave this page? Your changes will be lost.';
export const getMaxFilesAmountError = (maxFilesAmount: number) => {
  return `You can upload no more than ${maxFilesAmount} files per batch.`;
};
export const UPLOADS_EXPLANATION_TEXT =
  'You will be able to start processing applicants CV’s after you start the screening.';
export const UPLOADS_EMPTY_STATE_TEXT = 'Drop your files here.';
export const STARTING_PROCESSING_BUTTON_TITLE = (type: string) =>
  `Upload to ${type}`;
export const STARTING_PROCESSING_DISABLED_BUTTON_TITLE =
  'Select category first';
export const DISABLED_UPLOAD_EXPLANATION_TEXT =
  'Please wait while your previous selection uploads';
export const RESUME_SCREENING_UPLOAD_EXPLANATION_TEXT =
  'Screening is paused at the moment.\nResume it to start uploading.';

// notifications
export const ITEM_NOT_FOUND = 'Item not found';
export const SEARCHES_FETCHING_ERROR = 'Error on fetching available searches';
export const NO_DRAFT_SEARCHES =
  'Could not retrieve draft searches for current interview. Please, try again';
export const ERROR_SEARCH_REOPEN =
  'Error during search reopening. Please, try again.';
export const ERROR_DRAFT_PUBLISHING =
  'Error during draft publishing. Please, try again.';
export const ERROR_INTERVIEW_PAUSE = 'Error during interview pause attempt';
export const ERROR_INTERVIEW_REPUBLISH =
  'Error during interview republish attempt. Please, try again.';
export const ERROR_INTERVIEW_CLOSE = 'Error during interview close attempt';
export const ERROR_JOB_LISTING = 'Error on job list fetching';
export const INTERVIEW_DRAFT_NOT_FOUND =
  'Interview draft not found. It was probably already discarded.';
export const ERROR_DRAFT_COPY_CREATE =
  'Error during draft copy creation. Please, try again';
export const ERROR_INTERVIEW_UPDATE =
  'Error during interview update. Please, try again.';
export const NO_ATS = 'No ATS available';
export const ATS_INFO_TITLE = 'ATS information';
export const ERROR_REQUISITION_CREATE =
  'Error during requisition creation. Please, try again';
export const ERROR_JOB_SPECIALIZATIONS_UPDATE =
  'Error updating job specializations';
export const SUCCESS_JOB_SPECIALIZATIONS_UPDATE = 'Job specializations changed';

// Dropzone and Uploader
export const getFileTooBigError = (fileName: string) =>
  `File ${
    fileName ? wrapInQuotes(transformFileName(fileName)) : ''
  } is too big. Please make sure your file is no more than 10 MB`;
export const getFileTypeError = (fileName: string) =>
  `${
    fileName ? wrapInQuotes(transformFileName(fileName)) : ''
  } file type is incorrect. Please upload PDF, DOC, or DOCX`;

export const getFileDuplicateError = (fileName: string) =>
  `Applicant with resume file ${
    fileName ? wrapInQuotes(fileName) : ''
  } already exists.`;
export const UPLOADER_ERROR =
  'Could not upload resume. Please try again later.';
export const FILE_QUEUED_UPLOAD_INFO = 'File queued';
export const getPercentageUploadedInfo = (progress: number) =>
  `${progress}% uploaded`;
export const SUCCESSFULLY_UPLOADED_INFO = 'Successfully uploaded';
export const REMOVE_ACTION = 'Remove';
export const REMOVING_ACTION = 'Removing';
export const REMOVE_CV_ERROR = 'The CV was not removed, please try again later';
export const TOO_BIG_FILE_ERROR = '10mb limit exceeded';
export const INCORRECT_FILE_TYPE_ERROR = 'Incorrect file type';
export const DUPLICATED_FILE_ERROR = 'Duplicated file';
export const INVALID_FILE_ERROR = 'Invalid file';
export const SOMETHING_WENT_WRONG_ERROR = 'Something went wrong';
export const DRAGGING_CV_INTO_NOT_ALLOWED_AREA =
  'You can’t upload your files to this section';

export const SUGGEST_KEYWORDS = `Suggest keywords`;
export const KEYWORDS_SUGGESTION_REQUESTED = `Suggestion\nRequested`;
export const ADD_ALL_KEYWORDS = 'Add all\nkeywords';
export const NO_KEYWORDS_FOUND = 'No keywords found';

export const DOWNLOAD_RESUME_DEFAULT_NAME = 'Resume';
export const DOWNLOAD_RESUME_INITIAL_TEXT = 'Download';
export const DOWNLOAD_RESUME_IN_PROGRESS_TEXT = 'Downloading';

export const RESUME_TAB_LABEL = 'Resume';
export const WORK_EXPERIENCE_TAB_LABEL = 'Work Experience';
export const MATCHING_TAB_LABEL = 'Matching';
export const CONTACTS_TAB_LABEL = 'Contacts';

export const COMPANY_TYPE_DROPDOWN_HINT =
  'Enter the company name or select group bellow';
export const COMPANY_WAS_SUCCESSFULLY_UPDATED = 'Company settings updated';

export const SEARCH_TABS_CRITERIA = 'Criteria';
export const SEARCH_TABS_FLAGS = 'Flags';
export const FLAGS_TAB_DESCRIPTION =
  'Red flags are designed to give you the heads up if there are any undesired patterns, potentially misleading or missing information in the applicant’s CV.';

export const FLAG_SETTINGS_TITLE_TRIVIAL_SKILLS = 'Trivial skills';
export const FLAG_SETTINGS_TITLE_BUZZWORDS = 'Buzzwords';
export const FLAG_SETTINGS_TITLE_VAGUE_WORDS = 'Vague words';
export const FLAG_SETTINGS_TITLE_EMPLOYMENT_GAPS = 'Employment gaps';
export const FLAG_SETTINGS_TITLE_SKILL_OVERFLOW = 'Skill overflow';
export const FLAG_SETTINGS_TITLE_LONG_TERM_EMPLOYMENT = 'Long-term employment';
export const FLAG_SETTINGS_TITLE_MISSING_MONTHS = 'Missing months';
export const FLAG_SETTINGS_TITLE_JOB_HOPPING = 'Job hopping';
export const FLAG_SETTINGS_TITLE_NO_CAREER_PROGRESS = 'No career progress';
export const FLAG_SETTINGS_TITLE_DIPLOMA_MILL = 'Diploma mill';
export const FLAG_SETTINGS_TITLE_UNKNOWN_UNIVERSITY = 'Unknown university';
export const FLAG_SETTINGS_TITLE_UNKNOWN_COMPANY = 'Unknown company';
export const FLAG_SETTINGS_TITLE_PIPL_EMPLOYMENT_RECORD_DISCREPANCY =
  'Employment discrepancy';
export const FLAG_SETTINGS_TITLE_PIPL_EDUCATION_DISCREPANCY =
  'Education discrepancy';
export const FLAG_SETTINGS_DESCRIPTIONS_TOO_LONG_TITLE =
  'Descriptions too long';
export const FLAG_SETTINGS_DESCRIPTIONS_TOO_LONG_DESC =
  'Too much text in the employment records description';

export const FLAG_SETTINGS_DESC_TRIVIAL_SKILLS =
  'CV contains obvious basic skills';
export const FLAG_SETTINGS_DESC_BUZZWORDS =
  'Applicant overused buzzwords in their CV';
export const FLAG_SETTINGS_DESC_VAGUE_WORDS =
  'Applicant used vague words in their CV';
export const FLAG_SETTINGS_DESC_LONG_TERM_EMPLOYMENT =
  'Applicant worked for an unusually long time in one company';
export const FLAG_SETTINGS_DESC_MISSING_MONTHS =
  'CV seems to have missing months in employment history';
export const FLAG_SETTINGS_DESC_EMPLOYMENT_GAPS =
  "Significant unemployment period(s) found in the applicant's CV";
export const FLAG_SETTINGS_DESC_JOB_HOPPING =
  'The applicant has been changing jobs way too often';
export const FLAG_SETTINGS_DESC_SKILL_OVERFLOW =
  'CV lists an unrealistic amount of skills';
export const FLAG_SETTINGS_DESC_NO_CAREER_PROGRESS =
  'Career downgrade or no increasingly responsible career progression found';
export const FLAG_SETTINGS_DESC_DIPLOMA_MILL =
  "University doesn't have legitimate accreditation";
export const FLAG_SETTINGS_DESC_UNKNOWN_UNIVERSITY =
  "Unable to confirm the university's accreditation";
export const FLAG_SETTINGS_DESC_UNKNOWN_COMPANY =
  'Unable to check the company registration';
export const FLAG_SETTINGS_DESC_PIPL_EDUCATION_DISCREPANCY =
  'Education record discrepancy found';
export const FLAG_SETTINGS_DESC_PIPL_EMPLOYMENT_RECORD_DISCREPANCY =
  'Employment record discrepancy found';

export const FLAG_SETTINGS_DISQUALIFY = 'Disqualify';
export const FLAG_SETTINGS_ASK_FIRST = 'Ask first';
export const FLAG_SETTINGS_NOTIFY = 'Notify';
export const FLAG_SETTINGS_IGNORE = 'Ignore';

export const FLAG_SETTINGS_DISQUALIFY_SECTION_DESC =
  'Applicants will be disqualified if a match occurs';
export const FLAG_SETTINGS_ASK_FIRST_SECTION_DESC =
  'Applicants will be asked about the issue if a match occurs';
export const FLAG_SETTINGS_NOTIFY_SECTION_DESC =
  'The warning will notify you about the issue if a match occurs';
export const FLAG_SETTINGS_IGNORE_SECTION_DESC =
  'The red flag will not be raised even if a match occurs';

export const RED_FLAG_THEN_LABEL = 'then';
export const RED_FLAG_WHEN_LABEL = 'when';

export const FLAGS_VIEW_MODE_RESOLUTION = `view\nby resolution`;
export const FLAGS_VIEW_MODE_CATEGORY = `view\nby category`;

export const WALLPAPERS_TITLE = 'Wallpapers';
export const getReloadNotification = (counter: number) =>
  `The application has been updated and will restart in ${counter} seconds.\nPlease save your work now.`;

export const FREEFORM_SKILL_EXPLANATION =
  'This skill value is not present in our database so only this exact value will be used for matching without considering similar words or skills, which may distort matching results.';
export const FREEFORM_CERTIFICATE_EXPLANATION =
  'This certificate value is not present in our database, so fuzzy matching will be used. This may distort the matching results';
export const FREEFORM_ROLE_EXPLANATION =
  'This role value is not present in our database, so fuzzy matching will be used. This may distort the matching results';

/*
  Status Badge actions
*/
export const DRAFT_SECTION_DISCARD_ACTION = 'Discard draft';

export const SCREENING_SECTION_RESUME_ACTION = 'Resume';
export const RESUME_ACTION_CONFIRMATION_MESSAGE = 'Resume this job?';
export const CLOSE_ACTION_CONFIRMATION_MESSAGE = 'Close this job?';
export const PAUSE_ACTION_CONFIRMATION_MESSAGE = 'Pause this job?';

// Generate Report right panel
export const GENERATE_REPORT_HEADER = 'Generate Report';
export const GENERATE_REPORT_TEXT = [
  'Enter the email addresses that should receive statistics about candidate distribution or ',
  'download',
  ' the PDF.',
];
export const POSITION_STATUS_REPORT_TITLE = 'Position Status Report';
export const GENERATE_REPORT_ADD_EMAIL_BUTTON = 'Add Email';
export const GENERATE_REPORT_SEND_BUTTON = 'Send reports';
export const GENERATE_REPORT_SENDING_BUTTON = 'Sending ...';
export const GENERATE_REPORT_SUCCESS_TEXT = 'The report was successfully sent.';
export const GENERATE_REPORT_EMPTY_HEADER_NO_DATA = 'No candidates yet';
export const GENERATE_REPORT_EMPTY_TEXT_NO_DATA =
  'You have no candidates at the moment. Once they are available, you will be able to get statistics on them.';
export const GENERATE_REPORT_NO_SUITABLE_DATA_HEADER =
  'No screened candidates yet';
export const GENERATE_REPORT_NO_SUITABLE_DATA_TEXT =
  'You have no screened candidates at the moment. Once you start screening and at least one candidate is processed, the position statistics report will become available.';

export const GENERATE_REPORT_DISABLED_ADD_BUTTON_TOOLTIP = (
  fieldsLimit: number
) => `You have reached the limit of ${fieldsLimit} email addresses.`;

//Load More Applicants, Match Miner
export const ALL_SUITABLE_CANDIDATES_LOADED_TEXT =
  'All suitable candidates are currently loaded.';
export const TRY_TO_MODIFY_CRITERIA_TEXT =
  'If you need more, try to modify your search criteria.';
export const NOT_ENOUGH_CANDIDATES_TEXT =
  'Not enough candidates matching your search criteria?';
export const REQUEST_MORE_CANDIDATES_TEXT = 'request more';
export const REQUEST_MORE_TOOLTIP_TEXT = [
  'You can request up to',
  'candidates more',
];
export const REQUEST_MORE_TOOLTIP_PLACEHOLDER = 'Num. of candidates';
export const TRY_TO_REQUEST_MORE = 'Try to request more candidates!';
export const REQUEST_MORE_TRIAL_EXPIRED =
  'This action is not available when the trial is expired';
export const REQUEST_MORE_NO_CRITERIA_DISABLED =
  'Please add at least one Skill or Role criteria with Mandatory or Important priority to start the search.';
export const MM_REQUESTED = 'MatchMiner was successfully requested.';
export const MS_REQUESTED = 'MatchScout was successfully requested.';
export const REQUESTED_SALES = 'Our sales team will contact you soon!';
export const MM_CLOSED_ON_HOLD_REQUEST =
  'You can request more candidates only for jobs in screening status';
export const MAX_MM_CANDIDATES_REACHED =
  "You've reached the maximum number of candidates for MatchMiner.";
export const MAX_MS_CANDIDATES_REACHED =
  "You've reached the maximum number of candidates for MatchScout.";

// MatchService Settings Panel
export const MM_SETTINGS_PANEL_HEADER = 'Change mining settings';
export const MS_SETTINGS_PANEL_HEADER = 'MatchScout settings';
export const MM_SETTINGS_PANEL_DESCRIPTION =
  'After your restart the search, candidates from the current list will be removed, and you won’t be able to interact with them.';
export const MS_SETTINGS_PANEL_RESTART_SEARCH = 'Restart search';
export const MS_SETTINGS_PANEL_RESTART_CONFIRMATION = `After your restart the search, all the candidates from the previous search will be removed, and you won't be able to engage with them.`;
export const MM_SETTINGS_PANEL_DISABLED =
  'Please add at least one Skill or Role criteria with Mandatory or Important priority to change mining settings.';
export const MM_SETTINGS_FORM_SINCE_LABEL = 'Candidates added within';
export const MM_SETTINGS_FORM_SINCE_ALL_TIME = 'All time';
export const MM_SETTINGS_FORM_SINCE_2_YEARS = '2 years';
export const MM_SETTINGS_FORM_SINCE_1_YEAR = '1 year';
export const MM_SETTINGS_FORM_SINCE_9_MONTHS = '9 months';
export const MM_SETTINGS_FORM_SINCE_6_MONTHS = '6 months';
export const MM_SETTINGS_FORM_SINCE_3_MONTHS = '3 months';
export const MM_SETTINGS_FORM_SINCE_1_MONTH = '1 month';
export const MM_SETTINGS_FORM_SINCE_2_WEEKS = '2 weeks';
export const MM_SETTINGS_FORM_SINCE_1_WEEK = '1 week';
export const MM_SETTINGS_FORM_LIMIT_LABEL = 'Number of candidates';
export const GET_MM_SETTINGS_FORM_LIMIT_ERROR_LABEL = (limit: number) =>
  `Max number: ${limit}`;
export const MM_SETTINGS_FORM_EXCLUDE_LABEL = 'Exclude rejected';

//Kanban Card
export const SAVE_TEXT = 'SAVE';
export const OPEN_IN_NEW_TAB_BUTTON_TEXT = 'Open in new tab';
export const SELECT_BUTTON_TEXT = 'Select';

//Confirmation ModalWrapper
export const CANCEL = 'CANCEL';
export const DISCARD = 'DISCARD';
export const CONFIRM = 'CONFIRM';
export const DISCARD_UNSAVED_CHANGES = 'Discard unsaved changes?';

export const SEARCH_CAN_NOT_HAVE_EMPTY_SKILLS =
  'Search can not have empty skills';

//Landing
export const ORGANIZATION_TITLE = 'ORGANIZATION';

// Reminders
export const REMINDER_LABEL = 'Remind to answer in';
export const REMINDER_OPTION_1_DAY = '1 day';
export const REMINDER_OPTION_3_DAY = '3 days';
export const REMINDER_OPTION_5_DAY = '5 days';
export const REMINDER_OPTION_7_DAY = '7 days';
export const REMINDER_OPTION_10_DAY = '10 days';

// Email configuration
export const EMAIL_CONFIG_RIGHT_PANEL_TITLE = 'Add section';
export const EMAIL_TEMPLATE_SECTION_GREETINGS = 'Greetings';
export const EMAIL_TEMPLATE_SECTION_EXPERIENCE =
  'Experience at current position';
export const EMAIL_TEMPLATE_SECTION_SKILLS_MATCHED =
  "Candidate's matched skills";
export const EMAIL_TEMPLATE_SECTION_SKILLS_LACK = 'Skills with lack of info';
export const EMAIL_TEMPLATE_SECTION_SKILLS_MISSING = 'Missing skills';
export const EMAIL_TEMPLATE_SECTION_FREEFORM = 'Freeform text';
export const EMAIL_TEMPLATE_SUBJECT = 'Email subject';
export const EMAIL_SUBJECT_PLACEHOLDER = 'Enter email subject';
export const EMAIL_TEMPLATE_CONTENT_PLACEHOLDER =
  'Paste your text or type here...';
export const EMAIL_TEMPLATE_SAVE_BUTTON = 'Save it as a template';
export const DEFAULT_EMAIL_TEMPLATE_NAME = 'Starting Template';
export const EMAIL_TEMPLATE_SAVE_DESCRIPTION = ' to use in future emails.';
export const EMAIL_TEMPLATE_SELECT_RECIPIENTS_BUTTON = 'Select recipients';
export const SELECT_RECIPIENTS_BUTTON_TOOLTIP_NO_SUBJECT =
  'Add subject to your email first.';
export const SELECT_RECIPIENTS_BUTTON_TOOLTIP_NO_CONTENT =
  'Add text to your email first.';
export const SELECT_RECIPIENTS_BUTTON_TOOLTIP_EMPTY_EMAIL =
  'Add email subject and text to your email first.';
export const EMAIL_SECTION_TAB_ALTERNATIVE = 'Alternative';
export const EMAIL_CONFIG_SENDING_EMAILS_TO_LABEL = 'Sending emails to';
export const EMAIL_CONFIG_SELECTED_JOB_LABEL = 'Selected job';
export const EMAIL_CONFIG_GO_TO_SCREENING_LABEL = 'Go to screening';
export const EMAILS_RECEIVERS_APPLIED_CANDIDATES = 'Applied candidates';
export const EMAILS_RECEIVERS_PASSIVE_CANDIDATES = 'Passive candidates';
export const EMAILS_RECEIVERS_MATCH_MINER_CANDIDATES = 'MatchMiner candidates';
export const EMAILS_RECEIVERS_MATCH_SCOUT_CANDIDATES = 'MatchScout candidates';
export const EMAIL_SECTIONS_LOAD_ERROR = 'No email sections available.';
export const JOB_EMAILS_TAB_CREATE_NEW_EMAIL_BUTTON = 'Create new email';
export const EMAIL_SECTION_TEMPLATES_TAB_LABEL = 'Templates';
export const EMAIL_SECTION_JOB_EMAILS_TAB_LABEL = 'Job Emails';
export const EMAIL_SECTION_JOB_EMAILS_NO_EMAILS_ERROR = 'No emails';
export const EMAIL_SECTION_JOB_EMAILS_NO_EMAIL_BY_ID = 'No email';
export const EMAIL_SECTION_JOB_EMAILS_NO_EMAILS = 'No emails';
export const EMAIL_SECTION_CREATE_NEW_TITLE = 'Create an email for this job!';
export const EMAIL_SECTION_CREATE_NEW_DESC =
  'Chose existing template or create a new email\nfrom scratch.';
export const EMAIL_GO_TO_EDIT_MODE_BUTTON = 'Finish editing';
export const EMAIL_CREATED = 'Created';
export const EMAIL_CREATED_BY = 'by';
export const EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_SAVE_BUTTON_LABEL =
  'Save';

export const EMAIL_SECTION_CONTROLS_FOR_SUBMIT_TEMPLATE_CHANGES_LABEL =
  'Apply changes';
export const EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_CANCEL_BUTTON_LABEL =
  'Cancel';
export const EMAIL_SECTION_CONTROLS_FOR_SAVING_TEMPLATE_INPUT_LABEL =
  'template name';
export const EMAIL_SECTION_NEW_TEMPLATE_SAVE_SUCCESS = 'New template created.';
export const EMAIL_SECTION_ACTION_MENU_DELETE = 'Delete';
export const EMAIL_SECTION_ACTION_DELETE_TOOLTIP = 'Deletion is not finished';
export const EMAIL_SECTION_TEMPLATE_BOTTOM_SECTION_SELECT = 'Select';
export const EMAIL_SECTION_TEMPLATE_BOTTOM_SECTION_SELECT_FOR_SENDING =
  'Select for sending';
export const EMAIL_SECTION_FILTER_DROPDOWN_FILTER_TEXT = 'Filter';
export const EMAIL_SECTION_FILTER_DROPDOWN_CREATED_BY_ME_TEXT = 'Created by me';
export const EMAIL_SECTION_DUPLICATE_TEMPLATE_TOAST = 'Template duplicated.';
export const EMAIL_SECTION_DUPLICATE_EMAIL_TOAST = 'Email duplicated.';
export const EMAIL_SECTION_TOAST_DELETED_ITEM_JOB_EMAIL_INFO = (name: string) =>
  `The email "${name}" has been just removed by another user.`;
export const EMAIL_SECTION_TOAST_DELETED_EMAIL_TEMPLATE_INFO = (name: string) =>
  `The template "${name}" has been just removed by another user.`;
export const EMAIL_SECTION_TOAST_DELETED_JOB_EMAIL_SUCCESS = 'Email deleted.';
export const EMAIL_SECTION_TOAST_DELETED_TEMPLATE_EMAIL_SUCCESS =
  'Template deleted.';
export const EMAIL_SECTION_CONFIRM_LABEL = 'Delete';
export const EMAIL_SECTION_CONFIRM_MODAL_DELETE_TEXT_EMAIL = 'Delete email';
export const EMAIL_SECTION_CONFIRM_MODAL_DELETE_TEMPLATE_EMAIL =
  'Delete template';
export const EMAIL_SECTION_TEMPLATE_CHANGES_SAVED_TOAST =
  'Template changes saved.';
export const EMAIL_SECTION_TEMPLATE_CHANGES_DISCARDED_TOAST =
  'Template changes discarded.';
export const EMAIL_SECTION_LIST_ITEM_UNSAVED_CHANGES_TEXT = 'Unsaved changes';
export const EMAIL_SECTION_DISCARD_CHANGES_BUTTON_TEXT = 'Discard Changes';
export const EMAIL_SECTION_NEWLY_CREATED_JOB_EMAIL_SUBJECT = (
  position: string
) => `${position} position`;
export const EMAIL_SECTION_CREATING_JOB_EMAIL_FROM_TEMPLATE_CHANGES_TEXT =
  'Changes will not affect the template.';
export const DEFAULT_TEMPLATE_CREATOR = 'Employa';

/* Select Recipients */
export const SELECT_RECIPIENTS_TITLE = 'Select Recipients';
export const SELECT_RECIPIENTS_TAB_NEW_TITLE = 'New';
export const SELECT_RECIPIENTS_TAB_SENT_TITLE = 'Sent';
export const SELECT_RECIPIENTS_FILTER_PLACEHOLDER = 'Type to filter';
export const SELECT_RECIPIENTS_BOUNCED_TOOLTIP_TEXT = 'Bounced';
export const SELECT_RECIPIENTS_NO_EMAIL_TOOLTIP_TEXT =
  'No email address. Add it to select this candidate for sending.';
export const SELECT_RECIPIENTS_DELIVERED_TOOLTIP_TEXT = 'Delivered';
export const SELECT_RECIPIENTS_OPEN_CANDIDATE_TOOLTIP_TEXT =
  'Open profile in new tab';
export const SELECT_RECIPIENTS_NO_CANDIDATES_TITLE = 'No candidates to select';
export const SELECT_RECIPIENTS_NO_CANDIDATES_MESSAGE =
  'Upload new candidates to send them emails.';
export const SELECT_RECIPIENTS_NO_EMAIL_SENT_TITLE = 'No emails sent';
export const SELECT_RECIPIENTS_NO_EMAIL_SENT_MESSAGE =
  'The list of candidates you have sent emails to will appear here.';
export const SELECT_RECIPIENTS_SEND_BUTTON_TEXT = 'Send emails...';
export const SELECT_RECIPIENTS_OVERLAY_SEND_BUTTON_TEXT = 'Send emails';
export const SELECT_RECIPIENTS_OVERLAY_CANCEL_BUTTON_TEXT = 'Cancel';
export const SELECT_RECIPIENTS_CANDIDATES_SELECTED_TEXT = (count: number) =>
  `${count === 1 ? 'Candidate' : 'Candidates'} selected`;
export const SELECT_RECIPIENTS_SENT_FROM_TEXT = 'Email will be sent from';
export const getEmailsSentSuccessMessage = (count: number) =>
  `${R.textByCount('email', 'emails', count)} sent`;
