import React from 'react';
import { RouteProps } from 'react-router-dom';

import { Button } from '@air/components';
import { TypeaheadSearchFieldWrapper } from 'components';
import { CRITERIA_COLUMNS } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import { FetchCriteriaParamsT } from 'domain/dictionaries/criteria';
import { TypeaheadSearchFieldVariants } from '@air/components/TypeaheadSearchField/TypeaheadSearchField';

// imports from styles
import styles from './TableControls.css';
import commonStyles from 'styles/commonDictionaries.css';

type Props = {
  addItem?: () => void;
  exportItems?: () => void;
  onUpdateSearchField?: (props: FetchCriteriaParamsT) => void;
  setKeywordValue?: (keyword: string) => void;
  isLoading: boolean;
  toggleDeprecatedFilter?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleManagerialFilter?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  discardChanges?: () => void;
  handleSave?: () => void;
  isReadOnly?: boolean;
  disablePublishing?: boolean;
  disableExporting?: boolean;
} & RouteProps;

export const TableControls: React.FC<Props> = ({
  addItem,
  exportItems,
  onUpdateSearchField,
  setKeywordValue,
  isLoading,
  toggleDeprecatedFilter,
  toggleManagerialFilter,
  discardChanges,
  handleSave,
  isReadOnly,
  disablePublishing,
  disableExporting,
}) => {
  return (
    <div className={styles.tableControls}>
      <div className={commonStyles.row}>
        <div className={styles.controlsWrapper}>
          <Button
            onClick={addItem}
            variant={Button.variants.CHIP}
            disabled={isReadOnly}
          >
            <span>{phrases.BUTTON_ADD_ITEM}</span>
          </Button>
          {exportItems && (
            <Button
              variant={Button.variants.CHIP}
              onClick={exportItems}
              className={styles.buttonExport}
              disabled={disableExporting}
            >
              <span>{phrases.BUTTON_EXPORT}</span>
            </Button>
          )}
        </div>

        <div className={commonStyles.topNav}>
          <Button
            small
            disabled={disablePublishing}
            variant={Button.variants.NEGATIVE_MAIN}
            onClick={discardChanges}
          >
            {phrases.BUTTON_DISCARD}
          </Button>
          <Button
            small
            disabled={disablePublishing}
            variant={Button.variants.POSITIVE_SECONDARY}
            onClick={handleSave}
          >
            {phrases.BUTTON_SAVE}
          </Button>
        </div>
      </div>
      <div className={styles.searchWrapper}>
        {setKeywordValue && (
          <TypeaheadSearchFieldWrapper
            onUpdateSearchField={onUpdateSearchField}
            searchFieldName="keyword"
            setSearchField={setKeywordValue}
            variant={TypeaheadSearchFieldVariants.SMALL}
          />
        )}
        {toggleDeprecatedFilter && (
          <form className={commonStyles.checkboxForm}>
            <label>
              <input
                disabled={isLoading}
                type="checkbox"
                name={CRITERIA_COLUMNS.DEPRECATED}
                onChange={toggleDeprecatedFilter}
              />
              {phrases.HIDE_DEPRECATED}
            </label>
          </form>
        )}
        {toggleManagerialFilter && (
          <form className={commonStyles.checkboxForm}>
            <label>
              <input
                disabled={isLoading}
                type="checkbox"
                name={CRITERIA_COLUMNS.MANAGERIAL}
                onChange={toggleManagerialFilter}
              />
              {phrases.SHOW_MANAGERIAL}
            </label>
          </form>
        )}
      </div>
    </div>
  );
};

TableControls.displayName = 'TableControls';
