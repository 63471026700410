import React, { useMemo, useState, useCallback } from 'react';
import { toast } from '@air/third-party/toast';
import R from '@air/third-party/ramda';

import {
  CustomerCompaniesContext,
  CustomerCompaniesContextT,
  UpdateItemParamsT,
} from 'context';
import {
  FetchCriteriaParamsT,
  CustomerCompaniesT,
} from 'domain/dictionaries/criteria';

import { DEFAULT_DICTIONARY_ITEM } from 'constants/criteria';
import * as phrases from 'constants/phrases';
import {
  CompanyListResponse,
  ImsItemList,
  DssItemList,
  CustomerCompanyResponseV3,
  CustomerCompanyCreateRequestV3,
  CustomerCompanyUpdateRequestV3,
  CustomerCompanyAccountManagerListResponse,
} from '@air/api';
import * as DictionariesApi from 'domain/dictionaries/dictionariesApi';
import * as CustomerCompaniesApi from 'domain/customerCompaniesApi';
import { BillingReportPayload } from 'domain/customerCompaniesApi';
import * as sharedUrls from '@air/constants/apiEndpoints';
import {
  CustomerCompanyT,
  prepareCustomerCompaniesForUITable,
} from 'domain/customerCompanies';
import * as sharedPhrases from '@air/constants/phrases';
import { downloadFile } from 'domain/dictionaries/dictionaries';
import { useContextSelector } from 'use-context-selector';
import { useEqualContextSelector } from '@air/utils/hooks';
import { prepareCompanyManagersListForUI } from 'domain/dictionaries/companies';

export const CustomerCompaniesProvider: React.FC = ({ children }) => {
  const [dictionaryItems, setDictionaryItems] = useState<CustomerCompaniesT>(
    DEFAULT_DICTIONARY_ITEM
  );

  const fetchDictionaryItems = useCallback(
    async (params: FetchCriteriaParamsT) => {
      setDictionaryItems((state) => {
        return {
          ...state,
          sortField: params.sortField || state?.sortField,
          sortOrder: params.sortOrder || state?.sortOrder,
          isLoading: true,
        };
      });
      let responseCompanies: ImsItemList;
      let responseCompaniesAts: DssItemList;
      await DictionariesApi.fetchDictionary<CompanyListResponse>(
        params,
        sharedUrls.API_CUSTOMER_COMPANY_V1
      ).fork(
        () => {
          setDictionaryItems((state) => ({
            ...state,
            isLoading: false,
          }));
          toast.error(phrases.DICTIONARY_FETCHING_ERROR);
        },
        (response: ImsItemList) => {
          responseCompanies = response;
        }
      );
      if (responseCompanies.items.length > 0) {
        await CustomerCompaniesApi.fetchCustomerCompanyAts<DssItemList>(
          // get ATS data for the fetched companies
          {
            companyId: responseCompanies?.items
              .map((item) => item.id)
              .join(','),
          }
        ).fork(
          () => {},
          (response) => {
            responseCompaniesAts = response;
          }
        );
      }
      setDictionaryItems((state) => {
        const fetchedItems = prepareCustomerCompaniesForUITable(
          responseCompanies?.items,
          responseCompaniesAts?.items
        );
        return {
          ...responseCompanies,
          sortField: state.sortField,
          sortOrder: state.sortOrder,
          updatedItems: state.updatedItems,
          companyManagers: state.companyManagers,
          items: params?.page
            ? [...state.items, ...fetchedItems]
            : fetchedItems,
          isLoading: false,
        };
      });
    },
    []
  );

  const getItemById = useCallback(
    (companyId: string): Promise<CustomerCompanyResponseV3> => {
      return CustomerCompaniesApi.fetchCustomerCompanyById(companyId).fork(
        () => {
          throw new Error();
        },
        R.identity
      );
    },
    []
  );

  const createNewItem = useCallback(
    async (
      requestParams: CustomerCompanyCreateRequestV3
    ): Promise<CustomerCompanyResponseV3> => {
      return CustomerCompaniesApi.createNewItem<CustomerCompanyCreateRequestV3>(
        requestParams
      ).fork((rej) => {
        toast.error(
          rej.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
      }, R.identity);
    },
    []
  );

  const updateItem = useCallback(
    async ({
      newItem,
      companyId,
      newLocalItem,
    }: UpdateItemParamsT): Promise<CustomerCompanyResponseV3> => {
      // update item locally after we receive SSE events
      if (newLocalItem) {
        setDictionaryItems((state) => {
          return {
            ...state,
            items: state.items.map((item) => ({
              ...item,
              accountManager:
                item.id === String(newLocalItem.companyId)
                  ? newLocalItem.accountManager
                  : item.accountManager,
            })),
          };
        });
        return;
      }
      // send user changes to BE after form completion
      return CustomerCompaniesApi.updateItem<CustomerCompanyUpdateRequestV3>(
        newItem,
        companyId
      ).fork((rej) => {
        toast.error(
          rej.details?.description || sharedPhrases.GENERAL_ERROR_TRY_AGAIN
        );
        throw new Error();
      }, R.identity);
    },
    []
  );

  const uploadLogoForCustomerCompany = useCallback(({ companyId, body }) => {
    return CustomerCompaniesApi.uploadCompanyLogo({ companyId, body }).fork(
      (rej) => {
        toast.error(rej.details?.description);
      },
      () => {}
    );
  }, []);

  const removeLogoForCustomerCompany = useCallback((companyId) => {
    return CustomerCompaniesApi.removeCompanyLogo({ companyId }).fork(
      (rej) => {
        toast.error(rej.details?.description);
      },
      () => {}
    );
  }, []);

  const removeItem = useCallback((companyId) => {
    return CustomerCompaniesApi.removeItem(companyId).fork(() => {
      throw new Error();
    }, R.identity);
  }, []);

  const dropIndexationResults = useCallback(async (companyId) => {
    return CustomerCompaniesApi.dropIndexationResults(companyId).fork(() => {
      throw new Error();
    }, R.identity);
  }, []);

  const getBillingReport = useCallback((payload: BillingReportPayload) => {
    return CustomerCompaniesApi.getBillingReport(payload).fork(
      (rej) => {
        toast.error(rej.details?.description);
      },
      (blob, extraParams) => {
        downloadFile(blob, extraParams.headers, `billing_report`);
      }
    );
  }, []);

  const fetchCompanyManagers = useCallback(() => {
    setDictionaryItems((state) => ({
      ...state,
      isLoading: true,
    }));
    return CustomerCompaniesApi.getCompanyManagers().fork(
      (rej) => {
        setDictionaryItems((state) => ({
          ...state,
          isLoading: false,
        }));
        toast.error(rej.details?.description);
      },
      (res: CustomerCompanyAccountManagerListResponse) => {
        const companyManagers = prepareCompanyManagersListForUI(res.items);

        setDictionaryItems((state) => ({
          ...state,
          companyManagers,
          isLoading: false,
        }));

        return companyManagers;
      }
    );
  }, []);

  const dictionaryItemsContextValue = useMemo(() => {
    return {
      ...dictionaryItems,
      items: dictionaryItems.items as CustomerCompanyT[],
      updatedItems: dictionaryItems.updatedItems as CustomerCompanyT[],
      methods: {
        fetchDictionaryItems,
        createNewItem,
        updateItem,
        uploadLogoForCustomerCompany,
        getBillingReport,
        removeLogoForCustomerCompany,
        dropIndexationResults,
        removeItem,
        getItemById,
        fetchCompanyManagers,
      },
    };
  }, [
    dictionaryItems,
    fetchDictionaryItems,
    createNewItem,
    updateItem,
    uploadLogoForCustomerCompany,
    removeLogoForCustomerCompany,
    dropIndexationResults,
    removeItem,
    getItemById,
    getBillingReport,
    fetchCompanyManagers,
  ]);

  return (
    <CustomerCompaniesContext.Provider value={dictionaryItemsContextValue}>
      {children}
    </CustomerCompaniesContext.Provider>
  );
};

export const useCustomerCompaniesContext = <Selected,>(
  selector: (state: CustomerCompaniesContextT) => Selected
) => {
  return useContextSelector(CustomerCompaniesContext, selector);
};

export const useCustomerCompaniesMethods = () => {
  return useEqualContextSelector(
    CustomerCompaniesContext,
    (state: CustomerCompaniesContextT) => state.methods,
    R.shallowEqualObjects
  );
};

CustomerCompaniesProvider.displayName = 'CustomerCompaniesProvider';
