import { DictionaryUpsertResponse } from '@air/api';
import { Task } from '@air/utils/fp';
import * as Http from '@air/utils/http';
import {
  parseErrorJson,
  parseResponseBlob,
  parseResponseJson,
} from '@air/utils/api';
import { PAGE_START, REQUEST_PAGING_SIZE } from 'constants/api';
import {
  constructSortString,
  FetchCriteriaParamsT,
} from 'domain/dictionaries/criteria';
import { containsOnlyNumbersRegex } from '@air/utils/strings';

export const fetchDictionary = <T>(
  params: FetchCriteriaParamsT,
  apiEndpoint: string
): Task<T> => {
  const {
    keyword,
    page,
    size = REQUEST_PAGING_SIZE,
    sort,
    sortField,
    sortOrder,
    ...rest
  } = params;

  const hasIncludeId = containsOnlyNumbersRegex.test(params.keyword);

  return Http.get(apiEndpoint)
    .bind(
      Http.withQueryParams({
        ...(hasIncludeId
          ? {
              includeId: params.includeId,
              keyword: params.keyword,
            }
          : {
              keyword: params.keyword,
            }),
        size,
        page: params.page || PAGE_START,
        sort: constructSortString(sortField, sortOrder),
        ...rest,
      })
    )
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const publishChanges = <B>(
  body: B,
  apiEndpoint: string
): Task<DictionaryUpsertResponse> => {
  return Http.put(apiEndpoint)
    .bind(Http.withJsonBody(body))
    .toTask()
    .chain(parseResponseJson)
    .chainError(parseErrorJson);
};

export const exportDictionary = (apiEndpoint: string): Task<Blob> => {
  return Http.get(apiEndpoint)
    .toTask()
    .chain(parseResponseBlob)
    .chainError(parseErrorJson);
};
