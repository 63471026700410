import { CertificationUpsertRequest } from '@air/api';
import { ModifiedCertificationResponse } from './criteria';
import {
  prepareAliasesForBackend,
  prepareJobSpecializationsForBackend,
} from 'domain/dictionaries/dictionaries';

export const prepareCertificationsForBackend = (
  certifications: ModifiedCertificationResponse[]
): CertificationUpsertRequest[] => {
  return certifications.map((item) => {
    return {
      id: item.__isNew__ ? null : Number(item.id),
      fullName: item.fullName?.trim(),
      shortName: item.shortName?.trim(),
      aliases: prepareAliasesForBackend(item.aliases),
      specializations: prepareJobSpecializationsForBackend(
        item.specializations
      ),
      company: item.company,
      deprecated: item.deprecated,
    };
  });
};
