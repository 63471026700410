import React, { useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import R from '@air/third-party/ramda';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';

import {
  EditableTableWrapper,
  OverlayListEditor,
  TableControls,
} from 'components';

import {
  computeDictionaryExpandedItem,
  GetCellComponentParamsT,
} from 'domain/dictionaries/dictionaries';
import {
  FetchCriteriaParamsT,
  getNewlyAddedItems,
  mapPresentItemsToUpdatedItems,
  ModifiedCriteriaComputedResponse,
  ModifiedEducationMajorGroup,
} from 'domain/dictionaries/criteria';
import { getCellComponentForCriteria } from 'domain/tableHelpers';

import {
  CRITERIA_ERRORS_MAPPING,
  SORTABLE_DICTIONARIES_COLUMNS,
  SortFieldsT,
} from 'constants/criteria';
import { DEFAULT_EMPTY_DICTIONARY_ITEM } from 'constants/dictionaries';
import { PAGE_START, REQUEST_PAGING_SIZE, SortOrderT } from 'constants/api';
import { TableColumnsT } from 'constants/tables';
import * as phrases from 'constants/phrases';
import { LoadAsyncListOptionsT } from '@air/components/Select/Select';
import { dictionarySelectors } from 'selectors';
import { DictionaryContextT } from 'context';

// imports from styles
import commonStyles from 'styles/commonDictionaries.css';
import { useJobSpecializations } from '@air/hooks/useJobSpecializations';

type GetExtraParamsT = {
  includeDeprecated?: boolean;
  managerial?: boolean;
  hideDeprecatedControl?: boolean;
  showManagerialControl?: boolean;
};

type Props<ContextT, ContextMethodsT> = {
  tableTitle: string;
  tableColumns: TableColumnsT;
  defaultEmptyItem?: any; // TODO
  dictionaryContext: <Selected>(
    selector: (state: ContextT) => Selected
  ) => Selected;
  dictionaryContextMethods: ContextMethodsT;
  hideDeprecatedControl?: boolean;
  showManagerialControl?: boolean;
  isReadOnly?: boolean;
  withOverlayEditor?: boolean;
  overlayListEditorPlaceholder?: string;
  loadAsyncListOptions?: LoadAsyncListOptionsT;
  loadAsyncCellOptions?: LoadAsyncListOptionsT | (() => void);
  asyncOptionsCell?: string;
  tab?: string;
  hideTableControls?: boolean;
  onCellClick?: (id: string) => void;
} & RouteProps;

const INCLUDE_DEPRECATED_DEFAULT_VALUE = true;
const MANAGERIAL_DEFAULT_VALUE = false;
/*
  All dictionaries are available for editing on DEV environment only.
*/
const isDevEnvironment = ENV_NAME === 'localhost' || ENV_NAME === 'dev';

const getExtraParams = (props: GetExtraParamsT) => {
  const {
    includeDeprecated,
    managerial,
    hideDeprecatedControl,
    showManagerialControl,
  } = props;
  return {
    ...(hideDeprecatedControl ? {} : { includeDeprecated }),
    ...(showManagerialControl ? { managerial } : {}),
  };
};

export const DictionaryView = <
  ContextT extends DictionaryContextT<unknown>,
  ContextMethodsT extends (...args: any[]) => {
    [methodName: string]: (...args: any[]) => any;
  }
>({
  tableTitle,
  tableColumns,
  defaultEmptyItem = DEFAULT_EMPTY_DICTIONARY_ITEM, // used when a new items is added to the table
  dictionaryContext,
  dictionaryContextMethods,
  hideDeprecatedControl, // shows or hides the checkbox
  showManagerialControl,
  isReadOnly, // is the whole table read only
  withOverlayEditor, // table fields can be edited in an overlay, each row has groups/lists of items in 1 cell
  overlayListEditorPlaceholder,
  loadAsyncListOptions, // used in overlay editor, now supporting only 1 per table
  loadAsyncCellOptions = R.noop, // used in table cells, now supporting only 1 per table
  asyncOptionsCell, // used with loadAsyncCellOptions
  tab, // DictionaryView is inside a tab
  hideTableControls,
  onCellClick,
}: Props<ContextT, ContextMethodsT>) => {
  const [pageNumber, setPageNumber] = useState(PAGE_START);
  const [keyword, setKeywordValue] = useState('');
  const [includeDeprecated, setIncludeDeprecated] = useState(
    INCLUDE_DEPRECATED_DEFAULT_VALUE
  );
  const [managerial, setManagerial] = useState(MANAGERIAL_DEFAULT_VALUE);
  const [expandedDictionaryItem, setExpandedDictionaryItem] = useState(null);
  const [expandedDictionaryItemId, setExpandedDictionaryItemId] =
    useState(null);

  const items = dictionaryContext(dictionarySelectors.items);
  const isLoading = dictionaryContext(dictionarySelectors.isLoading);
  const updatedItems = dictionaryContext(dictionarySelectors.updatedItems);
  const total = dictionaryContext(dictionarySelectors.total);
  const sortField = dictionaryContext(dictionarySelectors.sortField);
  const sortOrder = dictionaryContext(dictionarySelectors.sortOrder);

  const {
    fetchDictionaryItems,
    updateDictionaryItem,
    exportDictionaryItems,
    discardDictionaryItemsChanges,
    publishDictionaryItemsChanges,
    removeAddedDictionaryItem,
  } = dictionaryContextMethods();

  const unpublishedChangesPresent = updatedItems?.length > 0;
  useBeforeUnload(unpublishedChangesPresent, phrases.BEFORE_UNLOAD_MESSAGE);

  useEffect(() => {
    // when we select or deselect a new expanded item
    // or updatedItems/items are changed
    // update the contents of expanded item
    if (!expandedDictionaryItemId) return setExpandedDictionaryItem(null);
    setExpandedDictionaryItem(
      computeDictionaryExpandedItem<ModifiedEducationMajorGroup>(
        updatedItems,
        items,
        expandedDictionaryItemId
      )
    );
  }, [expandedDictionaryItemId, updatedItems, items]);

  const expandDictionaryItem = useCallback(
    (item: ModifiedCriteriaComputedResponse) => {
      setExpandedDictionaryItemId(R.isNil(item) ? null : item.id);
    },
    []
  );

  const [computedDictionaryItems, setComputedDictionaryItems] = useState({
    items,
    isLoading,
    updatedItems,
    total,
    sortField,
    sortOrder,
  });

  useEffect(() => {
    // first fetching of items
    fetchDictionaryItems({
      page: PAGE_START,
      size: REQUEST_PAGING_SIZE,
      ...getExtraParams({
        includeDeprecated: INCLUDE_DEPRECATED_DEFAULT_VALUE,
        managerial: MANAGERIAL_DEFAULT_VALUE,
        hideDeprecatedControl,
        showManagerialControl,
      }),
    });
  }, [fetchDictionaryItems, hideDeprecatedControl, showManagerialControl]);

  useEffect(() => {
    // Update items in table after the user adds new item
    // or the saved/erroneous items are returned from back-end
    // @ts-ignore
    setComputedDictionaryItems(() => {
      const newlyAddedItems = getNewlyAddedItems(updatedItems);
      const mappedItems = mapPresentItemsToUpdatedItems(items, updatedItems);
      return {
        total,
        updatedItems,
        isLoading,
        sortField,
        sortOrder,
        items: [...newlyAddedItems, ...mappedItems],
      };
    });
  }, [items, updatedItems, total, isLoading, sortField, sortOrder]);

  const toggleDeprecatedFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = !e?.target?.checked;
      setIncludeDeprecated(value);
      setPageNumber(PAGE_START);
      fetchDictionaryItems({
        page: PAGE_START,
        size: REQUEST_PAGING_SIZE,
        keyword,
        sortField,
        sortOrder,
        ...getExtraParams({
          includeDeprecated: value,
          managerial,
          hideDeprecatedControl,
          showManagerialControl,
        }),
      });
    },
    [
      fetchDictionaryItems,
      keyword,
      sortField,
      sortOrder,
      managerial,
      hideDeprecatedControl,
      showManagerialControl,
    ]
  );

  const toggleManagerialFilter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.checked;
      setManagerial(value);
      setPageNumber(PAGE_START);
      fetchDictionaryItems({
        page: PAGE_START,
        size: REQUEST_PAGING_SIZE,
        keyword,
        sortField,
        sortOrder,
        ...getExtraParams({
          includeDeprecated,
          managerial: value,
          hideDeprecatedControl,
          showManagerialControl,
        }),
      });
    },
    [
      fetchDictionaryItems,
      keyword,
      sortField,
      sortOrder,
      includeDeprecated,
      hideDeprecatedControl,
      showManagerialControl,
      setPageNumber,
    ]
  );

  const loadMoreItems = useCallback(() => {
    if (total !== items?.length && !isLoading) {
      const page = pageNumber + 1;
      fetchDictionaryItems(
        {
          page,
          keyword,
          sortField,
          sortOrder,
          ...getExtraParams({
            includeDeprecated,
            managerial,
            hideDeprecatedControl,
            showManagerialControl,
          }),
        },
        true
      );
      setPageNumber(page);
    }
  }, [
    pageNumber,
    setPageNumber,
    isLoading,
    fetchDictionaryItems,
    sortField,
    sortOrder,
    managerial,
    includeDeprecated,
    hideDeprecatedControl,
    showManagerialControl,
    keyword,
    items,
    total,
  ]);

  const addItem = useCallback(() => {
    updateDictionaryItem({
      item: { ...defaultEmptyItem, id: uuidv4() },
    });
  }, [updateDictionaryItem, defaultEmptyItem]);

  const fetchSortedItems = (
    sortField?: SortFieldsT,
    sortOrder?: SortOrderT
  ) => {
    setPageNumber(PAGE_START);
    fetchDictionaryItems({
      page: PAGE_START,
      sortField,
      sortOrder,
      keyword,
      ...getExtraParams({
        includeDeprecated,
        managerial,
        hideDeprecatedControl,
        showManagerialControl,
      }),
    });
  };

  const isEditable = !isReadOnly && isDevEnvironment;
  const disableControls = isLoading || !isEditable;
  const disablePublishing = disableControls || !updatedItems?.length;
  const disableExporting = isLoading || !items?.length;

  const onUpdateSearchField = useCallback(
    (props: FetchCriteriaParamsT) => {
      setPageNumber(PAGE_START);
      fetchDictionaryItems({
        page: PAGE_START,
        sortField,
        sortOrder,
        ...getExtraParams({
          includeDeprecated,
          managerial,
          hideDeprecatedControl,
          showManagerialControl,
        }),
        ...props,
      });
    },
    [
      hideDeprecatedControl,
      showManagerialControl,
      includeDeprecated,
      managerial,
      sortField,
      sortOrder,
      fetchDictionaryItems,
    ]
  );

  const { specializations } = useJobSpecializations();

  const getCellComponent = ({
    cell,
    row,
    removeItem,
    onCellClick,
  }: GetCellComponentParamsT) => {
    const selectedRowItem =
      tableTitle === phrases.NAVIGATION_LINK_ROLES ? row.original.id : null;
    return getCellComponentForCriteria({
      cell,
      row,
      removeItem,
      disabled: !isEditable,
      onCellClick,
      loadOptions: loadAsyncCellOptions
        ? loadAsyncCellOptions(
            row.original?.[asyncOptionsCell],
            selectedRowItem
          )
        : R.noop,
      specializations,
    });
  };

  return (
    <div
      className={classNames(commonStyles.dictionaryView, {
        [commonStyles.tabsContent]: !!tab,
      })}
    >
      {!hideTableControls && (
        <TableControls
          addItem={addItem}
          exportItems={exportDictionaryItems}
          onUpdateSearchField={onUpdateSearchField}
          setKeywordValue={setKeywordValue}
          isLoading={isLoading}
          toggleDeprecatedFilter={
            hideDeprecatedControl ? null : toggleDeprecatedFilter
          }
          toggleManagerialFilter={
            showManagerialControl ? toggleManagerialFilter : null
          }
          discardChanges={discardDictionaryItemsChanges}
          handleSave={publishDictionaryItemsChanges}
          isReadOnly={disableControls}
          disablePublishing={disablePublishing}
          disableExporting={disableExporting}
        />
      )}
      {withOverlayEditor && (
        <OverlayListEditor
          updateList={updateDictionaryItem}
          setOverlayItem={expandDictionaryItem}
          overlayItem={expandedDictionaryItem}
          loadAsyncListOptions={loadAsyncListOptions}
          placeholder={overlayListEditorPlaceholder}
          isReadOnly={disableControls}
        />
      )}
      <EditableTableWrapper
        title={tableTitle}
        sortOrder={sortOrder}
        sortField={sortField}
        sortableColumns={SORTABLE_DICTIONARIES_COLUMNS}
        fetchSortedItems={fetchSortedItems}
        columns={tableColumns}
        data={computedDictionaryItems}
        loadMoreItems={loadMoreItems}
        removeItem={removeAddedDictionaryItem}
        updateData={updateDictionaryItem}
        onCellClick={onCellClick}
        getCellComponent={getCellComponent}
        errorMapping={CRITERIA_ERRORS_MAPPING}
        expandItem={withOverlayEditor ? expandDictionaryItem : null}
        isReadOnly={!isEditable}
        pageNumber={pageNumber}
      />
    </div>
  );
};

DictionaryView.displayName = 'DictionaryView';
