import React from 'react';
import { Column, Row } from 'react-table';

import { Button } from '@air/components';

import { UpdateFieldParamsT } from 'domain/dictionaries/criteria';

// imports from styles
import styles from './TextCell.css';

type Props = {
  row: Row;
  column: Column;
  removeItem: (id: string) => void;
  updateData: ({ value, id, fieldName }: UpdateFieldParamsT) => void;
  onCellClick?: (id: number) => void;
  defaultValue?: string;
};

export const TextCell = React.memo((props: Props) => {
  const {
    row: { original },
    column,
    onCellClick,
    defaultValue,
  } = props;

  return onCellClick ? (
    <Button
      variant={Button.variants.INLINE}
      onClick={() => onCellClick(original.id)}
    >
      {original[column.id]}
    </Button>
  ) : (
    <div className={styles.textCell}>{original[column.id] || defaultValue}</div>
  );
});

TextCell.displayName = 'TextCell';
